import styled from '@emotion/styled';

import { Section } from '../../../models/Summary/summary';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { RadioActiveIcon } from '../../Icons/RadioActiveIcon';
import { RadioIcon } from '../../Icons/RadioIcon';

type Props = {
  section: Section;
  onSelect: (section: Section) => void;
  selected: boolean;
};

export const SubjectSectionRow = ({ section, onSelect, selected }: Props) => {
  return (
    <Container onClick={() => onSelect(section)}>
      <Title>{section.sectionTitle}</Title>
      {selected ? (
        <RadioActiveIcon color={Colors.primaryButtonFill} />
      ) : (
        <RadioIcon color={Colors.radioInactive} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 20px 0;
  border-bottom: none;

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${Colors.modalBackgroundColor};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid ${Colors.disabledContentColor};
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${Colors.primaryButtonFill};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid ${Colors.primaryButtonFill};
  }
`;

const Title = styled.div`
  padding-left: 64px;
  flex: 1;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemBody}
`;
