import { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductBundle } from '../../models/ProductBundle';
import { useAppDispatch } from '../../store/hooks';
import { addToCart } from '../../store/slices/cartSlice';
import { setCoverUrlToSummary } from '../../utils/apiUtils';
import { CartItem } from '../CartItem';

type Props = {
  productBundle: ProductBundle;
  onPress?: () => void;
} & ComponentProps<'div'>;

const DECIMAL_PLACES = 2;

export const BuyableItem = ({ productBundle, ...props }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onPress = () => {
    dispatch(addToCart(productBundle));
    navigate('/cart');
    if (props.onPress) {
      props.onPress();
    }
  };

  const getPriceWithPotentialDiscount = () => {
    return productBundle.discountPercentage
      ? `€${(productBundle.price * (productBundle.discountPercentage / 100 + 1)).toFixed(DECIMAL_PLACES)}`
      : `€${productBundle.price.toFixed(DECIMAL_PLACES)}`;
  };

  const getOldPriceIfDiscounted = () => {
    return productBundle.discountPercentage
      ? `€${productBundle.price.toFixed(DECIMAL_PLACES)}`
      : undefined;
  };

  return (
    <CartItem
      image={setCoverUrlToSummary(productBundle.cover)}
      title={productBundle.description}
      subtitle={`${productBundle.name} ${productBundle.courseLevels?.[0] || ''}`}
      price={getPriceWithPotentialDiscount()}
      oldPrice={getOldPriceIfDiscounted()}
      discountPercentage={productBundle.discountPercentage}
      {...props}
      onPress={onPress}
    />
  );
};
