import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { Colors } from '../../../styles/colors';

type Props = {
  shouldAlignCenter: boolean;
  isLeftContainer: boolean;
} & PropsWithChildren;

export const PracticeContainer = ({
  children,
  shouldAlignCenter,
  isLeftContainer,
}: Props) => (
  <Container
    shouldAlignCenter={shouldAlignCenter}
    isLeftContainer={isLeftContainer}
  >
    {children}
  </Container>
);

const Container = styled.div<{
  shouldAlignCenter: boolean;
  isLeftContainer: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ shouldAlignCenter }) =>
    shouldAlignCenter ? 'center' : 'flex-start'};
  padding: ${HEADER_HEIGHT_PX}px 20px;
  box-sizing: border-box;

  ${({ isLeftContainer }) =>
    isLeftContainer &&
    `margin-top: calc(${HEADER_HEIGHT_PX}px + env(safe-area-inset-top));
    background-color: ${Colors.backgroundColor};`}

  @media ${HookMediaQueries.isDesktop} {
    height: calc(100vh - ${HEADER_HEIGHT_PX}px);
    overflow-y: auto;
    padding: 72px 12.5%;
    ${({ isLeftContainer }) => isLeftContainer && `margin-top: 0;`}
  }
`;
