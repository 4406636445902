import styled from '@emotion/styled';
import { CalendarEvent } from '@schedule-x/calendar';
import { format } from 'date-fns';

import { SCHEDULE_TIME_FORMAT } from '../../constants/date';
import { Subject } from '../../models/Subject';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

export const CalendarEventComponent = ({
  calendarEvent,
}: {
  calendarEvent: CalendarEvent & Subject;
}) => {
  const startTime = new Date(calendarEvent.start);
  const endTime = new Date(calendarEvent.end);
  return (
    <CalendarEventContainer color={calendarEvent.color}>
      <EventTitle>{calendarEvent.code}</EventTitle>
      <ExamIntervalContainer>
        <ScheduleTime>{format(startTime, SCHEDULE_TIME_FORMAT)}</ScheduleTime>
        <ScheduleTime>{format(endTime, SCHEDULE_TIME_FORMAT)}</ScheduleTime>
      </ExamIntervalContainer>
    </CalendarEventContainer>
  );
};

const EventTitle = styled.p`
  margin: 0;
  ${TextStyles.ScheduleEventTitle}
`;

const ScheduleTime = styled.div`
  ${TextStyles.ScheduleEventTime}
`;

const ExamIntervalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
`;

const CalendarEventContainer = styled.div<{ color: string }>`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  color: ${Colors.calendarEventText};
  padding: 8px;
`;
