import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { ModalTypes, useModal } from '../../../../hooks/useModal';
import { Sections } from '../../../../models/Summary/summary';
import { useGetUserQuery } from '../../../../services/userApi';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { CircularProgress } from '../../../CircularProgress';
import { SecondaryButton } from '../../../common/Button';

export const ProgressCard = ({
  subjectColor,
  sections,
  subjectId,
  progressPercentage,
}: {
  subjectColor?: string;
  sections?: Sections;
  subjectId?: string;
  progressPercentage: number;
}) => {
  const { data: user } = useGetUserQuery();
  const { showModal } = useModal();

  const averageSubjectGrade = user?.subjects?.find(
    (userSubject) => userSubject._id === subjectId
  )?.grade;

  const t = useTranslations();

  const onCalculatePress = () => {
    showModal({
      type: ModalTypes.RequiredGradeModal,
      data: {
        subjectId,
      },
    });
  };

  return (
    <StyledCard>
      <CircularProgress
        subjectColor={subjectColor}
        progress={Number(progressPercentage)}
      />
      <StyledColumn full={true}>
        <Container>
          <Title>{t('common_average_score')}</Title>
          <Score>{sections?.subjectRating}</Score>
        </Container>
        <Container>
          <Title>{t('common_needed_score')}</Title>
          {averageSubjectGrade ? (
            <RequiredGrade color={subjectColor} onClick={onCalculatePress}>
              {averageSubjectGrade}
            </RequiredGrade>
          ) : (
            <SecondaryButton buttonSize="md" onClick={onCalculatePress}>
              {t('calculate')}
            </SecondaryButton>
          )}
        </Container>
      </StyledColumn>
    </StyledCard>
  );
};

const Container = styled.div`
  background-color: ${Colors.inputBackgroundColor};
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin: 8px 0;
  gap: 8px;
  border-radius: 4px;
  align-items: center;
`;

const Score = styled.span`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ProgressTitle}
`;

const RequiredGrade = styled.span<{ color: string | undefined }>`
  cursor: pointer;
  color: ${(props) => props.color || Colors.primaryTextColor};
  ${TextStyles.ProgressTitle}
`;
const Title = styled.span`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ProgressSubtitle}
`;

const StyledColumn = styled.div<{
  full?: boolean;
  width?: string;
  margin?: string;
}>`
  width: ${(props) => (props.full ? '100%' : props.width)};
  margin: ${(props) => props.margin};
`;
const StyledCard = styled.div`
  background-color: ${Colors.modalBackgroundColor};
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid ${Colors.dividerColor};
  box-shadow: 0px 2px 2px 0px ${Colors.shadowColor};
  padding: 20px;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 24px;
`;
