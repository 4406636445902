import { CardBase } from '../../../../components/common/CardBase';
import { BuyableItem } from '../../../dashboard/BuyableItem';
import { useRecommendedBundles } from '../../summary/hooks/useRecommendedBundles';

export const UpsellCard = ({ subjectId }: { subjectId?: string }) => {
  const buyableProducts = useRecommendedBundles(subjectId);
  return buyableProducts?.length ? (
    <CardBase fullWidth={true}>
      {buyableProducts.map((buyableProduct) => (
        <BuyableItem key={buyableProduct._id} productBundle={buyableProduct} />
      ))}
    </CardBase>
  ) : (
    <></>
  );
};
