import { UserRole } from '../models/UserRole';

export const ROLE_SELECTION = 1;
export const SUBJECT_SELECTION = ROLE_SELECTION + 1;
export const RELEVANT_SECTION_SELECTION = SUBJECT_SELECTION + 1;
export const STUDENT_TYPE_SELECTION = RELEVANT_SECTION_SELECTION + 1;

export const ONBOARDING_STEPS = {
  [UserRole.student]: 5,
  [UserRole.teacher]: 3,
  [UserRole.parentCaretaker]: 3,
};
