import styled from '@emotion/styled';

import { MediaQueries } from '../../../constants/MediaQueries';
import { Colors } from '../../../styles/colors';
import { BuyableItem } from '../../dashboard/BuyableItem';

import { useRecommendedBundles } from './hooks/useRecommendedBundles';
import { BuyableType } from './utils/summaryUtils';

type Props = {
  buyableTypeFilter: BuyableType;
  subjectId: string;
};

export const SummaryBuyables = ({ buyableTypeFilter, subjectId }: Props) => {
  const recommendations = useRecommendedBundles(
    subjectId,
    buyableTypeFilter,
    false
  );
  return (
    <ContentWrapper>
      {recommendations.length > 0 && (
        <Container>
          {recommendations.map((buyableProduct, i) => (
            <StyledBuyableItem key={i} productBundle={buyableProduct} />
          ))}
        </Container>
      )}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  border: 1px solid ${Colors.dividerColor};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 82px;
  margin-left: 20px;

  @media (min-width: ${MediaQueries.desktop}) {
    width: 960px;
  }
`;
const StyledBuyableItem = styled(BuyableItem)`
  flex: 1;
`;
