import styled from '@emotion/styled';
import { ChangeEventHandler } from 'react';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SearchFieldComponent } from '../../Inputs/SearchField';

type Props = {
  title: string;
  subTitle: string;
  searchBar: boolean;
  onChange?: ChangeEventHandler;
};

export const DefinitionCardHeader = ({
  title,
  subTitle,
  searchBar,
  onChange,
}: Props) => {
  const t = useTranslations();
  return (
    <CardHeader>
      <TitleWrapper>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </TitleWrapper>
      {searchBar && (
        <SearchWrapper>
          <SearchFieldComponent
            placeholder={t('search_placeholder')}
            onChange={onChange}
          />
        </SearchWrapper>
      )}
    </CardHeader>
  );
};

const CardHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
const TitleWrapper = styled.div`
  align-self: flex-start;
  flex: 4;
`;
const SearchWrapper = styled.div`
  flex: 3;
`;
const Title = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
const SubTitle = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
