import styled from '@emotion/styled';
import { useState } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { StarIcon } from '../Icons/StarIcon';

type RadioGridProps = {
  amountOfItems: number;
  onChange: (selected: number) => void;
  color: string;
};

export const StarRating = ({
  amountOfItems,
  onChange,
  color,
}: RadioGridProps) => {
  const [selected, setSelected] = useState<number | null>(null);

  const handleSelect = (index: number) => {
    setSelected(index);
    onChange(index);
  };

  return (
    <Grid>
      {[...Array(amountOfItems)].map((_, index) => (
        <Item key={index}>
          <StyledStarIcon
            key={index}
            width={32}
            color={
              selected !== null && selected >= index
                ? color
                : Colors.secondaryButtonFill
            }
            onClick={() => handleSelect(index)}
          />
        </Item>
      ))}
    </Grid>
  );
};
const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${Colors.primaryTextColor};
  ${TextStyles.InputHintText}
`;
const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: fit-content;
`;
const StyledStarIcon = styled(StarIcon)`
  width: 32px;
  height: 32px;
`;
