import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { makeSubjectSummaryRoute } from '../../constants/routes';
import { ModalTypes, useModal } from '../../hooks/useModal';
import { useProductAccess } from '../../hooks/useProductAccess';
import { Section } from '../../models/Summary/summary';
import { BuyableType } from '../../models/training/buyable';
import {
  useGetNextUnreadSectionsQuery,
  useGetSubjectByEncodedNameQuery,
  useGetSummarySectionsQuery,
} from '../../services/subjectsApi';
import { PrimaryHeaderText } from '../../styles/elements/PrimaryHeaderText';
import { SubjectColorButton } from '../common/Button';
import { CardBase } from '../common/CardBase';
import { SubjectImage } from '../common/SubjectImage';

export const ContinueLearningCard = () => {
  const t = useTranslations();
  const { showModal } = useModal();

  const { data: nextUnreadSections } = useGetNextUnreadSectionsQuery();

  const selectedSection = nextUnreadSections?.length
    ? nextUnreadSections[0]
    : undefined;

  const { data: subject } = useGetSubjectByEncodedNameQuery(
    selectedSection?.encodedSubjectName ?? skipToken
  );

  const { data: sections } = useGetSummarySectionsQuery(
    selectedSection?.encodedSubjectName ?? skipToken
  );

  const { hasChapterSectionAccess } = useProductAccess();

  const navigate = useNavigate();

  const findChapterSection = () => {
    if (!selectedSection || !sections) return undefined;

    let foundSection: Section | undefined;
    sections.chapters.forEach((chapter) => {
      chapter.sections.forEach((section) => {
        if (section.encodedSectionName === selectedSection.encodedSectionName) {
          foundSection = section;
        }
      });
    });
    return foundSection;
  };

  const onButtonPress = () => {
    if (!selectedSection || !sections) return;

    const chapterSection = findChapterSection();
    if (!chapterSection) {
      console.warn('No chapter section not found in continue learning card');
      return;
    }

    if (hasChapterSectionAccess(chapterSection, sections)) {
      const summaryRoute = makeSubjectSummaryRoute(
        selectedSection.encodedSubjectName
      );
      navigate(`${summaryRoute}?section=${selectedSection.encodedSectionName}`);
    } else {
      showModal({
        type: ModalTypes.BuySectionModal,
        data: {
          encodedSubjectName: selectedSection.encodedSubjectName,
          buyableType: BuyableType.summary,
        },
      });
    }
  };

  if (!selectedSection) {
    return <></>;
  }

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={<PrimaryHeaderText>{t('continue_learning')}</PrimaryHeaderText>}
    >
      <Container>
        <SubjectDetailsContainer>
          <SubjectImage src={subject?.subjectImage} />
          <SubjectTextContainer>
            <div>
              <strong>{subject?.name}</strong>
            </div>
            <div>{selectedSection.sectionTitle}</div>
          </SubjectTextContainer>
        </SubjectDetailsContainer>
        <SubjectButtonContainer>
          <SubjectColorButton
            buttonSize="md"
            buttonColor={selectedSection.subjectColor}
            onClick={onButtonPress}
          >
            {t('continue_learning_section')}
          </SubjectColorButton>
        </SubjectButtonContainer>
      </Container>
    </CardBase>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
`;

const SubjectDetailsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const SubjectTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubjectButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  white-space: nowrap;
`;
