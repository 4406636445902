import styled from '@emotion/styled';
import { ComponentProps } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type Props = ComponentProps<'textarea'>;

export const TextAreaComponent = ({ ...props }: Props) => {
  return (
    <InputContainer>
      <InputWrapper>
        <InputField {...props} />
      </InputWrapper>
    </InputContainer>
  );
};
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const InputField = styled.textarea`
  width: 100%;
  padding: 8px 48px 8px 12px;
  border: 1px solid ${Colors.inputBorderColor};
  border-radius: 4px;
  background-color: ${Colors.inputBackgroundColor};
  ${TextStyles.InputText};

  &:focus {
    outline: none;
    border-color: ${Colors.inputBorderColor};
  }
`;

export default TextAreaComponent;
