import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { FAQ_QUESTIONS } from '../../../constants/FAQ';
import { PrimaryHeaderText } from '../../../styles/elements/PrimaryHeaderText';
import { TextStyles } from '../../../styles/textStyles';
import { CardBase } from '../../common/CardBase';

import { FAQCategoryComponent } from './FAQCategoryComponent';

export const FAQContent = () => {
  const t = useTranslations();
  return (
    <Container>
      <CardBase
        fullWidth={true}
        fullWidthBody={true}
        header={<PrimaryHeaderText>{t('faq_page_title')}</PrimaryHeaderText>}
      >
        <ContentContainer>
          <FAQIntro1>{t('faq_intro_1')}</FAQIntro1>
          <FAQParagraph>
            <strong>{t('faq_intro_2')}</strong>
          </FAQParagraph>
          <FAQParagraph>{t('faq_intro_3')}</FAQParagraph>
        </ContentContainer>
      </CardBase>
      {FAQ_QUESTIONS.map(({ category, questions }) => (
        <FAQCategoryComponent
          key={category}
          category={category}
          questions={questions}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
`;

const ContentContainer = styled.div`
  padding: 20px;
`;

const FAQIntro1 = styled.p`
  white-space: pre-line;
  margin: 2px 0;
  ${TextStyles.FAQBaseText}
`;

const FAQParagraph = styled.p`
  margin: 2px 0;
  ${TextStyles.FAQBaseText}
`;
