import styled from '@emotion/styled';

import { useSubjectColorContext } from '../../../context/SubjectColorContext';
import { ResultTopics } from '../../../models/Results';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  topic: ResultTopics;
};

export const ResultTopicRow = ({ topic }: Props) => {
  const { subjectColor } = useSubjectColorContext();

  return (
    <TopicContainer>
      <TopicTitle>{topic.topicTitle}</TopicTitle>
      <RatingContainer>
        <RatingText>{topic.rating}</RatingText>
        <ImprovementRateText color={subjectColor}>
          {topic.improvementRate}
        </ImprovementRateText>
      </RatingContainer>
    </TopicContainer>
  );
};

const RatingContainer = styled.div``;

const TopicContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 12px 0;
  justify-content: space-between;
`;
const TopicTitle = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleSmall}
`;

const RatingText = styled.div`
  ${TextStyles.ProgressTitle}
  text-align: end;
`;

const ImprovementRateText = styled.div<{ color: string }>`
  ${TextStyles.ProgressSubtitle}
  color: ${({ color }) => color};
  text-align: end;
`;
