import styled from '@emotion/styled';

import {
  Chapter,
  Section,
  Sections,
  Topic,
} from '../../../models/Summary/summary';

import { NextTopic } from './NextTopic';
import { SummaryPracticeCard } from './SummaryPracticeCard';
import { SummaryTopic } from './SummaryTopic';

type Props = {
  chapter: Chapter;
  section: Section;
  color: string;
  summary: Sections;
  onSelectNextTopicClick: () => void;
  hasNextSection: boolean;
  isPracticeModeActive: boolean;
};

export const SummaryReader = ({
  chapter,
  section,
  color,
  summary,
  hasNextSection,
  onSelectNextTopicClick,
  isPracticeModeActive,
}: Props) => {
  return (
    <TopicContainer>
      {section?.topics.map((topic: Topic) => (
        <SummaryTopic key={topic.idxContent} color={color} topic={topic} />
      ))}
      {isPracticeModeActive && (
        <SummaryPracticeCard
          chapterTitle={chapter.chapterTitle}
          sectionTitle={section.sectionTitle}
          encodedChapterName={chapter.encodedChapterName}
          encodedSectionName={section.encodedSectionName}
          buttonColor={color}
          isPreview={summary.previewExercise && !section.preview}
          sectionRating={section.sectionRating}
        />
      )}
      {hasNextSection && (
        <NextTopic onSelectNextTopicClick={onSelectNextTopicClick} />
      )}
    </TopicContainer>
  );
};

const TopicContainer = styled.div`
  max-width: 540px;
  margin: auto;
`;
