import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { appRoutes, mainRoutesWithLogo } from '../../constants/routes';
import { Messages } from '../../global.d';
import { TextStyles } from '../../styles/textStyles';

import { CartNavComponent } from './CartNavComponent';

export const MobileHeader = () => {
  const t = useTranslations();
  const path = useLocation();

  const curRoute = mainRoutesWithLogo.find((route) => {
    return (
      path.pathname === route.to ||
      (path.pathname.includes(appRoutes.Profile) &&
        route.to.includes(appRoutes.Profile))
    );
  });

  return (
    <MobileTitleContainer>
      <>
        <MobileTitleContainer>
          <MobileTitle>
            {t(curRoute?.name as keyof Messages) || ' '}
          </MobileTitle>
        </MobileTitleContainer>
        <CartNavComponent />
      </>
    </MobileTitleContainer>
  );
};
const MobileTitleContainer = styled.div`
  display: flex;
`;

const MobileTitle = styled.h3`
  margin: 0;
  ${TextStyles.MobileHeaderTitle}
`;
