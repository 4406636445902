import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMediaQuery } from '@uidotdev/usehooks';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { useNavigate, useParams } from 'react-router-dom';

import {
  HookMediaQueries,
  MediaQueries,
} from '../../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { ZIndex } from '../../../constants/Zindex';
import { useGetSubjectByEncodedNameQuery } from '../../../services/subjectsApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { DateFormat } from '../../../utils/dateUtils';
import { ChevronLeft } from '../../Icons/ChevronLeft';
import { CartNavComponent } from '../../navbar/CartNavComponent';

export const MobileSubjectHeader = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const navigate = useNavigate();
  const { encodedSubjectName } = useParams();

  const { data: subject } = useGetSubjectByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );

  const getDateString = () => {
    if (!subject) return '';
    const date = new Date(subject.timeslots['1'].startDate);
    return format(date, DateFormat.fullDate, { locale: nl });
  };

  if (isDesktop) return <></>;

  return (
    <Container>
      <Navbar>
        <>
          <BackIconContainer onClick={() => navigate(-1)}>
            <ChevronLeft size="lg" />
          </BackIconContainer>
          <MobileTitleContainer>
            <MobileTitle>{subject?.name}</MobileTitle>
            <MobileSubTitle>{getDateString()}</MobileSubTitle>
          </MobileTitleContainer>
          <CartNavComponent />
        </>
      </Navbar>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  z-index: ${ZIndex.z50};
  box-shadow: 0px 0px 10px 0px ${Colors.navbarBoxShadow};
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(12px + env(safe-area-inset-top)) 0 12px 0;
  background-color: ${Colors.primaryNavbarBackgroundColor};
  height: ${HEADER_HEIGHT_PX - 24}px;
`;
const Navbar = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1080px;
  position: relative;
  @media (min-width: ${MediaQueries.desktop}) {
    justify-content: space-between;
  }
`;
const BackIconContainer = styled.div`
  position: absolute;
  left: 16px;
  cursor: pointer;
`;
const MobileTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MobileTitle = styled.h3`
  margin: 0;
  ${TextStyles.MobileHeaderTitle}
`;
const MobileSubTitle = styled.h3`
  margin: 0;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemBody};
`;
