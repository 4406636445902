import styled from '@emotion/styled';
import { useState } from 'react';

import { Illustrations } from '../../constants/Illustrations';
import { useGetUserQuery } from '../../services/userApi';
import { SecondaryButton } from '../common/Button';
import { ProfileImageRound } from '../common/ProfileImageRound';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';

import { ProfileMenu } from './ProfileMenu';

export const ProfileButton = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { data: user } = useGetUserQuery();

  return (
    <Container>
      <Button
        buttonSize="md"
        onClick={() => setShowProfileMenu(!showProfileMenu)}
      >
        <ButtonContainer>
          <ProfileImageRound src={user?.profileImage} />
          <RotatingIcon isExpanded={showProfileMenu}>
            <ArrowDownIcon />
          </RotatingIcon>
        </ButtonContainer>
      </Button>
      {showProfileMenu && (
        <ProfileMenu onClose={() => setShowProfileMenu(false)} />
      )}
    </Container>
  );
};
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const Button = styled(SecondaryButton)`
  padding: 4px 12px 4px 4px;
`;
const RotatingIcon = styled.div<{ isExpanded: boolean }>`
  transform: ${(props) =>
    props.isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s;
`;
const Container = styled.div`
  position: relative;
`;
