import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { useError } from '../../hooks/useError';
import { Error } from '../../services/baseApi';
import { usePutProductPairMutation } from '../../services/dashboardApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CardBase } from '../common/CardBase';
import Input from '../common/Input';

export const ProductCodeCard = () => {
  const t = useTranslations();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [pairProductTrigger] = usePutProductPairMutation();
  const { showErrorModal } = useError();

  const useCode = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setSuccess(false);
    try {
      await pairProductTrigger({ accessCode: code }).unwrap();
      setSuccess(true);
    } catch (error) {
      switch ((error as Error).status) {
        case 400:
          setError(t('invalid_code'));
          break;
        case 403:
          setError(t('code_already_used_by_someone_else'));
          break;
        case 404:
          setError(t('invalid_code'));
          break;
        case 409:
          setError(t('code_already_paired'));
          break;
        default:
          showErrorModal(error);
      }
    }
  };

  return (
    <CardBase fullWidth={true}>
      <form onSubmit={useCode}>
        <ProductCode>{t('product_code')}</ProductCode>
        <Input
          value={code}
          showAcceptButton={true}
          placeholder={t('input_your_code')}
          hasError={error !== ''}
          errorText={error}
          onChange={(el) => setCode(el.target.value)}
        />
        {success && <Success>{t('your_new_product_has_been_paired')}</Success>}
      </form>
    </CardBase>
  );
};
const ProductCode = styled.p`
  margin: 0;
  padding-bottom: 8px;
  color: ${Colors.inputHintText};
  ${TextStyles.ListItemTitle}
`;
const Success = styled.p`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyText}
`;
