import styled from '@emotion/styled';
import { ComponentProps, Ref } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type InputFieldProps = ComponentProps<'input'> & {
  hasError?: boolean;
  errorText?: string;
  inputRef?: Ref<HTMLInputElement>;
};

export const BigInput = ({
  hasError = false,
  errorText = '',
  inputRef,
  ...props
}: InputFieldProps) => {
  return (
    <InputContainer>
      <InputWrapper>
        <InputField hasError={hasError} ref={inputRef} {...props} />
      </InputWrapper>
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </InputContainer>
  );
};
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const InputField = styled.input<{ hasError?: boolean }>`
  width: 100%;
  padding: 16px;
  border: 1px solid ${Colors.inputBorderColor};
  border-radius: 4px;
  background-color: ${Colors.inputBackgroundColor};
  text-align: center;
  ${TextStyles.InputText};

  ${({ hasError }) => hasError && `border: 1px solid ${Colors.errorColor};`}

  &:focus {
    outline: none;
    border-color: ${Colors.inputBorderColor};
  }
`;

const ErrorText = styled.span`
  margin-top: 0.5rem;
  color: ${Colors.inputHintError};
  ${TextStyles.InputTextError};
`;
