import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { useGetTipOfTheDayQuery } from '../../services/dashboardApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CardBase } from '../common/CardBase';

export const TipOfTheDayCard = () => {
  const t = useTranslations();
  const { data: tip } = useGetTipOfTheDayQuery();

  return (
    <CardBase fullWidth={true} header={<Title>{t('tip_of_the_day')}</Title>}>
      <Description>{tip?.description}</Description>
    </CardBase>
  );
};
const Title = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const Description = styled.p`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyText};
`;
