import styled from '@emotion/styled';
import { motion } from 'framer-motion';

import { ProgressType } from '../constants/progress';
import { Colors } from '../styles/colors';
import { LOWEST_PASS_GRADE, LOWEST_PASS_PERCENTAGE } from '../utils/constants';
import { getFillPercents, getPercentage } from '../utils/mathUtils';

import { CircularProgressCounter } from './CircularProgressCounter';

type Props = {
  progress?: number;
  subjectColor?: string;
  type?: ProgressType;
};

const CIRCLE_WIDTH = 96;
const CIRCLE_RADIUS = 44;
const transition = {
  duration: 1,
  delay: 0.3,
  ease: 'easeIn',
};

export const CircularProgress = ({
  progress = 20,
  type = ProgressType.Percentage,
}: Props) => {
  const circumference = Math.ceil(2 * Math.PI * CIRCLE_RADIUS);
  const fillPercents = getFillPercents(
    circumference,
    type === ProgressType.Percentage ? progress : progress * 10
  );

  const strokeDashoffset = circumference - fillPercents;

  const isLowGrade = () => {
    if (type === ProgressType.Grade) {
      return progress < LOWEST_PASS_GRADE;
    }
    return progress < LOWEST_PASS_PERCENTAGE;
  };

  return (
    <Container>
      <CircularProgressCounter
        progress={
          type === ProgressType.Percentage
            ? getPercentage(fillPercents, circumference)
            : progress
        }
        type={type}
      />
      <svg>
        <circle
          cx={CIRCLE_WIDTH / 2}
          cy={CIRCLE_WIDTH / 2}
          r={44}
          fill="none"
          stroke={Colors.progressBarDefault}
          strokeWidth={8}
        />
        <CircleBackground
          cx={CIRCLE_WIDTH / 2}
          cy={CIRCLE_WIDTH / 2}
          initial={{ strokeDashoffset: circumference }}
          animate={{ strokeDashoffset: strokeDashoffset }}
          transition={transition}
          r={CIRCLE_RADIUS}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          isLowGrade={isLowGrade()}
        />
      </svg>
    </Container>
  );
};

const Container = styled.div`
  width: 96px;
  height: 96px;
  flex: 1;
  border-radius: 5px;
  position: relative;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
`;

const CircleBackground = styled(motion.circle)<{ isLowGrade?: boolean }>`
  fill: none;
  stroke: ${(props) =>
    props.isLowGrade ? Colors.textLessGrade : Colors.topicPositive};
  stroke-width: 8px;
  transform: rotate(-90deg);
  transform-origin: ${({ cx, cy }) => `${cx}px ${cy}px`};
  stroke-linecap: round;
  stroke-linejoin: round;
`;
