import styled from '@emotion/styled';
import { Dispatch, SetStateAction } from 'react';
import { useTranslations } from 'use-intl';

import { useGetPaymentOptionsQuery } from '../../services/cartApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CheckIcon } from '../Icons/CheckIcon';

type Props = {
  setPaymentOption: Dispatch<SetStateAction<string | undefined>>;
  selectedPaymentOption?: string;
};

export const CartPaymentOptions = ({
  setPaymentOption,
  selectedPaymentOption,
}: Props) => {
  const { data: paymentOptions } = useGetPaymentOptionsQuery();
  const t = useTranslations();

  return (
    <>
      {paymentOptions?.map((paymentOption, i) => (
        <Container
          selected={selectedPaymentOption === paymentOption.id}
          key={i}
        >
          <Image src={paymentOption.image.svg} />
          <Title>{paymentOption.description}</Title>
          <RadioCircle
            selected={selectedPaymentOption === paymentOption.id}
            onClick={() => setPaymentOption(paymentOption.id)}
          >
            <CheckIcon color={Colors.primaryButtonContentColor} />
          </RadioCircle>
        </Container>
      ))}
      <AgreementTitle>
        {t('agreement_title')}
        <AgreementTitleLink>{t('agreement_title_link')}</AgreementTitleLink>
      </AgreementTitle>
    </>
  );
};

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 10px 0px 10px 0px;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.selected ? Colors.primaryButtonFill : Colors.dividerColor};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    background-color: ${(props) =>
      props.selected ? Colors.primaryButtonFill : 'transparent'};
    opacity: 0.2;
    z-index: 0;
  }

  & > * {
    position: relative;
  }
`;
const RadioCircle = styled.div<{ selected: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid ${Colors.secondaryButtonStroke};
  cursor: pointer;
  background-color: ${(props) => props.selected && Colors.primaryButtonFill};
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.h1`
  flex: 1;
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemBody}
`;
const Image = styled.img`
  width: 24px;
  height: 20px;
`;

const AgreementTitle = styled.span`
  ${TextStyles.BodySmall}
  padding-bottom: 20px;
`;
const AgreementTitleLink = styled.span`
  color: ${Colors.linkTextSecondary};
  ${TextStyles.BodySmall}
`;
