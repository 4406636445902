import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  topicLevelIndicatorColor: string;
};

export const CMSContainer = ({
  topicLevelIndicatorColor,
  children,
}: PropsWithChildren<Props>) => (
  <Container topicLevelIndicatorColor={topicLevelIndicatorColor}>
    {children}
  </Container>
);

// This is the css that styles the html provided by the CMS
const Container = styled.div<{ topicLevelIndicatorColor: string }>`
  position: relative;

  /** COPY PROTECTON */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  color: ${Colors.primaryTextColor};

  p {
    ${TextStyles.SummaryReadingText}
    display: block;
    min-height: 1em;
    margin: 0;
    text-align: justify;
  }

  h1 {
    ${TextStyles.SummaryReadingTitle}
  }

  h2 {
    ${TextStyles.SummaryReadingTitle}
    margin-bottom: 16px;
  }

  h3 {
    ${TextStyles.SummaryReadingTitle}
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 24px;
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding-left: 28px;
  }

  ol {
    counter-reset: li;
    li {
      counter-increment: li;
      &:before {
        content: counter(li);
      }
    }
  }

  li {
    position: relative;
    line-height: 30px;
    counter-increment: li;

    &:before {
      content: '•';
      position: absolute;
      display: block;
      width: 10px;
      left: -20px;
      font-weight: bold;
      text-align: right;
    }
  }

  img {
    width: 100%;
  }

  svg {
    max-width: 100%;
  }

  iframe {
    border: none;
    border-radius: 8px;
    max-width: 100%;
    height: auto;
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  &:before {
    content: '';
    display: none;
    height: 100%;
    width: 4px;
    background-color: transparent;
    position: absolute;
    left: -56px;

    @media (max-width: 768px) {
      left: -20px;
    }
  }

  &:before {
    display: block;
    background-color: ${({ topicLevelIndicatorColor }) =>
      topicLevelIndicatorColor};
  }
`;
