import { Gender } from '../constants/gender';
import { Messages } from '../global.d';

export function genderNumberToTranslationKey(gender: Gender): keyof Messages {
  switch (gender) {
    case Gender.male:
      return 'gender_male';
    case Gender.female:
      return 'gender_female';
    case Gender.other:
      return 'gender_other';
    default:
      throw new Error('Invalid gender');
  }
}
