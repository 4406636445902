import styled from '@emotion/styled';
import { MathJax } from 'better-react-mathjax';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { SubjectColorProvider } from '../../../context/SubjectColorContext';
import { QuestionType } from '../../../models/training/Question';
import { Training } from '../../../models/training/training';
import { useGetSubjectByEncodedNameQuery } from '../../../services/subjectsApi';
import { useGetThesisByIdQuery } from '../../../services/trainingApi';
import { Colors } from '../../../styles/colors';

import { PracticeQA } from './PracticeQA';
import { PracticeThesis } from './PracticeThesis';
import { QAHeader } from './QAHeader';
import { ThesisHeader } from './ThesisHeader';

type Props = {
  encodedSubjectName: string;
  training: Training;
};

/* 
A user can start a training from the StartTrainingModal. 
When a training is created on the backend, it contains multiple theses.
You can think of a thesis as an 'exam question'.
A thesis can contain a single question, often the case with multiple choice or dictation (translation) questions. Then the question will be displayed on the left, and answer input on the right.
A thesis can also contain a question with a source, and multiple questions surrounding that source. Then the source will be displayed left and the multiple questions on the right.
The points given to a user is calculated on the backend in case of a multiple choice or dictation question. In the case of an open question, the user will amount points to her/it/himself.
*/
export const Practice = ({ encodedSubjectName, training }: Props) => {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState({
    questionCounter: training.questionsAnsweredCount + 1,
    currentThesisId: training.lastThesisId,
  });

  const { data: subject } = useGetSubjectByEncodedNameQuery(encodedSubjectName);
  const { data: thesis } = useGetThesisByIdQuery(state.currentThesisId);
  const navigate = useNavigate();

  const incrementQuestionCounter = () => {
    setState((prevState) => ({
      ...prevState,
      questionCounter: prevState.questionCounter + 1,
    }));
  };

  const toNextThesis = () => {
    const index = training.thesisIds.indexOf(state.currentThesisId);
    if (training.thesisIds[index + 1]) {
      setState((prevState) => ({
        ...prevState,
        currentThesisId: training.thesisIds[index + 1],
      }));
    } else {
      showResult();
    }
  };

  const showResult = async () => {
    navigate(`../result?${searchParams}`, { relative: 'path' });
  };

  const currentQuestion = thesis?.questions[0];
  const isSimpleQuestion =
    (currentQuestion && !currentQuestion.title) ||
    currentQuestion?.title === '';
  const isDictationQuestion =
    thesis?.questions[0].type === QuestionType.Dictation;

  return (
    <Container>
      <SubjectColorProvider subjectColor={subject?.color || ''}>
        <MathJax key={thesis?._id}>
          <ContentWrapper>
            <Left>
              <ThesisHeader
                currentQuestionIndex={state.questionCounter}
                amountOfQuestions={training.questionCount || 0}
                thesisId={thesis?._id ?? ''}
              />
              {currentQuestion && (
                <PracticeThesis
                  thesis={thesis}
                  shouldAlignCenter={isSimpleQuestion || isDictationQuestion}
                  isDictationQuestion={isDictationQuestion}
                />
              )}
            </Left>
            <Right>
              {thesis && (
                <>
                  <QAHeader thesisId={thesis._id} />
                  <PracticeQA
                    thesis={thesis}
                    nextThesis={toNextThesis}
                    incrementQuestionCounter={incrementQuestionCounter}
                    shouldAlignCenter={isSimpleQuestion || isDictationQuestion}
                    encodedSubjectName={encodedSubjectName}
                    lastQuestionId={training.lastQuestionId}
                  />
                </>
              )}
            </Right>
          </ContentWrapper>
        </MathJax>
      </SubjectColorProvider>
    </Container>
  );
};

const ContentWrapper = styled.div`
  @media ${HookMediaQueries.isDesktop} {
    width: 100vw;
    display: flex;
    max-height: 100vh;
    overflow: hidden;
  }

  * > img {
    max-width: 100%;
  }

  * > svg {
    max-width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 2;
  flex-grow: 2;
  flex-shrink: 2;

  background-color: ${Colors.modalBackgroundColor};
  min-height: 100vh;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
  }
`;
const Left = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;

  @media ${HookMediaQueries.isDesktop} {
    flex: 1;
    max-height: 100vh;
  }
`;
const Right = styled.div`
  flex: 1;
`;
