import { Suspense, useEffect, useState } from 'react';
import { lazily } from 'react-lazily';

import { useModal } from '../../hooks/useModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import { Modal } from './Modal';

// copied ModalRoot from Teacherdashboard for consistency. Merge when code bases are combined.
export function ModalRoot() {
  const dispatch = useAppDispatch();
  const { clearModal } = useModal();
  const { type, data, modalActive } = useAppSelector((state) => state.modal);
  const resetTimerMs = 500;
  const [DynamicComponent, setDynamicComponent] = useState<any>();

  useEffect(() => {
    const lazyComponent = lazily(() => import(`../modals/${type}`));
    const dynamicComponent = lazyComponent[type];
    setDynamicComponent(dynamicComponent);
  }, [type]);

  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (!modalActive) {
      timeOut = setTimeout(() => {
        clearModal();
      }, resetTimerMs);
    }

    return () => {
      window.clearTimeout(timeOut);
    };
  }, [dispatch, modalActive]);

  if (type === '') return <></>;
  return (
    <Modal>
      <Suspense fallback={''}>
        {DynamicComponent && <DynamicComponent data={data} />}
      </Suspense>
    </Modal>
  );
}
