import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT } from '../../../constants/Sizes';
import { Category, SubCategory } from '../../../models/Definition';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { NoSearchResultsIcon } from '../../Icons/NoSearchResultsIcon';

import { CategoryList } from './CategoryList';

type Props = {
  categories: Category[];
  selectedSubCategory?: SubCategory;
  onChangeVisibleCategory: (categoryId: string) => void;
};

export const DefinitionCardBody = ({
  categories,
  selectedSubCategory,
  onChangeVisibleCategory,
}: Props) => {
  const t = useTranslations();
  const noSearchResults = () => !categories || categories.length == 0;

  return (
    <TableContent>
      <CategoryList
        categories={categories}
        onChangeVisibleCategory={onChangeVisibleCategory}
        selectedSubCategory={selectedSubCategory}
      />
      {noSearchResults() && (
        <NoResultsContainer>
          <NoSearchResultsIcon color={Colors.primaryIconColor} size={'xl'} />
          <Text>{t('no_search_results')}</Text>
        </NoResultsContainer>
      )}
    </TableContent>
  );
};

const TableContent = styled.div`
  padding-bottom: 20px;
`;
const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - ${HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT});
  justify-content: center;
  align-items: center;
`;
const Text = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
