import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';

import { SocketInterface } from './socket.types';

class SocketConnection implements SocketInterface {
  public socket: Socket;
  constructor(
    url: string,
    options?: Record<string, Partial<ManagerOptions & SocketOptions>>
  ) {
    this.socket = io(url, options);
  }
}

class SocketFactory {
  public static create(
    url: string,
    options?: Record<string, any>
  ): SocketConnection {
    return new SocketConnection(url, options);
  }
}

export default SocketFactory;
