import { useTranslations } from 'use-intl';

import { Dropdown } from '../../components/common/Dropdown';
type Props = {
  selectedPeriod: number;
  onOptionClick: (value: number) => void;
  numberOfTimeslots: string | undefined;
};

export const PeriodDropdown = ({
  selectedPeriod,
  onOptionClick,
  numberOfTimeslots,
}: Props) => {
  const t = useTranslations();

  const handleOnChange = (option: string | number) => {
    onOptionClick(Number(option));
  };

  const options = Array.from(
    { length: Number(numberOfTimeslots || 1) },
    (v, k) => ({
      label: t('school_period', { count: k + 1 }),
      value: k + 1,
    })
  );

  return (
    <>
      <Dropdown
        onChange={handleOnChange}
        selectedItem={t('school_period', { count: selectedPeriod })}
        options={options}
      />
    </>
  );
};
