import { UserSubject } from '../../models/Subject';
import { CardBase } from '../common/CardBase';

import { ProfileCardHeader } from './ProfileCardHeader';
import { SubjectRow } from './subjects/SubjectRow';

type Props = {
  userExtraSubjects?: UserSubject[];
};

export const ExtraSubjectsCard = ({ userExtraSubjects }: Props) => {
  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={<ProfileCardHeader titleTranslationKey="extra_subjects" />}
    >
      {userExtraSubjects?.map((subject) => {
        return <SubjectRow key={subject.subject._id} userSubject={subject} />;
      })}
    </CardBase>
  );
};
