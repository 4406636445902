import { Section } from '../models/Summary/summary';
import { TrainingResults } from '../models/training/trainingResults';

export const getPercentage = (partialValue: number, totalValue: number) =>
  (partialValue / totalValue) * 100;

export const calcSubjectProgress = (
  sections: Section[],
  sectionsRead?: Section[],
  result?: TrainingResults[]
) => {
  let progressPercentageSummary = 0;
  let progressPercentageTraining = 0;

  if (sections.length > 0) {
    progressPercentageSummary = (sectionsRead?.length || 0) / sections.length;
  }

  const sectionsWithPracticeModule = sections.filter(
    (section) => section.hasPracticeModule
  );

  if (sectionsWithPracticeModule.length > 0) {
    progressPercentageTraining =
      (result?.length || 0) / sectionsWithPracticeModule.length;
  }

  progressPercentageSummary = isNaN(progressPercentageSummary)
    ? 0
    : progressPercentageSummary;
  progressPercentageTraining = isNaN(progressPercentageTraining)
    ? 0
    : progressPercentageTraining;

  if (!isFinite(progressPercentageTraining)) {
    progressPercentageTraining = 0;
  }

  const progressPercentage = (
    ((progressPercentageSummary + progressPercentageTraining) / 2) *
    10
  ).toFixed(1);

  return {
    progressPercentage,
    progressPercentageTraining,
    progressPercentageSummary,
  };
};

export const uniqueByKey = <T>(array: T[], key: keyof T): T[] => {
  return Array.from(new Map(array.map((item) => [item[key], item])).values());
};

export const flattenArray = <T>(array: T[][]): T[] => {
  return array.reduce((acc, item) => acc.concat(item), []);
};

export const getFillPercents = (circumference: number, progress: number) => {
  return Math.abs(Math.ceil((circumference / 100) * progress));
};
