import { Section, Sections } from '../models/Summary/summary';

export const useProductAccess = () => {
  const hasChapterSectionAccess = (
    chapterSection: Section,
    summarySections: Sections
  ) => {
    return !!chapterSection.preview || !summarySections.previewSummary;
  };

  const hasExplanationVideoAccess = (
    chapterSection: Section,
    summarySections: Sections
  ) => {
    return (
      summarySections.videosActive &&
      (summarySections.unlockedExplanationVideos || chapterSection.preview)
    );
  };

  const hasBoughtExplanationVideo = (summarySections: Sections) => {
    return (
      summarySections.videosActive && summarySections.unlockedExplanationVideos
    );
  };

  const hasExamChallengeAccess = (
    chapterSection: Section,
    summarySections: Sections
  ) => {
    return (
      chapterSection.hasPracticeModule &&
      summarySections.practiceModuleActive &&
      (chapterSection.preview || !summarySections.previewExercise)
    );
  };

  const hasAccessToOneOfTheModules = (
    chapterSection: Section,
    summarySections: Sections
  ) => {
    return (
      hasChapterSectionAccess(chapterSection, summarySections) ||
      hasExplanationVideoAccess(chapterSection, summarySections) ||
      hasExamChallengeAccess(chapterSection, summarySections)
    );
  };

  return {
    hasChapterSectionAccess,
    hasExplanationVideoAccess,
    hasExamChallengeAccess,
    hasAccessToOneOfTheModules,
    hasBoughtExplanationVideo,
  };
};
