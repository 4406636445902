import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { appRoutes } from '../constants/routes';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { PrimaryButton } from './common/Button';

export const NoSubjects = () => {
  const t = useTranslations();
  const navigate = useNavigate();

  return (
    <Container>
      <Text>{t('no_subjects')}</Text>
      <PrimaryButton
        buttonSize="md"
        onClick={() => navigate(appRoutes.Profile)}
      >
        {t('to_profile')}
      </PrimaryButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyMedium}
`;
