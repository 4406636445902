import { Socket } from 'socket.io-client';

export enum SocketEvent {
  timeTracking = 'TimeTracking',
  loginRegister = 'register',
  loginDevice = 'device',
  badgeCompleted = 'exo.badge.completed',
}

export interface SocketInterface {
  socket: Socket;
}
