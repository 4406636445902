import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router-dom';

import { Illustrations } from '../../constants/Illustrations';
import { HookMediaQueries } from '../../constants/MediaQueries';
import { BaseNavHeader } from '../navbar/BaseNavHeader';

export const OnboardingHeader = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const navigate = useNavigate();

  if (!isDesktop) {
    return <></>;
  }

  return (
    <BaseNavHeader>
      <LeftContainer>
        <Logo src={Illustrations.exoLogo} onClick={() => navigate('/login')} />
      </LeftContainer>
    </BaseNavHeader>
  );
};
const Logo = styled.img`
  margin-right: 8px;
  height: 38px;
  cursor: pointer;
`;
const LeftContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
