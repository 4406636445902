import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Messages } from '../../../global.d';
import { useLazyGetUserExistsQuery } from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { InputFieldComponent } from '../../common/Input';
import { useOnboarding } from '../hooks/useOnboarding';
import { LoginCardBase } from '../LoginCardBase';
import { LoginSocial } from '../social/LoginSocial';

import { RegisterCheckbox } from './RegisterCheckbox';
import { RegisterFooter } from './RegisterFooter';
import { RegisterSchema, FormData, FormFields } from './RegisterFormSchema';

export const RegisterCard = () => {
  const t = useTranslations();
  const [getUserExists] = useLazyGetUserExistsQuery();
  const navigate = useNavigate();
  const { onboardUser } = useOnboarding();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(RegisterSchema),
  });

  const { ref: emailRef, ...emailRegister } = register(FormFields.email);
  const { ref: passwordRef, ...passwordRegister } = register(
    FormFields.password
  );
  const { ref: repeatPasswordRef, ...repeatPasswordRegister } = register(
    FormFields.repeatPassword
  );
  const { ref: checkboxRef, ...checkboxRegister } = register(
    FormFields.termsAndConditions
  );

  const onSubmit = (data: FormData) =>
    handleRegister(data.email, data.password);

  const handleRegister = async (email: string, password: string) => {
    const result = await getUserExists(email);
    if (result.error) {
      setError(FormFields.email, {
        type: 'manual',
        message: t('no_valid_email'),
      });
    }

    const user = result.data;

    if (!user) {
      onboardUser(email, password);
    } else {
      setError(FormFields.termsAndConditions, {
        type: 'manual',
        message: t('user_already_exists'),
      });
    }
  };

  const getErrorText = (error?: FieldError) =>
    error?.message !== undefined ? t(error.message as keyof Messages) : '';

  return (
    <LoginCardBase
      onSubmit={handleSubmit(onSubmit)}
      header={<Title>{t('register')}</Title>}
      footerButtons={
        <RegisterFooter onAlreadyHaveAccountClick={() => navigate('/login')} />
      }
    >
      <LoginSocial />
      <InputFieldComponent
        label={t('e-mail')}
        type="e-mail"
        hasError={errors.email !== undefined}
        errorText={getErrorText(errors.email)}
        placeholder={t('placeholder_e-mail')}
        {...emailRegister}
        inputRef={emailRef}
      />
      <InputFieldComponent
        label={t('password')}
        type="password"
        hasError={errors.password !== undefined}
        errorText={getErrorText(errors.password)}
        placeholder={t('placeholder_password')}
        {...passwordRegister}
        inputRef={passwordRef}
      />
      <InputFieldComponent
        label={t('repeat_password')}
        type="password"
        hasError={errors.repeatPassword !== undefined}
        errorText={getErrorText(errors.repeatPassword)}
        placeholder={t('placeholder_password')}
        {...repeatPasswordRegister}
        inputRef={repeatPasswordRef}
      />
      <RegisterCheckbox
        hasError={errors.termsAndConditions !== undefined}
        errorText={getErrorText(errors.termsAndConditions)}
        {...checkboxRegister}
        inputRef={checkboxRef}
      />
    </LoginCardBase>
  );
};

const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText};
`;
