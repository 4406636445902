import styled from '@emotion/styled';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type ButtonSize = 'md' | 'sm';
type ButtonTextSize = 'xs' | 'sm' | 'lg';

const SUBJECT_COLOR_HEX_TO_LIGHTER_COLOR_SUFFIX = 'CC';
const SUBJECT_COLOR_HEX_TO_DISABLED_COLOR_SUFFIX = 'BB';

const BaseButton = styled.button<{
  buttonSize: ButtonSize;
  textSize?: ButtonTextSize;
  withIcon?: boolean;
  fullWidth?: boolean;
  buttonColor?: string;
}>`
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  padding: ${(props) => (props.buttonSize === 'sm' ? '4px 12px' : '8px 16px')};
  ${TextStyles.ButtonTextSmallText}
  ${({ textSize }) => textSize == 'xs' && TextStyles.ButtonTextExtraSmallText}
  ${({ textSize }) => textSize == 'sm' && TextStyles.ButtonTextSmallText}
  ${({ textSize }) => textSize == 'lg' && TextStyles.ButtonTextLargeText}
  ${({ withIcon }) => withIcon && 'padding-right: 64px;'}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  transition: all 0.3s ease;
  &:disabled {
    cursor: default;
    pointer-events: none;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: ${(props) =>
    props.buttonColor ? props.buttonColor : Colors.primaryButtonFill};
  color: ${Colors.primaryButtonContentColor};
  border: 1px solid ${Colors.primaryButtonStroke};

  &:hover {
    background-color: ${Colors.primaryButtonHover};
  }
  &:disabled {
    color: ${Colors.primaryButtonContentColorDisabled};
  }
`;

export const SecondaryButton = styled(BaseButton)`
  background-color: ${Colors.secondaryButtonFill};
  color: ${Colors.secondaryButtonContentColor};
  border: 1px solid ${Colors.secondaryButtonStroke};
  &:hover {
    background-color: ${Colors.secondaryButtonHover};
  }
  &:disabled {
    color: ${Colors.secondaryButtonContentColorDisabled};
  }
`;

export const FacebookButton = styled(BaseButton)`
  ${TextStyles.ButtonTextSmallText}
  background-color: ${Colors.facebookButtonFill};
  color: ${Colors.facebookButtonContentColor};
  border: 1px solid ${Colors.facebookButtonStroke};
  &:hover {
    background-color: ${Colors.facebookButtonHover};
  }
  &:disabled {
    color: ${Colors.facebookButtonContentColorDisabled};
  }
`;

export const GoogleButton = styled(BaseButton)`
  ${TextStyles.ButtonTextSmallText}
  background-color: ${Colors.googleButtonFill};
  color: ${Colors.googleButtonContentColor};
  border: 1px solid ${Colors.googleButtonStroke};
  &:hover {
    background-color: ${Colors.googleButtonHover};
  }
  &:disabled {
    color: ${Colors.googleButtonContentColorDisabled};
  }
`;

export const AppleButton = styled(BaseButton)`
  ${TextStyles.ButtonTextSmallText}
  background-color: ${Colors.appleButtonFill};
  color: ${Colors.appleButtonContentColor};
  border: 1px solid ${Colors.appleButtonStroke};
  &:hover {
    background-color: ${Colors.appleButtonHover};
  }
  &:disabled {
    color: ${Colors.appleButtonContentColorDisabled};
  }
`;

export const ErrorButton = styled(BaseButton)`
  ${TextStyles.ButtonTextSmallText}
  background-color: ${Colors.errorColor};
  color: ${Colors.errorButtonContentColor};
  border: 1px solid ${Colors.errorButtonStroke};
  &:hover {
    background-color: ${Colors.errorButtonHover};
  }
  &:disabled {
    color: ${Colors.errorButtonContentColorDisabled};
  }
`;

export const SubjectColorButton = styled(BaseButton)`
  background-color: ${(props) =>
    props.buttonColor ? props.buttonColor : Colors.secondaryButtonFill};
  color: ${Colors.primaryButtonContentColor};
  border: 1px solid
    ${(props) =>
      props.buttonColor
        ? props.buttonColor + SUBJECT_COLOR_HEX_TO_LIGHTER_COLOR_SUFFIX
        : Colors.secondaryButtonStroke};
  &:hover {
    background-color: ${(props) =>
      props.buttonColor
        ? props.buttonColor + SUBJECT_COLOR_HEX_TO_LIGHTER_COLOR_SUFFIX
        : Colors.secondaryButtonFill};
  }
  &:disabled {
    background-color: ${(props) =>
      props.buttonColor
        ? props.buttonColor + SUBJECT_COLOR_HEX_TO_DISABLED_COLOR_SUFFIX
        : Colors.secondaryButtonFill};
  }
`;
