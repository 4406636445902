import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { PlusIcon } from '../Icons/PlusIcon';

export const enum CompleteProfileStepEnum {
  Username = 'username',
  School = 'school',
}

type Props = {
  step: CompleteProfileStepEnum;
  onClick: () => void;
};

export const CompleteProfileStep = ({ step, onClick }: Props) => {
  const t = useTranslations();
  return (
    <StepContainer onClick={onClick}>
      <Row>
        <AddButton>
          <PlusIcon />
        </AddButton>
        <Column>
          <Subtitle>{t('next_step')}</Subtitle>
          <Title>
            {t(
              step === CompleteProfileStepEnum.Username
                ? 'choose_your_username'
                : 'choose_your_school'
            )}
          </Title>
        </Column>
      </Row>
      <Emoji>
        {t(
          step === CompleteProfileStepEnum.Username
            ? 'username_emoji'
            : 'school_emoji'
        )}
      </Emoji>
    </StepContainer>
  );
};
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  &:hover button {
    background-color: ${Colors.secondaryButtonHover};
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${Colors.progressBarDefault};
  padding: 0px 16px;
  cursor: pointer;
`;
const AddButton = styled.button`
  border-radius: 100px;
  padding: 6px !important;
  height: 32px;
  width: 32px;
  background-color: ${Colors.outlineBackgroundButton};
  border: none;
  cursor: pointer;
`;
const Subtitle = styled.p`
  margin: 0 0 0 8px;
  color: ${Colors.secondaryButtonContentColor};
  ${TextStyles.CardSubtitleText}
`;
const Title = styled.h1`
  margin: 0 0 0 8px;
  color: ${Colors.textNormal};
  ${TextStyles.CardTitleText}
`;
const Emoji = styled.p`
  font-size: 24px;
`;
