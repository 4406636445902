import { useTranslations } from 'use-intl';

import { PrimaryButton, SecondaryButton } from '../../common/Button';

type Props = {
  onSkipClick: () => void;
  linkDisabled: boolean;
};

export const LinkProductCardFooter = ({ onSkipClick, linkDisabled }: Props) => {
  const t = useTranslations();

  return (
    <>
      <SecondaryButton buttonSize="md" onClick={onSkipClick}>
        {t('skip')}
      </SecondaryButton>
      <PrimaryButton buttonSize="md" type="submit" disabled={linkDisabled}>
        {t('link_product')}
      </PrimaryButton>
    </>
  );
};
