import styled from '@emotion/styled';
import DOMPurify from 'dompurify';

import { Topic } from '../../../models/Summary/summary';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

import { CMSContainer } from './CMSContainer';

const LOW_RATING = 5.5;
const HIGH_RATING = 7.5;

type Props = {
  color: string;
  topic: Topic;
};

const DOM_PURIFY_IGNORE_TAGS_FROM_CMS = ['iframe', 'svg', 'use', 'g'];

export const SummaryTopic = ({ color, topic }: Props) => {
  const ratingColorLabel = (rating: number) => {
    if (!rating) return '';

    if (rating < LOW_RATING) return Colors.topicNegative;
    if (rating >= HIGH_RATING) return Colors.topicPositive;
    return Colors.topicMedium;
  };

  return (
    <CMSContainer topicLevelIndicatorColor={ratingColorLabel(topic.rating)}>
      <TopicTitle
        color={color}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(topic.topicTitle),
        }}
      />
      <TopicInner
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(topic.html, {
            ADD_TAGS: DOM_PURIFY_IGNORE_TAGS_FROM_CMS,
          }),
        }}
      />
    </CMSContainer>
  );
};
const TopicTitle = styled.div<{ color: string }>`
  padding: 16px 0 8px 0;
  ${({ color }) => color && `color: ${color};`};
  ${TextStyles.SummaryReadingTitle}
`;
const TopicInner = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.SummaryReadingText}
`;
