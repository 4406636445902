import styled from '@emotion/styled';
import { useState } from 'react';

import { Subject } from '../../../models/Subject';
import { Section } from '../../../models/Summary/summary';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SubjectImage } from '../../common/SubjectImage';
import { ArrowDownIcon } from '../../Icons/ArrowDownIcon';

import { SubjectSectionRow } from './SubjectSectionRow';

type Props = {
  subject: Subject;
  showCourseLevel: boolean;
  onSelect: (section: Section, subject: Subject) => void;
  selectedSectionName: string | undefined;
};

export const SubjectRow = ({
  subject,
  showCourseLevel,
  onSelect,
  selectedSectionName,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <>
      <Container onClick={() => setIsExpanded(!isExpanded)}>
        <StyledSubjectImage src={subject?.subjectImage} />
        <TextContainer>
          <Title>{subject.name}</Title>
          {showCourseLevel && <CourseLevel>{subject.courseLevel}</CourseLevel>}
        </TextContainer>
        <TrailerContainer>
          <RotatingIcon isExpanded={isExpanded}>
            <ArrowDownIcon />
          </RotatingIcon>
        </TrailerContainer>
      </Container>

      {isExpanded && (
        <SectionContainer>
          {subject.sections?.map((section: Section) => (
            <SubjectSectionRow
              key={section.encodedSectionName}
              section={section}
              onSelect={(section) => onSelect(section, subject)}
              selected={selectedSectionName === section.encodedSectionName}
            />
          ))}
        </SectionContainer>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 20px;
`;

const SectionContainer = styled.div`
  padding: 20px;
`;
const TextContainer = styled.div`
  flex: 1;
`;
const Title = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;

const CourseLevel = styled.div`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;

const TrailerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const RotatingIcon = styled.div<{ isExpanded: boolean }>`
  transform: ${(props) =>
    props.isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s;
  cursor: pointer;
`;

const StyledSubjectImage = styled(SubjectImage)`
  width: 48px;
  height: 48px;
`;
