import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import { useGetSubjectTipsByEncodedNameQuery } from '../../../services/subjectsApi';
import { CardBase } from '../../common/CardBase';

import { Tip } from './Tip';

export const SubjectTips = () => {
  const { encodedSubjectName } = useParams();
  const { data } = useGetSubjectTipsByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );

  return (
    <CardBase fullWidth={true}>
      {data?.map((tip) => (
        <Tip key={tip._id} title={tip.title} description={tip.description} />
      ))}
    </CardBase>
  );
};
