import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Token } from '../../models/User';
import { SocialProvider } from '../../utils/socialLoginUtils';
import { Slices } from '../Slices';

export type UserState = {
  token: Token | undefined;
  socialLoginProvider: SocialProvider | undefined;
  newUser: boolean;
  hasOpenedSummaryTutorial: boolean;
  loggingOut: boolean;
};

const initialState: UserState = {
  token: undefined,
  socialLoginProvider: undefined,
  newUser: false,
  hasOpenedSummaryTutorial: false,
  loggingOut: false,
};

const userSlice = createSlice({
  name: Slices.User,
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<Token>) => {
      state.token = action.payload;
      // This is needed for the TeacherDashboard to work
      localStorage.setItem('token', JSON.stringify(action.payload));
    },
    clearUser: (state) => {
      state.token = undefined;
      state.socialLoginProvider = undefined;
      state.newUser = false;
    },
    setSocialLoginProvider: (state, action: PayloadAction<SocialProvider>) => {
      state.socialLoginProvider = action.payload;
    },
    clearSocialLoginProvider: (state) => {
      state.socialLoginProvider = undefined;
    },
    setNewUser: (state) => {
      state.newUser = true;
    },
    welcomeMessageClosed: (state) => {
      state.newUser = false;
    },
    setHasSummaryTutorialOpened: (state) => {
      state.hasOpenedSummaryTutorial = true;
    },
  },
});

export const {
  clearUser,
  setToken,
  setSocialLoginProvider,
  clearSocialLoginProvider,
  setNewUser,
  welcomeMessageClosed,
  setHasSummaryTutorialOpened,
} = userSlice.actions;
export default userSlice.reducer;
