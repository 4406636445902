import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { PrimaryButton, SecondaryButton } from '../../common/Button';

type RegisterFooterProps = {
  onAlreadyHaveAccountClick: () => void;
};

export const RegisterFooter = ({
  onAlreadyHaveAccountClick,
}: RegisterFooterProps) => {
  const t = useTranslations();
  return (
    <>
      <StyledSecondaryButton
        buttonSize="md"
        onClick={onAlreadyHaveAccountClick}
      >
        {t('i_already_have_an_account')}
      </StyledSecondaryButton>
      <PrimaryButton buttonSize="md" type="submit">
        {t('register')}
      </PrimaryButton>
    </>
  );
};

const StyledSecondaryButton = styled(SecondaryButton)`
  text-wrap: nowrap;
`;
