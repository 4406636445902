import styled from '@emotion/styled';

import { ProfileIndex } from '../../components/profile/ProfileIndex';

export const Profile = () => {
  return (
    <Container>
      <ProfileIndex />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
`;
