export type PaymentLink = {
  href: string;
  type: string;
};
export type Order = {
  bundles: string[];
  user: any;
  deliveryAddress: DeliveryAddress;
};

export type Amount = {
  value: string;
  currency: string;
};
export type Payment = {
  amount: Amount;
  description: string;
  method?: string;
  order: Order;
  redirectUrl: string;
  discountCode?: string;
  issuer?: string;
  webhookUrl?: string;
  locale?: string;
  metadata?: string;
  sequenceType?: string;
  customerId?: string;
  mandateId?: string;
  profileId?: string;
  testmode?: boolean;
  status?: PaymentStatus;
};

export type PaymentResponse = {
  payment: {
    _links: {
      self: PaymentLink;
      checkout: PaymentLink;
      documentation: PaymentLink;
    };
    resource: string;
    id: string;
    mode: string;
    createdAt: string;
    amount: Amount;
    description: string;
    method?: string;
    metadata: any;
    status: string;
    isCancelable: boolean;
    expiresAt: string;
    details: any;
    profileId: string;
    sequenceType: string;
    redirectUrl: string;
    webhookUrl: string;
    paymentLink?: string;
  };
  order: Order;
};

export enum PaymentStatus {
  open = 'open',
  paid = 'paid',
  failed = 'failed',
  canceled = 'canceled',
  expired = 'expired',
  empty = 'empty',
}

export type DeliveryAddress = {
  country: string;
  street: string;
  postalCode: string;
  city: string;
  streetNumber: string;
  addition: string;
};

export type PaymentImage = {
  size1x: string;
  size2x: string;
  svg: string;
};
export type Issuer = {
  resource: string;
  id: string;
  name: string;
  image: PaymentImage;
};

export type PaymentOption = {
  resource: string;
  id: string;
  description: string;
  minimumAmount: Amount;
  maximumAmount: Amount;
  image: PaymentImage;
  _links: {
    _self: PaymentLink;
  };
  issuers?: Issuer[];
};

export type UserShipment = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};
