import styled from '@emotion/styled';

import { Section } from '../../../models/Summary/summary';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

import { CurrentSummarySegment } from './hooks/useSummaryData';

type SideMenuChapterSectionProps = {
  section: Section;
  current: CurrentSummarySegment;
  subjectColor: string;
  onChapterSectionClicked: (section: Section) => void;
  chapterSectionColor: string;
};

export const SideMenuChapterSection = ({
  section,
  current,
  subjectColor,
  onChapterSectionClicked,
  chapterSectionColor,
}: SideMenuChapterSectionProps) => (
  <SectionsContainer>
    <ChapterItemContainer onClick={() => onChapterSectionClicked(section)}>
      <ChapterItem
        isItemSelected={section.sectionTitle === current.section.sectionTitle}
        color={chapterSectionColor}
      >
        {section.sectionTitle}
      </ChapterItem>
      <ChapterRating
        isItemSelected={section.sectionTitle === current.section.sectionTitle}
        subjectColor={subjectColor}
      >
        {section.sectionRating}
      </ChapterRating>
    </ChapterItemContainer>
  </SectionsContainer>
);

const ChapterItemContainer = styled.div`
  cursor: pointer;
  gap: 8px;
  word-wrap: break-word;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChapterItem = styled.span<{
  isItemSelected: boolean;
  subjectColor?: string;
}>`
  color: ${(props) => props.color};
  white-space: normal;
  word-break: break-word;
  ${TextStyles.ListItemSubtitle}
`;

const ChapterRating = styled.span<{
  isItemSelected: boolean;
  subjectColor?: string;
}>`
  color: ${(props) =>
    props.isItemSelected ? props.subjectColor : Colors.primaryTextColor};
  white-space: normal;
  word-break: break-word;
  ${TextStyles.ListItemSubtitle};
  text-wrap: nowrap;
`;

const SectionsContainer = styled.div`
  margin: 5px 0px;
`;
