import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import { SummaryIndex } from '../../../components/subject/summary/SummaryIndex';
import { useGetSummarySectionsQuery } from '../../../services/subjectsApi';

export const Summary = () => {
  const { encodedSubjectName } = useParams();

  const { data: summary } = useGetSummarySectionsQuery(
    encodedSubjectName ?? skipToken
  );
  if (!summary) return <></>;

  return <SummaryIndex summary={summary} />;
};
