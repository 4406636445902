import styled from '@emotion/styled';

import { ProgressBar } from '../../components/ProgressBar';
import { BadgeProgress } from '../../models/Badge';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type Props = {
  progress: BadgeProgress;
};

export const BadgeProgressComponent = ({ progress }: Props) => {
  const getPercentage = (progress: BadgeProgress) =>
    (progress.amount / progress.badgeGoal?.targetAmount) * 100;

  return (
    <ProgressContainer>
      <ProgressAmount>
        {progress.amount}/{progress.badgeGoal?.targetAmount}
      </ProgressAmount>
      <ProgressBar
        progress={getPercentage(progress)}
        full={progress.badgeGoal?.targetAmount === progress.amount}
      />
    </ProgressContainer>
  );
};

const ProgressContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  bottom: 0;
`;

const ProgressAmount = styled.p`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ProgressAmountIndicator};
  font-weight: 700;
  text-align: center;
`;
