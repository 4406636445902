import { ApiTagTypes } from '../constants/api';
import { CONVERSION_EXPERIMENT_NAME } from '../constants/experiments';
import {
  Experiment,
  ExperimentSection,
  ExperimentSubjectSections,
} from '../models/Experiment';

import { baseApi } from './baseApi';

export const experimentsApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ExperimentSections],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      addUser: build.mutation<Experiment, void>({
        query: () => ({
          url: `/experiments/${CONVERSION_EXPERIMENT_NAME}/addUser`,
          method: 'POST',
        }),
        invalidatesTags: [ApiTagTypes.ExperimentSections],
      }),
      claimPresent: build.mutation<
        Experiment,
        { claimUrl: string; experimentSection?: ExperimentSection }
      >({
        query: ({ claimUrl, experimentSection }) => ({
          url: `/${claimUrl}`,
          method: 'POST',
          body: experimentSection,
        }),
        invalidatesTags: [ApiTagTypes.ExperimentSections],
      }),
      getExperimentSections: build.query<ExperimentSubjectSections[], void>({
        query: () => ({
          url: `/experiments/${CONVERSION_EXPERIMENT_NAME}/sections`,
        }),
        providesTags: [ApiTagTypes.ExperimentSections],
      }),
    }),
  });

export const {
  useAddUserMutation,
  useClaimPresentMutation,
  useGetExperimentSectionsQuery,
} = experimentsApi;
