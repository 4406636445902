import styled from '@emotion/styled';

import { useSubjectColorContext } from '../../../context/SubjectColorContext';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  title: string;
  text: string;
};

export const ResultSection = ({ title, text }: Props) => {
  const { subjectColor } = useSubjectColorContext();

  return (
    <div>
      <Title>{title}</Title>
      <Result color={subjectColor}> {text}</Result>
    </div>
  );
};

const Title = styled.div`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleSmall}
`;
const Result = styled.p<{ color: string }>`
  margin: 0;
  color: ${({ color }) => color};
  ${TextStyles.TitleSmall}
`;
