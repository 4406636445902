import { IconProps, Icon } from './Icon';

export const ArrowDownIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M9.33594 15.6641C9.70313 16.0312 10.2969 16.0312 10.6602 15.6641L18.1641 8.16406C18.5313 7.79688 18.5313 7.20313 18.1641 6.83984C17.7969 6.47656 17.2031 6.47266 16.8398 6.83984L10.0039 13.6758L3.16406 6.83594C2.79688 6.46875 2.20313 6.46875 1.83985 6.83594C1.47656 7.20312 1.47266 7.79687 1.83985 8.16016L9.33594 15.6641Z"
      fill={color}
    />
  </Icon>
);
