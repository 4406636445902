import { useMediaQuery } from '@uidotdev/usehooks';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { BuyableModalComponent } from '../components/BuyableModalRoute';
import { Backdrop } from '../components/common/Backdrop';
import { ModalRoot } from '../components/common/ModalRoot';
import { MobileNavigationBar } from '../components/navbar/MobileNavigationBar';
import { NavigationHeader } from '../components/navbar/NavigationHeader';
import { EmailVerificationCard } from '../components/onboarding/email-verification/EmailVerificationCard';
import { LinkProductCard } from '../components/onboarding/link-product/LinkProductCard';
import { RelevantSectionCard } from '../components/onboarding/relevant-section/RelevantSectionCard';
import { SelectRoleCard } from '../components/onboarding/role/SelectRoleCard';
import { StudentTypeCard } from '../components/onboarding/StudentTypeCard';
import { SelectExtraSubjects } from '../components/onboarding/subjects/SelectExtraSubjects';
import { SelectSubjects } from '../components/onboarding/subjects/SelectSubjects';
import { Page } from '../components/Page';
import { ChangeCourseLevel } from '../components/profile/change/ChangeCourseLevel';
import { ChangeSubjects } from '../components/profile/change/ChangeSubjects';
import { FAQIndex } from '../components/profile/faq/FAQIndex';
import { ProfileHeader } from '../components/profile/ProfileHeader';
import { SubjectDefinitions } from '../components/subject/definitions/SubjectDefinitions';
import { SubjectExercises } from '../components/subject/exercises/SubjectExercises';
import { SubjectHome } from '../components/subject/home/SubjectHome';
import { MobileSubjectHeader } from '../components/subject/nav/MobileSubjectHeader';
import { OldExamDocumentComponent } from '../components/subject/old-exams/OldExamDocumentComponent';
import { OldExamPeriodComponent } from '../components/subject/old-exams/OldExamPeriodComponent';
import { OldExamsComponent } from '../components/subject/old-exams/OldExamsComponent';
import { SubjectTips } from '../components/subject/tips/SubjectTips';
import { Videos } from '../components/subject/videos/Videos';
import { HookMediaQueries } from '../constants/MediaQueries';
import { AppRoutePathNames, appRoutes } from '../constants/routes';
import { useDeepLinking } from '../hooks/useDeepLinking';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';

import { Badges } from './badges/Badges';
import { Cart } from './cart/Cart';
import { Dashboard } from './dashboard/Dashboard';
import { Devices } from './devices/Devices';
import { Disclaimer } from './disclaimer/Disclaimer';
import { Login } from './login/Login';
import { Unblock } from './login/Unblock';
import { Onboarding } from './onboarding/Onboarding';
import { Payment } from './payment/Payment';
import { Products } from './products/Products';
import { Profile } from './profile/Profile';
import { ProfileOnboarding } from './profile/ProfileOnboarding';
import { Schedule } from './schedule/Schedule';
import { PracticePage } from './subject/practice/Practice';
import { ResultPage } from './subject/result/Result';
import { Subject } from './subject/Subject';
import { Summary } from './subject/summary';
import { Subjects } from './subjects/Subjects';

export const Root = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const isLoggedIn = useIsLoggedIn();
  useDeepLinking();

  return (
    <>
      <Routes>
        <Route
          path="*"
          element={
            <>
              {!isLoggedIn() && <Navigate to={appRoutes.Login} />}
              <NavigationHeader />
              <Page>
                <Routes>
                  <Route
                    path={AppRoutePathNames.Dashboard}
                    element={<Dashboard />}
                  />
                  <Route path={AppRoutePathNames.Cart} element={<Cart />} />
                  <Route
                    path={AppRoutePathNames.PaymentStatus}
                    element={<Payment />}
                  />
                  <Route path={AppRoutePathNames.Subjects} element={<Outlet />}>
                    <Route index element={<Subjects />}></Route>

                    <Route
                      path=":encodedSubjectName"
                      element={
                        <>
                          <MobileSubjectHeader />
                          <Subject />
                        </>
                      }
                    >
                      <Route
                        index
                        element={<Navigate to={AppRoutePathNames.Home} />}
                      />

                      <Route
                        path={AppRoutePathNames.Home}
                        element={<SubjectHome />}
                      />
                      <Route
                        path={AppRoutePathNames.Definitions}
                        element={<SubjectDefinitions />}
                      />
                      <Route
                        path={AppRoutePathNames.Tips}
                        element={<SubjectTips />}
                      />
                      <Route
                        path={AppRoutePathNames.Exercises}
                        element={<SubjectExercises />}
                      />
                      <Route
                        path={AppRoutePathNames.OldExams}
                        element={<OldExamsComponent />}
                      />
                      <Route
                        path={`${AppRoutePathNames.OldExams}/:examYear/:examPeriod`}
                        element={<OldExamPeriodComponent />}
                      />
                      <Route
                        path={`${AppRoutePathNames.OldExams}/:examYear/:examPeriod/:oldExamDocument`}
                        element={<OldExamDocumentComponent />}
                      />
                      <Route
                        path={AppRoutePathNames.Videos}
                        element={<Videos />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path={AppRoutePathNames.Schedule}
                    element={<Schedule />}
                  ></Route>
                  <Route
                    path={AppRoutePathNames.Profile}
                    element={
                      <>
                        <ProfileHeader />
                        <Outlet />
                      </>
                    }
                  >
                    <Route
                      index
                      element={<Navigate to={AppRoutePathNames.Home} />}
                    />
                    <Route
                      path={AppRoutePathNames.Home}
                      element={<Profile />}
                    />
                    <Route
                      path={AppRoutePathNames.Disclaimer}
                      element={<Disclaimer />}
                    />
                    <Route
                      path={AppRoutePathNames.FAQ}
                      element={<FAQIndex />}
                    />
                    <Route
                      path={AppRoutePathNames.Products}
                      element={<Products />}
                    />
                    <Route
                      path={AppRoutePathNames.Devices}
                      element={<Devices />}
                    />
                  </Route>
                  <Route
                    path={`${AppRoutePathNames.Profile}/${AppRoutePathNames.ChangeEducationLevel}`}
                    element={<ChangeCourseLevel />}
                  />
                  <Route
                    path={`${AppRoutePathNames.Profile}/${AppRoutePathNames.ChangeSubjects}`}
                    element={<Outlet />}
                  >
                    <Route index element={<ChangeSubjects />} />
                    <Route path=":category" element={<SelectExtraSubjects />} />
                  </Route>
                  <Route path="/profile/badges" element={<Badges />} />
                  <Route
                    path="/buyable-modal/:type"
                    element={
                      <BuyableModalComponent
                        subject={{ _id: '59df69ed63965a141e2cb6e7' }}
                      />
                    }
                  />
                  <Route path={AppRoutePathNames.Subjects} element={<Outlet />}>
                    <Route index element={<Subjects />}></Route>
                  </Route>
                </Routes>
              </Page>
              {!isDesktop && <MobileNavigationBar />}
            </>
          }
        />
        <Route
          path={AppRoutePathNames.Login}
          element={<Login reset={false} />}
        />
        <Route
          path={AppRoutePathNames.Reset}
          element={<Login reset={true} />}
        />
        <Route
          path={`${AppRoutePathNames.Reset}/:key`}
          element={<Login reset={true} />}
        />
        <Route
          path={`${AppRoutePathNames.Unblock}/:key`}
          element={<Unblock />}
        />
        <Route path={AppRoutePathNames.Onboarding} element={<Onboarding />}>
          <Route index element={<SelectRoleCard />}></Route>
          <Route
            path={AppRoutePathNames.Subjects}
            element={<SelectSubjects />}
          ></Route>
          <Route
            path={`${AppRoutePathNames.Subjects}/:category`}
            element={<SelectExtraSubjects />}
          ></Route>
          <Route
            path={AppRoutePathNames.RelevantSection}
            element={<RelevantSectionCard />}
          ></Route>
          <Route
            path={AppRoutePathNames.StudentType}
            element={<StudentTypeCard />}
          ></Route>
          <Route
            path={AppRoutePathNames.EmailVerification}
            element={<EmailVerificationCard />}
          ></Route>
          <Route
            path={AppRoutePathNames.LinkProduct}
            element={<LinkProductCard />}
          ></Route>
        </Route>
        <Route
          path={`${AppRoutePathNames.Subjects}/:encodedSubjectName/${AppRoutePathNames.Summary}`}
          element={<Summary />}
        />
        <Route
          path={`${AppRoutePathNames.Subjects}/:encodedSubjectName/${AppRoutePathNames.Practice}`}
          element={<PracticePage />}
        />
        <Route
          path={`${AppRoutePathNames.Subjects}/:encodedSubjectName/${AppRoutePathNames.Result}`}
          element={<ResultPage />}
        />
        <Route
          path={`${AppRoutePathNames.Profile}/${AppRoutePathNames.ChangeEducationLevel}`}
          element={<ProfileOnboarding />}
        >
          <Route index element={<ChangeCourseLevel />} />
        </Route>
        <Route
          path={`${AppRoutePathNames.Profile}/${AppRoutePathNames.ChangeSubjects}`}
          element={<ProfileOnboarding />}
        >
          <Route index element={<ChangeSubjects />} />
          <Route path=":category" element={<SelectExtraSubjects />} />
        </Route>
      </Routes>
      <ModalRoot />
      <Backdrop />
    </>
  );
};
