import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ModalTypes, useModal } from './useModal';

export const useError = () => {
  const { showModal } = useModal();

  const showErrorModal = (error: any) => {
    console.warn(error);

    let errorMessage = 'An unexpected error occurred.';

    if (typeof error === 'string') {
      errorMessage = error;
    } else if (error && (error as FetchBaseQueryError).status) {
      const fetchError = error as FetchBaseQueryError;
      if ('data' in fetchError) {
        errorMessage = JSON.stringify(fetchError.data);
      } else {
        errorMessage = `Error: ${fetchError.status}, ${fetchError.error}`;
      }
    } else if (error && (error as SerializedError).message) {
      errorMessage = (error as SerializedError).message || errorMessage;
    }

    showModal({
      type: ModalTypes.ErrorModal,
      data: {
        error: errorMessage,
      },
    });
  };

  return { showErrorModal };
};
