import styled from '@emotion/styled';
import DOMPurify from 'dompurify';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type Props = {
  title: string;
  description: string;
  isLast?: boolean;
};

export const ListRow = ({ title, description, isLast = false }: Props) => {
  const sanitizedDescription = DOMPurify.sanitize(description);
  const sanitizedTitle = DOMPurify.sanitize(title);

  return (
    <Row>
      <RowTextWrapper>
        <Title dangerouslySetInnerHTML={{ __html: sanitizedTitle }} />
        <Description
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
        />
      </RowTextWrapper>
      {!isLast && <Divider></Divider>}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;
const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const Title = styled.h1`
  word-break: break-word;
  flex: 2;
  flex-wrap: wrap;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
  padding-right: 20px;
`;
const Description = styled.p`
  flex: 5;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemBody}
`;
const Divider = styled.div`
  background-color: ${Colors.dividerColor};
  height: 1px;
  width: 100%;
`;
