import { IconProps, Icon } from './Icon';

export const ChevronLeft = ({ color, ...props }: IconProps) => (
  <Icon {...props} viewBox="0 0 24 24" color={color}>
    <path
      d="M15.0235 20.655C15.2387 20.8782 15.5127 21 15.8356 21C16.4814 21 17 20.4724 17 19.8027C17 19.4679 16.863 19.1635 16.638 18.9301L9.78865 11.9899L16.638 5.0699C16.863 4.83653 17 4.52198 17 4.19729C17 3.52762 16.4814 3 15.8356 3C15.5127 3 15.2387 3.12176 15.0235 3.34498L7.41096 11.0564C7.13699 11.3202 7.00978 11.6449 7 12C7 12.3551 7.13699 12.6595 7.41096 12.9335L15.0235 20.655Z"
      fill={color}
    />
  </Icon>
);
