import { youtubePrefix } from '../constants/youtube';

// Youtube via iFrames does not support watch?v= urls, needs embed/ url
export const getYoutubeEmbeddedUrl = (youtubeLink: string): string => {
  let embeddedUrl = '';
  if (youtubeLink.indexOf(youtubePrefix.link) > 0) {
    embeddedUrl = youtubeLink.replace(
      youtubePrefix.link,
      youtubePrefix.embedded
    );
  } else if (youtubeLink.indexOf(youtubePrefix.embedded) > 0) {
    embeddedUrl = youtubeLink;
  }
  return embeddedUrl;
};
