export function setCoverUrlToSummary(summaryCover: string): string {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  if (!apiUrl) {
    throw new Error('API_URL is not defined in environment variables.');
  }

  const productCover = `${apiUrl}${summaryCover}`;
  return productCover;
}
