import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { ZIndex } from '../../constants/Zindex';
import { SubjectCategory, UserSubject } from '../../models/Subject';
import { useGetUserSubjectsQuery } from '../../services/dashboardApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { partition } from '../../utils/arrayUtils';
import { CardBase } from '../common/CardBase';
import { SubjectRow } from '../common/SubjectRow';

export const SubjectsSubmenu = () => {
  const { data } = useGetUserSubjectsQuery();
  const t = useTranslations();

  const [extraSubjects, mainSubjects] = partition<UserSubject>(
    data || [],
    ({ subject }) => subject.category === SubjectCategory.Extra
  );

  return (
    <>
      <SubMenu className="subjectsMenu">
        <SubmenuContainer>
          {mainSubjects?.map((userSubject) => (
            <SubjectRow userSubject={userSubject} key={userSubject._id} />
          ))}
        </SubmenuContainer>
        {!!extraSubjects?.length && (
          <>
            <BreakContainer>
              <TextContainer>{t('extra_subjects')}</TextContainer>
              <HrContainer>
                <StyledHr />
              </HrContainer>
            </BreakContainer>
            <SubmenuContainer>
              {extraSubjects?.map((userSubject) => (
                <SubjectRow userSubject={userSubject} key={userSubject._id} />
              ))}
            </SubmenuContainer>
          </>
        )}
      </SubMenu>
    </>
  );
};

const SubMenu = styled(CardBase)`
  margin: 5px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  z-index: ${ZIndex.z40};
  width: 500px;
  max-height: 500px;
  overflow-y: auto;
`;

const SubmenuContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-sizing: border-box;
  gap: 10px;
  justify-content: space-between;
`;

const BreakContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
`;

const TextContainer = styled.div`
  white-space: nowrap;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;

const HrContainer = styled.div`
  flex: 1;
  margin: 0 5px;
`;

const StyledHr = styled.hr`
  background-color: ${Colors.secondaryTextColor};
  height: 1px;
  border: 0;
  opacity: 0.5;
`;
