import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { ProgressType } from '../constants/progress';
import { TextStyles } from '../styles/textStyles';

type Props = {
  progress: number;
  type: ProgressType;
};

export const CircularProgressCounter = ({ progress, type }: Props) => {
  const t = useTranslations();

  const title =
    type === ProgressType.Percentage
      ? `${progress.toFixed()}%`
      : progress.toFixed(1);

  return (
    <Container>
      <TextWrapper>
        <ProgressTitle>{title}</ProgressTitle>
        {type === ProgressType.Percentage && (
          <ProgressSubTitle>{t('common_progress')}</ProgressSubTitle>
        )}
      </TextWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressTitle = styled.h3`
  ${TextStyles.ProgressTitle};
  margin: 0;
`;

const ProgressSubTitle = styled.h5`
  ${TextStyles.ProgressBody};
  margin: 0;
`;
