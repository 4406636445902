import styled from '@emotion/styled';

import { Category, SubCategory } from '../../../models/Definition';

import { DefinitionList } from './DefinitionList';
import { SubCategoryListHeader } from './SubCategoryListHeader';

type Props = {
  category: Category;
  disableFirstHeader?: boolean;
  refHandler: (el: HTMLDivElement | null, subCategoryId: string) => void;
};

export const SubCategoryList = ({
  category,
  disableFirstHeader = false,
  refHandler,
}: Props) => {
  const isFirstSubCategoryOfCategory = (
    category: Category,
    subCategory: SubCategory
  ) => category.subcategories[0] === subCategory;

  // This header exemption is needed because the first header that is shown, is the DefinitionCardHeader component with the search input. That one also displays a title.
  // So this exemption edge-case is needed to prevent a duplicate
  const showHeader = (subCategory: SubCategory) => {
    if (category.subcategories[0] === subCategory && disableFirstHeader)
      return false;
    return true;
  };

  return (
    <>
      {category.subcategories.map((subCategory: SubCategory) => (
        <Container key={subCategory._id}>
          <SubCategoryHeaderContainer
            id={subCategory._id}
            ref={(el) => refHandler(el, subCategory._id)}
          >
            {showHeader(subCategory) && (
              <SubCategoryListHeader
                subTitle={subCategory.name}
                title={
                  isFirstSubCategoryOfCategory(category, subCategory)
                    ? category.name
                    : undefined
                }
              />
            )}
          </SubCategoryHeaderContainer>

          <DefinitionList definitions={subCategory.definitions} />
        </Container>
      ))}
    </>
  );
};

const SubCategoryHeaderContainer = styled.div``;
const Container = styled.div``;
