export const getScoreMessageKey = (score: number) => {
  if (score >= 5.5 && score < 7) {
    return 'result_okay';
  }
  if (score >= 7 && score < 8) {
    return 'result_good';
  }
  if (score >= 8) {
    return 'result_great';
  }
  return 'result_bad';
};
