import React, { createContext, useContext } from 'react';

import { Subject } from '../models/Subject';
import { Sections } from '../models/Summary/summary';

type SubjectContextType = {
  encodedSubjectName?: string;
  subject?: Subject;
  sections?: Sections;
  subjectColor?: string;
};

const SubjectContext = createContext<SubjectContextType | undefined>(undefined);

export const useSubjectContext = () => {
  const context = useContext(SubjectContext);
  if (!context) {
    throw new Error('useSubjectContext must be used within a SubjectProvider');
  }
  return context;
};

export const SubjectProvider = ({
  encodedSubjectName,
  subject,
  sections,
  children,
}: {
  encodedSubjectName?: string;
  subject?: Subject;
  sections?: Sections;
  children: React.ReactNode;
}) => {
  return (
    <SubjectContext.Provider
      value={{
        encodedSubjectName,
        subject,
        sections,
        subjectColor: subject?.color,
      }}
    >
      {children}
    </SubjectContext.Provider>
  );
};
