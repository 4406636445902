import { ApiTagTypes } from '../constants/api';
import { ProductBundle } from '../models/ProductBundle';

import { baseApi } from './baseApi';

export const productApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ProductBundles],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getBuyAbleProductBundlesForUserBySubjectId: build.query<
        ProductBundle[],
        string
      >({
        query: (subjectId) => ({
          url: `/user/productbundles/buyable/${subjectId}`,
        }),
        providesTags: [ApiTagTypes.ProductBundles],
      }),
    }),
  });

export const { useGetBuyAbleProductBundlesForUserBySubjectIdQuery } =
  productApi;
