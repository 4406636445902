import styled from '@emotion/styled';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  title: string;
  description: string;
};

export const Tip = ({ title, description }: Props) => {
  return (
    <>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Divider />
    </>
  );
};

const Title = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle};
  padding-top: 10px;
`;

const Description = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyText};
`;

const Divider = styled.div`
  background-color: ${Colors.dividerColor};
  width: 100%;
  height: 1px;
  margin: 10px 0;
`;
