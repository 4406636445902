import { ONBOARDING_STEPS } from '../../../constants/onboardingSteps';
import { UserRole } from '../../../models/UserRole';
import { useAppSelector } from '../../../store/hooks';

const TOTAL_PROGRESS = 100;

export const useOnboardingProgress = (currentStep: number) => {
  const { accountType, teacherCourseLevels } = useAppSelector(
    (state) => state.userManagement
  );

  if (accountType === undefined) {
    return { progress: 0, previousProgress: 0 };
  }

  const role: UserRole = accountType;
  let totalSteps = ONBOARDING_STEPS[role] || 0;
  if (role === UserRole.teacher) {
    const amountOfCourseLevels = teacherCourseLevels?.length || 0;
    totalSteps += Math.max(0, amountOfCourseLevels - 1);
  }

  if (currentStep > totalSteps) {
    return { progress: TOTAL_PROGRESS, previousProgress: TOTAL_PROGRESS };
  }

  const previousStep = currentStep - 1;

  const progress = (currentStep / totalSteps) * TOTAL_PROGRESS;
  const previousProgress = (previousStep / totalSteps) * TOTAL_PROGRESS;

  return {
    progress,
    previousProgress,
  };
};
