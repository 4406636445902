import styled from '@emotion/styled';
import { InputHTMLAttributes } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  labelRightValue: string;
  labelLeftValue: string;
};

export const Slider = ({
  labelRightValue,
  labelLeftValue,
  ...props
}: InputFieldProps) => {
  return (
    <InputContainer>
      <Labels>
        <Label>{labelLeftValue}</Label>
        <Label>{labelRightValue}</Label>
      </Labels>
      <InputWrapper>
        <InputField type="range" {...props} />
      </InputWrapper>
    </InputContainer>
  );
};
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: ${Colors.inputHintText};
  ${TextStyles.BodySmall}
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  position: relative;
`;

const InputField = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: var(--exo-primary);
  outline: none;
  transition: opacity 0.2s;
  border: none;
  padding: 0;
  min-width: 50px;

  background: ${Colors.primaryButtonFill};

  ${TextStyles.InputText};

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${Colors.primaryButtonFill};
    cursor: pointer;
  }

  ::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${Colors.primaryButtonFill};
    cursor: pointer;
  }
`;
