import styled from '@emotion/styled';

import { SubjectCategory } from '../../models/Subject';
import { useGetUserSubjectsQuery } from '../../services/dashboardApi';

import { AccountManagementCard } from './AccountManagementCard';
import { ExtraSubjectsCard } from './ExtraSubjectsCard';
import { ProfileBadges } from './ProfileBadges';
import { ProfileEducationLevel } from './ProfileEducationLevel';
import { UserSubjectsCard } from './UserSubjectsCard';

export const ProfileContent = () => {
  const { data: subjects } = useGetUserSubjectsQuery();

  const subjectsWithoutExtra = subjects?.filter(
    (subject) => subject.subject.category !== SubjectCategory.Extra
  );
  const extraSubjects = subjects?.filter(
    (subject) => subject.subject.category === SubjectCategory.Extra
  );
  const allSubjects = subjects?.map((subject) => subject.subject) || [];

  return (
    <Container>
      <ProfileBadges />
      <ProfileEducationLevel />
      {!!subjectsWithoutExtra?.length && (
        <UserSubjectsCard
          subjects={subjectsWithoutExtra}
          allSubjects={allSubjects}
        />
      )}
      {!!extraSubjects?.length && (
        <ExtraSubjectsCard userExtraSubjects={extraSubjects} />
      )}
      <AccountManagementCard />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
`;
