import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Route } from '../../../constants/routes';
import { Colors } from '../../../styles/colors';

import { NavBarItem } from './NavBarItem';

type Props = {
  routes: Route[];
};

export const DesktopNavMenu = ({ routes }: Props) => {
  const t = useTranslations();
  return (
    <Container>
      {routes.map((navItem) => (
        <NavBarItem
          key={navItem.path}
          to={navItem.path}
          title={t(navItem.titleKey)}
        />
      ))}
    </Container>
  );
};
const Container = styled.div`
  border: 1px solid ${Colors.dividerColor};
  border-radius: 8px;
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  flex: 1;
  background-color: ${Colors.modalBackgroundColor};
  box-shadow: 0px 2px 2px 0px ${Colors.shadowColor};
`;
