import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import {
  SUMMARY_HEADER_HEIGHT,
  SUMMARY_SIDE_MENU_WIDTH,
} from '../../../constants/Sizes';
import { ZIndex } from '../../../constants/Zindex';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { useProductAccess } from '../../../hooks/useProductAccess';
import { Chapter, Section } from '../../../models/Summary/summary';
import {
  useGetSummaryByEncodedNameQuery,
  useGetSummarySectionsQuery,
} from '../../../services/subjectsApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SecondaryButton } from '../../common/Button';
import { MultiSwitch } from '../../Switch';

import { useSideMenuSectionAccess } from './hooks/useSideMenuSectionAccess';
import { CurrentSummarySegment } from './hooks/useSummaryData';
import { SideMenuChapterSection } from './SideMenuChapterSection';
import { BuyableType, SummaryMode } from './utils/summaryUtils';

type SideMenuSummaryProps = {
  chapters?: Chapter[];
  subjectColor?: string;
  videosActive: boolean;
  handleOptionChange: (option: SummaryMode) => void;
  onChapterOrSectionPress: (section: Section) => void;
  isNavActive: boolean;
  summaryMode: SummaryMode;
  current: CurrentSummarySegment;
};

export const SideMenuSummary = ({
  chapters,
  subjectColor,
  videosActive,
  handleOptionChange,
  onChapterOrSectionPress,
  isNavActive,
  summaryMode,
  current,
}: SideMenuSummaryProps) => {
  const t = useTranslations();
  const { encodedSubjectName } = useParams() || {};
  const { showModal } = useModal();
  const { hasChapterSectionAccess, hasExplanationVideoAccess } =
    useProductAccess();

  const { data: summarySections } = useGetSummarySectionsQuery(
    encodedSubjectName ?? skipToken
  );
  const { data: summary } = useGetSummaryByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );

  const checkAccess = useSideMenuSectionAccess({
    summarySections,
    summaryMode,
  });

  const onChapterSectionClicked = (chapterSection: Section) => {
    if (!summarySections) return;

    if (!checkAccess(chapterSection)) {
      const buyableType =
        summaryMode === SummaryMode.Video
          ? BuyableType.Videos
          : BuyableType.Summary;

      showModal({
        type: ModalTypes.BuySectionModal,
        data: {
          encodedSubjectName,
          buyableType,
        },
      });
      return;
    }

    onChapterOrSectionPress(chapterSection);
  };

  const openAnswersInNewTab = () => {
    if (summary?.answers) {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}${summary.answers}`,
        '_blank'
      );
    }
  };

  const chapterSectionColor = (chapterSection: Section) => {
    if (!summarySections) return Colors.primaryTextColor;

    if (chapterSection.sectionTitle === current.section.sectionTitle) {
      return subjectColor ?? '';
    }

    const hasAccess = {
      [SummaryMode.Summary]: hasChapterSectionAccess(
        chapterSection,
        summarySections
      ),
      [SummaryMode.Video]: hasExplanationVideoAccess(
        chapterSection,
        summarySections
      ),
      [SummaryMode.Both]:
        hasChapterSectionAccess(chapterSection, summarySections) ||
        hasExplanationVideoAccess(chapterSection, summarySections),
    }[summaryMode];

    return hasAccess ? Colors.primaryTextColor : Colors.disabledContentColor;
  };

  return (
    <MenuContainer isNavActive={isNavActive}>
      <HeaderMocker />
      <ScrollContainer>
        {videosActive && (
          <MultiSwitch
            backgroundColor={subjectColor}
            onSelectedChange={handleOptionChange}
            summaryMode={summaryMode}
          />
        )}

        {chapters?.map((chapter) => (
          <ChaptersContainer key={chapter.encodedChapterName}>
            <ChapterTitle>{chapter.chapterTitle}</ChapterTitle>
            <SectionsContentContainer>
              {chapter.sections.map((chapterSection) => (
                <SideMenuChapterSection
                  key={chapterSection._id}
                  section={chapterSection}
                  current={current}
                  subjectColor={subjectColor ?? ''}
                  onChapterSectionClicked={onChapterSectionClicked}
                  chapterSectionColor={chapterSectionColor(chapterSection)}
                />
              ))}
            </SectionsContentContainer>
          </ChaptersContainer>
        ))}
        {summary?.answers && (
          <AnswersButton buttonSize="md" onClick={openAnswersInNewTab}>
            <ButtonTitle>{t('see_answers')}</ButtonTitle>
          </AnswersButton>
        )}
      </ScrollContainer>
    </MenuContainer>
  );
};

const MenuContainer = styled.div<{ isNavActive: boolean }>`
  background-color: ${Colors.backgroundColor};
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  scroll-behavior: smooth;
  z-index: ${ZIndex.z10};

  transition: all 0.5s ease;
  transform: ${({ isNavActive }) =>
    isNavActive ? 'translateX(0%)' : 'translateX(-100%)'};

  @media ${HookMediaQueries.isDesktop} {
    width: ${SUMMARY_SIDE_MENU_WIDTH};
  }
`;
const ScrollContainer = styled.div`
  padding: 20px;
`;

const HeaderMocker = styled.div`
  height: calc(${SUMMARY_HEADER_HEIGHT} + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  width: 100%;
  position: sticky;
  top: 0;
  background-color: ${Colors.backgroundColor};
  z-index: ${ZIndex.z20};
  border-bottom: 1px solid ${Colors.dividerColor};
  box-sizing: border-box;

  @media ${HookMediaQueries.isDesktop} {
    width: ${SUMMARY_SIDE_MENU_WIDTH};
  }
`;

const ChapterTitle = styled.span`
  overflow: auto;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;

const AnswersButton = styled(SecondaryButton)`
  padding: 4px 12px;
`;

const ButtonTitle = styled.span`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ButtonTextExtraSmallText}
`;

const ChaptersContainer = styled.div`
  margin: 20px 0px;
`;
const SectionsContentContainer = styled.div`
  margin: 10px 0px;
`;
