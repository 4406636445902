import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { MediaQueries } from '../../../constants/MediaQueries';
import { makeSubjectOldExamsRoute } from '../../../constants/routes';
import { Messages } from '../../../global.d';
import { useGetOldExamsQuery } from '../../../services/subjectsApi';
import { TextStyles } from '../../../styles/textStyles';
import { CardBase } from '../../common/CardBase';
import { ChevronLeft } from '../../Icons/ChevronLeft';

export const OldExamPeriodComponent = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { encodedSubjectName, examYear, examPeriod } = useParams();
  const { data: oldExams } = useGetOldExamsQuery(
    encodedSubjectName ?? skipToken
  );

  const selectedOldExam = oldExams?.find(
    (exam) => exam.year === Number(examYear)
  );

  const getSelectedPeriod = () => {
    if (examPeriod && selectedOldExam) {
      if (Number(examPeriod) === 1) {
        return selectedOldExam?.firstPeriod;
      }
      if (Number(examPeriod) === 2) {
        return selectedOldExam.secondPeriod;
      }
    }
  };

  const selectedPeriod = getSelectedPeriod();

  const onDocumentPress = (documentKey: string) => {
    if (encodedSubjectName) {
      navigate(
        makeSubjectOldExamsRoute(
          encodedSubjectName,
          examYear,
          examPeriod,
          documentKey
        )
      );
    }
  };

  const onPeriodPress = () => {
    navigate(-1);
  };

  return (
    <ExamRowContainer>
      <ExamPeriodContainer onClick={onPeriodPress}>
        <CardBody>
          <ChevronLeft />
          <span>
            {examYear}, {t('school_period', { count: examPeriod })}
          </span>
        </CardBody>
      </ExamPeriodContainer>
      {selectedPeriod &&
        Object.keys(selectedPeriod).map((key) => (
          <ExamRowCardBase key={key} onClick={() => onDocumentPress(key)}>
            {t(key as keyof Messages)}
          </ExamRowCardBase>
        ))}
    </ExamRowContainer>
  );
};

const ExamRowContainer = styled.div`
  ${TextStyles.OldExamsBase}
  display: flex;
  margin: 20px 0;
  @media (max-width: ${MediaQueries.desktop}) {
    flex-direction: column;
    width: 100%;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ExamPeriodContainer = styled(CardBase)`
  margin-top: 0;
  margin-right: 10px;
  height: 140px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  cursor: pointer;
  @media (max-width: ${MediaQueries.desktop}) {
    width: unset;
    margin: 16px 20px;
    height: 50px;
    border-radius: 10px;
  }
`;

const ExamRowCardBase = styled(CardBase)`
  margin: 0 10px;
  height: 140px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${MediaQueries.desktop}) {
    width: unset;
    margin: 16px 20px;
    border-radius: 20px;
  }
`;
