import { z, ZodType } from 'zod';

export type FormData = {
  email: string;
  password: string;
};

export enum FormFields {
  email = 'email',
  password = 'password',
}

export const LoginSchema: ZodType<FormData> = z.object({
  email: z.string().email({ message: 'no_valid_email' }),
  password: z.string().min(1, { message: 'no_valid_password' }),
});
