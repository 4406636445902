import { useMediaQuery } from '@uidotdev/usehooks';
import { Outlet } from 'react-router-dom';

import { NavigationHeader } from '../../components/navbar/NavigationHeader';
import { Page } from '../../components/Page';
import { HookMediaQueries } from '../../constants/MediaQueries';

export const ProfileOnboarding = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  if (!isDesktop) {
    return <Outlet />;
  }

  return (
    <>
      <NavigationHeader />
      <Page>
        <Outlet />
      </Page>
    </>
  );
};
