import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Practice } from '../../../components/subject/practice/Practice';
import { useError } from '../../../hooks/useError';
import { Training } from '../../../models/training/training';
import { useLazyGetTrainingProgressQuery } from '../../../services/trainingApi';

export const PracticePage = () => {
  const { encodedSubjectName } = useParams();
  const [training, setTraining] = useState<Training | undefined>();
  const navigate = useNavigate();
  const { showErrorModal } = useError();
  const [getTraining] = useLazyGetTrainingProgressQuery();

  const loadTraining = async () => {
    if (!encodedSubjectName) return;

    try {
      const result = await getTraining(encodedSubjectName);

      if (result.error) {
        throw result.error;
      }

      setTraining(result.data);
    } catch (error) {
      showErrorModal(error);
    }
  };

  useEffect(() => {
    loadTraining();
  }, []);

  if (!encodedSubjectName || !training) {
    return <></>;
  }

  if (training.questionCount === 0) {
    navigate(-1);
  } else {
    return (
      <Practice encodedSubjectName={encodedSubjectName} training={training} />
    );
  }

  return <></>;
};
