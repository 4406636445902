import { ApiTagTypes } from '../constants/api';
import { PaymentResponse, Payment, PaymentOption } from '../models/payments';
import { ProductBundle } from '../models/ProductBundle';

import { baseApi } from './baseApi';

export const cartApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ProductBundles, ApiTagTypes.User],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      addToCart: build.mutation<ProductBundle[], string>({
        query: (subjectId) => ({
          url: `/user/productbundles/buyable/${subjectId}`,
        }),
      }),
      createPayment: build.mutation<PaymentResponse, Payment>({
        query: (deliveryAddress) => ({
          url: `/payments`,
          method: 'POST',
          body: deliveryAddress,
          invalidatesTags: [ApiTagTypes.UserProducts],
        }),
      }),
      getPaymentOptions: build.query<PaymentOption[], void>({
        query: () => ({
          url: `/v2/payment/options`,
        }),
      }),
    }),
  });

export const {
  useAddToCartMutation,
  useCreatePaymentMutation,
  useGetPaymentOptionsQuery,
} = cartApi;
