import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { useLogout } from '../../hooks/useLogout';
import { ModalTypes, useModal } from '../../hooks/useModal';
import { PrimaryHeaderText } from '../../styles/elements/PrimaryHeaderText';
import { PrimaryButton, SecondaryButton } from '../common/Button';
import { CardBase } from '../common/CardBase';

export const AccountManagementCard = () => {
  const t = useTranslations();
  const { logout } = useLogout();
  const { showModal } = useModal();

  const handleLogout = () => {
    logout();
  };

  const handleDeleteAccount = () => {
    showModal({
      type: ModalTypes.DeleteAccountModal,
    });
  };

  return (
    <CardBase
      fullWidth={true}
      header={<PrimaryHeaderText>{t('account_management')}</PrimaryHeaderText>}
    >
      <Container>
        <StyledSecondaryButton buttonSize="md" onClick={handleDeleteAccount}>
          {t('delete_account2')}
        </StyledSecondaryButton>
        <StyledPrimaryButton buttonSize="md" onClick={handleLogout}>
          {t('logout')}
        </StyledPrimaryButton>
      </Container>
    </CardBase>
  );
};

const Container = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  flex: 1;
  margin: 0 8px;
  color: #f42b27;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  flex: 1;
  margin: 0 8px;
`;
