import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import { useGetOldExamsQuery } from '../../../services/subjectsApi';

import { OldExamDetailsComponent } from './OldExamDetailsComponent';

export const OldExamsComponent = () => {
  const { encodedSubjectName } = useParams();
  const { data: oldExams } = useGetOldExamsQuery(
    encodedSubjectName ?? skipToken
  );

  const sortedOldExams = [...(oldExams || [])].sort((a, b) => b.year - a.year);

  return (
    <FlexBox>
      {sortedOldExams?.map((exam) => (
        <OldExamDetailsComponent key={exam._id} oldExam={exam} />
      ))}
    </FlexBox>
  );
};

const FlexBox = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
`;
