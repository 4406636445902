import styled from '@emotion/styled';
import { CalendarEvent } from '@schedule-x/calendar';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { PrimaryButton, SecondaryButton } from '../../components/common/Button';
import { CardBase } from '../../components/common/CardBase';
import { SubjectImage } from '../../components/common/SubjectImage';
import { SCHEDULE_TIME_FORMAT } from '../../constants/date';
import { LOCALE_SCHEDULE } from '../../constants/locale';
import {
  makeSubjectDefinitionsRoute,
  makeSubjectSummaryRoute,
} from '../../constants/routes';
import { Subject, SubjectCategory } from '../../models/Subject';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import {
  HourDifferenceBetweenDates,
  DifferenceBetweenDateMinutes,
} from '../../utils/dateUtils';

export const CalendarEventModal = ({
  calendarEvent,
}: {
  calendarEvent: CalendarEvent & Subject;
}) => {
  const navigate = useNavigate();
  const t = useTranslations();
  const date = new Date(calendarEvent.start).toLocaleDateString(
    LOCALE_SCHEDULE,
    {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }
  );

  const startTime = new Date(calendarEvent.start);
  const endTime = new Date(calendarEvent.end);

  const hoursDuration = HourDifferenceBetweenDates(startTime, endTime);
  const minutesDuration = DifferenceBetweenDateMinutes(startTime, endTime);

  const onSubjectsSummaryPress = () => {
    navigate(makeSubjectSummaryRoute(calendarEvent.encodedName));
  };
  const onSubjectsDefinitionsPress = () => {
    navigate(makeSubjectDefinitionsRoute(calendarEvent.encodedName));
  };

  return (
    <StyledCardBase
      fullWidth={true}
      header={
        <HeaderContainer>
          <StyledSubjectImage src={calendarEvent.subjectImage} />
          <div>
            <CourseNameContainer>{calendarEvent.name}</CourseNameContainer>
            <CourseLevelContainer>
              {calendarEvent.courseLevel}
            </CourseLevelContainer>
          </div>
        </HeaderContainer>
      }
      footerButtons={
        calendarEvent.category !== SubjectCategory.Extra ? (
          <FooterButtonsContainer>
            <StyledSecondaryButton
              buttonSize="md"
              onClick={onSubjectsDefinitionsPress}
            >
              {t('subject_menu_definitions')}
            </StyledSecondaryButton>
            <StyledPrimaryButton
              buttonSize="md"
              onClick={onSubjectsSummaryPress}
            >
              {t('common_summary')}
            </StyledPrimaryButton>
          </FooterButtonsContainer>
        ) : undefined
      }
    >
      <ExamTimeContainer>
        {date}{' '}
        {t('schedule_from_date_to_date', {
          start: format(startTime, SCHEDULE_TIME_FORMAT),
          end: format(endTime, SCHEDULE_TIME_FORMAT),
        })}
      </ExamTimeContainer>
      <ExamDurationContainer>
        {t('schedule_lasts_hours_minutes', {
          hours: hoursDuration,
          minutes: minutesDuration,
        })}
      </ExamDurationContainer>
    </StyledCardBase>
  );
};

const StyledSubjectImage = styled(SubjectImage)`
  margin-right: 10px;
  width: 50px;
  height: 50px;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const FooterButtonsContainer = styled.div`
  display: flex;
  width: 100%;
`;

// library is overriding button styles
const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: ${(props) =>
    `${props.buttonColor ? props.buttonColor : Colors.primaryButtonFill} !important`};
  color: ${Colors.primaryButtonContentColor} !important;
  border: 1px solid ${Colors.primaryButtonStroke} !important;

  &:hover {
    background-color: ${Colors.primaryButtonHover} !important;
  }
  flex: 1;
  margin: 0 8px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  background-color: ${Colors.secondaryButtonFill} !important;
  color: ${Colors.secondaryButtonContentColor} !important;
  border: 1px solid ${Colors.secondaryButtonStroke} !important;
  &:hover {
    background-color: ${Colors.secondaryButtonHover} !important;
  }
  flex: 1;
  margin: 0 8px;
`;

const StyledCardBase = styled(CardBase)`
  margin-top: 0;
`;

const CourseNameContainer = styled.div``;

const ExamTimeContainer = styled.p`
  ${TextStyles.ScheduleExamTime}
  ::first-letter {
    text-transform: capitalize;
  }
  margin-top: 0;
`;

const ExamDurationContainer = styled.div`
  margin-bottom: 0;
`;

const CourseLevelContainer = styled.div`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
