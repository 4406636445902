import styled from '@emotion/styled';

import { UserRole } from '../../../models/UserRole';
import { Colors } from '../../../styles/colors';
import { SelectableItem } from '../SelectableItem';
type RoleSelectableItemProps = {
  role: UserRole;
  Icon: React.ComponentType<{ color: string }>;
  text: string;
  selectedRole: UserRole | null | undefined;
  onSelect: (role: UserRole) => void;
};

export const RoleSelectableItem = ({
  role,
  Icon,
  text,
  selectedRole,
  onSelect,
}: RoleSelectableItemProps) => {
  return (
    <SelectableItem
      icon={
        <StyledIcon
          as={Icon}
          color={
            selectedRole === role
              ? Colors.primaryButtonFill
              : Colors.primaryTextColor
          }
        />
      }
      text={text}
      onClick={() => onSelect(role)}
      active={selectedRole === role}
    />
  );
};

const StyledIcon = styled.svg`
  width: 24px;
  height: 24px;
`;
