import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { useSubjectContext } from '../../../../context/SubjectContext';
import { useProductAccess } from '../../../../hooks/useProductAccess';
import { Section } from '../../../../models/Summary/summary';
import { Colors } from '../../../../styles/colors';
import { SubjectColorButton } from '../../../common/Button';
import { LockIcon } from '../../../Icons/LockIcon';
import { useSectionActions } from '../useSectionActions';

export const SectionActions = ({
  chapterSection,
  encodedChapterName,
  videosActive,
}: {
  chapterSection: Section;
  encodedChapterName: string;
  videosActive: boolean;
}) => {
  const { sections } = useSubjectContext();
  const {
    hasChapterSectionAccess,
    hasExplanationVideoAccess,
    hasExamChallengeAccess,
  } = useProductAccess();

  const t = useTranslations();
  const { subjectColor } = useSubjectContext();

  const { openSummary, openVideo, openTraining } = useSectionActions();

  const chapterSectionHasVideos = () => {
    const topicVideos = chapterSection.topics.filter((topic) => topic.video);
    return videosActive && topicVideos.length > 0;
  };

  if (!sections) return <></>;

  return (
    <Container>
      <SubjectColorButton
        buttonSize="sm"
        textSize="xs"
        buttonColor={subjectColor}
        onClick={() => openSummary(chapterSection)}
        style={{ border: 'none' }}
      >
        <TextContainer>
          {!hasChapterSectionAccess(chapterSection, sections) && (
            <StyledLockIcon color={Colors.primaryButtonContentColor} />
          )}
          {t('common_summary')}
        </TextContainer>
      </SubjectColorButton>
      {chapterSectionHasVideos() && (
        <SubjectColorButton
          buttonSize="sm"
          textSize="xs"
          buttonColor={subjectColor}
          onClick={() => openVideo(chapterSection)}
          style={{ border: 'none' }}
        >
          <TextContainer>
            {!hasExplanationVideoAccess(chapterSection, sections) && (
              <StyledLockIcon color={Colors.primaryButtonContentColor} />
            )}
            {t('common_video')}
          </TextContainer>
        </SubjectColorButton>
      )}
      {chapterSection.hasPracticeModule && (
        <SubjectColorButton
          buttonSize="sm"
          textSize="xs"
          buttonColor={subjectColor}
          onClick={() =>
            openTraining({
              section: chapterSection,
              encodedChapterName,
              startNewTraining: true,
            })
          }
          style={{ border: 'none' }}
        >
          <TextContainer>
            {!hasExamChallengeAccess(chapterSection, sections) && (
              <StyledLockIcon color={Colors.primaryButtonContentColor} />
            )}
            {t('common_challenge')}
          </TextContainer>
        </SubjectColorButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const StyledLockIcon = styled(LockIcon)`
  height: 14px;
  width: 14px;
`;
