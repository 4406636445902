import styled from '@emotion/styled';

import { ModalTypes, useModal } from '../../../hooks/useModal';
import {
  useGetLeaderBoardQuery,
  useGetSupportedFeaturesQuery,
} from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { CardBase } from '../../common/CardBase';
import { ProfileCardHeader } from '../../profile/ProfileCardHeader';

import { LeaderBoardPodiumUserRow } from './LeaderBoardPodiumUserRow';
import { LeaderBoardUserRow } from './LeaderBoardUserRow';

const LEADERBOARD_FEATURE = 'leaderboard';
const USER_LIST_LENGTH = 4;

export const LeaderBoardCard = () => {
  const { data } = useGetLeaderBoardQuery();
  const { data: supportedFeatures } = useGetSupportedFeaturesQuery();

  const { showModal } = useModal();

  const leaderBoardScores = data?.leaderboard?.scores.map((v, i) => ({
    ...v,
    position: i + 1,
  }));

  const podiumPositions = leaderBoardScores?.slice(0, 3) || [];

  const rest = leaderBoardScores?.slice(3) || [];

  const getUserPositionInterval = () => {
    const userPosition = rest.findIndex((v) => v.isMe);
    if (userPosition === undefined) return rest.slice(0, USER_LIST_LENGTH);

    if (userPosition < USER_LIST_LENGTH) {
      return rest.slice(0, USER_LIST_LENGTH);
    }

    if (userPosition > rest.length - USER_LIST_LENGTH) {
      return rest.slice(rest.length - USER_LIST_LENGTH);
    }

    return rest.slice(
      userPosition - USER_LIST_LENGTH / 2,
      userPosition + USER_LIST_LENGTH / 2
    );
  };

  const userPositionInterval = getUserPositionInterval();

  const onShowLeaderBoardPress = () => {
    showModal({
      type: ModalTypes.LeaderBoardModal,
      data: {
        scores: leaderBoardScores,
      },
    });
  };

  if (!supportedFeatures?.includes(LEADERBOARD_FEATURE) || !data?.isAssigned) {
    return <></>;
  }

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <ProfileCardHeader
          titleTranslationKey="leaderboard"
          buttonTranslationKey="leaderboard_view_all"
          onButtonClick={onShowLeaderBoardPress}
        />
      }
    >
      <PodiumScoresContainer>
        {podiumPositions.map((score, i) => (
          <LeaderBoardPodiumUserRow
            key={`${score.nickname}${i}`}
            leaderBoardScore={score}
          />
        ))}
      </PodiumScoresContainer>
      <UserPositionContainer>
        {userPositionInterval.map((score, i) => (
          <LeaderBoardUserRow
            key={`${score.nickname}${i}`}
            leaderBoardScore={score}
          />
        ))}
      </UserPositionContainer>
    </CardBase>
  );
};

const PodiumScoresContainer = styled.div`
  display: flex;
  margin: 0 20px;
  & div:nth-child(1) .rank-no {
    color: ${Colors.leaderBoardFirst};
  }
  & div:nth-child(2) .rank-no {
    color: ${Colors.leaderBoardSecond};
  }
  & div:nth-child(3) .rank-no {
    color: ${Colors.leaderBoardThird};
  }
`;

const UserPositionContainer = styled.div`
  margin: 10px 0;
`;
