import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { baseApi } from '../services/baseApi';
import { dashboardApi } from '../services/dashboardApi';
import { subjectsApi } from '../services/subjectsApi';
import { userApi } from '../services/userApi';

import { Slices } from './Slices';
import cartReducer from './slices/cartSlice';
import modal from './slices/modalSlice';
import training from './slices/trainingSlice';
import userManagement from './slices/userManagementSlice';
import userReducer from './slices/userSlice';

const rootReducer = combineSlices(subjectsApi, userApi, dashboardApi, {
  cart: cartReducer,
  user: userReducer,
  modal,
  training,
  userManagement,
});

const persistConfig = {
  key: 'react_app_persist',
  storage,
  whitelist: [Slices.Cart, Slices.User, Slices.Training, Slices.UserManagement],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([baseApi.middleware]),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
