import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { ProgressType } from '../../../constants/progress';
import { useHistoryContext } from '../../../context/HistoryContext';
import { useSubjectColorContext } from '../../../context/SubjectColorContext';
import { Results } from '../../../models/Results';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { secondsToReadableText } from '../../../utils/dateUtils';
import { getScoreMessageKey } from '../../../utils/scoreMessage';
import { CircularProgress } from '../../CircularProgress';
import { SubjectColorButton } from '../../common/Button';
import { PracticeContainer } from '../practice/PracticeContainer';

import { ResultSection } from './ResultSection';

type Props = {
  result: Results | undefined;
};

export const ResultOverview = ({ result }: Props) => {
  const t = useTranslations();
  const { subjectColor } = useSubjectColorContext();
  const { navigateBackFromExamChallenge } = useHistoryContext();

  const goBack = () => {
    navigateBackFromExamChallenge();
  };

  return (
    <PracticeContainer shouldAlignCenter={false} isLeftContainer={true}>
      <Container>
        {result && (
          <>
            <div>
              <Title>{t('exam_grade')}</Title>
              <GradeContainer>
                <ScoreMessage>
                  {t(getScoreMessageKey(result.averageRating))}
                </ScoreMessage>

                <CircularProgress
                  subjectColor={subjectColor}
                  progress={result.averageRating}
                  type={ProgressType.Grade}
                />
              </GradeContainer>
            </div>

            <ResultSection
              title={t('duration')}
              text={secondsToReadableText(result.totalTime)}
            />

            <ResultSection
              title={t('time_per_question')}
              text={secondsToReadableText(result.averageTimePerQuestion)}
            />

            <ResultSection
              title={t('total_points')}
              text={'' + result.totalPoints}
            />

            <ResultSection
              title={t('scored_points')}
              text={'' + result.scoredPoints}
            />

            <StyledSubjectColorButton
              buttonSize="md"
              buttonColor={subjectColor}
              onClick={goBack}
              fullWidth={false}
            >
              {t('back')}
            </StyledSubjectColorButton>
          </>
        )}
      </Container>
    </PracticeContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const GradeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
const Title = styled.div`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleSmall}
`;
const ScoreMessage = styled.p`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.SummaryReadingText}
`;

const StyledSubjectColorButton = styled(SubjectColorButton)`
  @media ${HookMediaQueries.isDesktop} {
    width: 160px;
  }
`;
