import { createContext, useContext, ReactNode } from 'react';

type SubjectColorContextType = {
  subjectColor: string;
};

const SubjectColorContext = createContext<SubjectColorContextType>({
  subjectColor: '',
});

export const useSubjectColorContext = () => {
  const context = useContext(SubjectColorContext);
  if (!context) {
    throw new Error(
      'useSubjectColorContext must be used within a SubjectColorProvider'
    );
  }
  return context;
};

export const SubjectColorProvider = ({
  subjectColor,
  children,
}: {
  subjectColor: string;
  children: ReactNode;
}) => {
  return (
    <SubjectColorContext.Provider
      value={{
        subjectColor,
      }}
    >
      {children}
    </SubjectColorContext.Provider>
  );
};
