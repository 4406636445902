import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { PrimaryButton } from '../../components/common/Button';
import { STORE_URL } from '../../constants/api';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

export const LoginGreeting = () => {
  const t = useTranslations();
  return (
    <Container>
      <Wrapper>
        <OnboardingTitle>{t('login_welcome_message')}</OnboardingTitle>
        <Line />
        <Title>{t('login_slogan')}</Title>
        <SubTitle>{t('login_no_products')}</SubTitle>
        <PrimaryButton buttonSize="md" onClick={() => window.open(STORE_URL)}>
          {t('login_webshop_button')}
        </PrimaryButton>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url('/assets/login-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
const Wrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
const OnboardingTitle = styled.h1`
  text-align: center;

  color: ${Colors.primaryTextInverted};
  ${TextStyles.OnboardingTitleExtraLarge}
`;
const Line = styled.div`
  height: 1px;
  width: 180px;
  background-color: ${Colors.inputBorderColor};
`;
const Title = styled.h1`
  padding-top: 10px;
  color: ${Colors.primaryTextInverted};
  ${TextStyles.ListItemTitle}
`;
const SubTitle = styled.h1`
  color: ${Colors.primaryTextInverted};
  ${TextStyles.ListItemSubtitle}
`;
