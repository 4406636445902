import { IconProps, Icon } from './Icon';

export const MobileCartIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M6.00027 16C4.90027 16 4.01027 16.9 4.01027 18C4.01027 19.1 4.90027 20 6.00027 20C7.10027 20 8.00027 19.1 8.00027 18C8.00027 16.9 7.10027 16 6.00027 16ZM0.000274658 0V2H2.00027L5.60027 9.59L4.25027 12.04C4.09027 12.32 4.00027 12.65 4.00027 13C4.00027 14.1 4.90027 15 6.00027 15H18.0003V13H6.42027C6.28027 13 6.17027 12.89 6.17027 12.75L6.20027 12.63L7.10028 11H14.5503C15.3003 11 15.9603 10.59 16.3003 9.97L19.8803 3.48C19.9603 3.34 20.0003 3.17 20.0003 3C20.0003 2.45 19.5503 2 19.0003 2H4.21027L3.27027 0H0.000274658ZM16.0003 16C14.9003 16 14.0103 16.9 14.0103 18C14.0103 19.1 14.9003 20 16.0003 20C17.1003 20 18.0003 19.1 18.0003 18C18.0003 16.9 17.1003 16 16.0003 16Z"
      fill="#333333"
    />
  </Icon>
);
