export type FAQQuestions = {
  q: string;
  a: string;
};

export type FAQCategory = {
  category: string;
  questions: FAQQuestions[];
};

export const FAQ_QUESTIONS: FAQCategory[] = [
  {
    category: 'Levering',
    questions: [
      {
        q: 'Wanneer krijg ik mijn bestelling geleverd?',
        a: `<p><u>Hardcopy (papier)</u></p>
        <p>Binnen 2 werkdagen heb je jouw bestelling in huis. Als jij vandaag iets bestelt, sturen we dit de volgende werkdag op. In principe zal het product dan de daaropvolgende dag bij jou thuis aankomen. We zijn afhankelijk van het PostNL bezorgschema. Dat wil zeggen dat post wordt bezorgd van dinsdag tot en met zaterdag. Meer informatie over verzending kun je <a title="Verzending" href="https://www.examenoverzicht.nl/verzenden" target="_self">hier lezen</a>.</p>
        <p><u>Digitaal</u></p>
        <p>Koop je een digitaal product, dan heb je direct online toegang tot het product. Je zult hierover binnen 10 minuten na je bestelling een mailtje ontvangen.</p>`,
      },
      {
        q: 'Mijn bestelling is na twee werkdagen nog niet binnen. Wat nu?',
        a: `<p>Dat is erg vervelend. We doen ons best om je bestelling zo snel mogelijk bij jou te krijgen. Het zou kunnen zijn dat er iets fout is gegaan bij PostNL. Laten we nog één dag afwachten. Hopelijk komt je bestelling dan alsnog aan. Mocht je bestelling die dag niet aankomen, neem dan contact met ons op. Dan gaan wij even op zoek naar wat er fout is gegaan en zoeken we naar een oplossing voor je.</p>`,
      },
      {
        q: 'Ik heb iets anders ontvangen dan ik heb besteld. Wat nu?',
        a: `<p>Dat is absoluut niet de bedoeling! Stuur ons een mail of berichtje via WhatsApp. We sturen dan zo snel mogelijk de juiste producten naar je op.</p>`,
      },
      {
        q: 'Mijn bestelling is beschadigd aangekomen. Wat nu?',
        a: `<p>Dat is vervelend. Het kan zijn dat je bestelling bij het transport en de verzending door PostNL is beschadigd. Denk bijvoorbeeld aan een opengescheurde envelop. Ben je van mening dat jouw product dusdanig beschadigd is dat je deze niet meer goed kunt gebruiken? Maak foto’s van de beschadiging en stuur ons een mail of berichtje via WhatsApp. We sturen dan zo snel mogelijk nieuwe producten naar je op.</p>`,
      },
      {
        q: 'Wat is mijn track & trace code?',
        a: `<p>Wij versturen onze producten zonder track en trace. Op deze manier kunnen wij jou een betaalbare samenvatting aanbieden. Je zult je bestelling binnen 2 werkdagen ontvangen.</p>`,
      },
      {
        q: 'Hoe wordt mijn bestelling geleverd?',
        a: `<p>Wij versturen via PostNL, door middel van normale briefpost. In het geval je 7 of meer producten bestelt, dan past het niet meer in een envelop en zullen we het als pakketje versturen.</p>`,
      },
      {
        q: 'Hoe zit het met verzendkosten?',
        a: `<p>Verzending is gratis voor bestellingen boven de 30 euro. Onder de 30 euro, betaal je 3 euro verzendkosten.</p>`,
      },
      {
        q: 'Ik heb een fout gemaakt in het adres bij mijn bestelling. Wat nu?',
        a: `<p>Stuur ons een mail of berichtje via WhatsApp met het goede adres. Dan passen we dit aan, en zorgen we ervoor dat je bestelling naar het juiste adres wordt verstuurd.</p>`,
      },
      {
        q: 'Er zat geen victory pen bij mijn bestelling. Wat nu?',
        a: `<p>Dat is niet zo best! Wij geloven sterk in de kracht van de victory pen. Dat is tenslotte de pen waarmee jij het examen gaat maken. Heb jij een papieren samenvatting besteld en zat er geen victory pen bij? Stuur ons een mail of berichtje via WhatsApp. Dan zullen we jou de victory pen nasturen.</p>`,
      },
    ],
  },
  {
    category: 'Digitale samenvattingen en mijn account',
    questions: [
      {
        q: 'Ik heb een digitale samenvatting besteld. Hoe link ik deze aan mijn account?',
        a: `<p>Wanneer je een digitale samenvatting bestelt, krijg je een bestelnummer en een code. Deze informatie kun je gebruiken in onze <a title="Mijn Account" href="https://www.examenoverzicht.nl/mijn-account/" target="_self">mijn account omgeving</a>. Zodra je een account hebt aangemaakt, kun je in de mijn account omgeving deze informatie invoeren om jouw product te activeren.</p>`,
      },
      {
        q: 'Kan ik digitale samenvattingen maar op één apparaat lezen?',
        a: `<p>Nee, je kunt digitale samenvattingen lezen op meerdere apparaten. We hebben wel een maximum in het aantal apparaten en IP adressen wat je kunt gebruiken om jouw digitale samenvattingen te lezen. Wanneer je de samenvattingen wilt lezen op één van jouw apparaten, en hij geeft de melding dat het apparaat niet is geautoriseerd, lees dan hieronder het volgende punt.</p>`,
      },
      {
        q: 'Er staat dat mijn apparaat niet is geautoriseerd. Wat nu?',
        a: `<p>Dat is vervelend. Je krijgt deze melding omdat we restricties hebben op het aantal IP adressen en het aantal apparaten wat je kunt gebruiken om de samenvatting te bekijken. Dit doen we om te voorkomen dat heel Nederland de samenvatting via jouw account kan lezen. Natuurlijk kan het zijn dat jij meerdere IP adressen of apparaten gebruikt. Als jij de melding ‘apparaat niet geautoriseerd’ krijgt, dan kun je dit gelukkig zelf oplossen. Ga in mijn account rechtsboven naar jouw profiel en dan naar 'Gekoppelde apparaten'. Hier kun je apparaten die geen toegang meer nodig hebben verwijderen. Zo kun je jezelf weer autoriseren. Mocht je er niet uitkomen, stuur ons een mail of een berichtje via WhatsApp en we lossen het direct voor je op.</p>`,
      },
      {
        q: 'Ik heb een digitale samenvatting besteld, maar heb geen koppelingscode ontvangen.',
        a: `<p>Dat is vervelend. Normaal wordt de koppelingscode automatisch gestuurd binnen 10 minuten na je bestelling. Mocht je deze toch niet hebben ontvangen, stuur ons dan een mail of een berichtje via WhatsApp. Vermeld hierbij je ordernummer of de naam waarop je hebt besteld. We sturen de koppelingscode dan zo snel mogelijk naar je op.</p>`,
      },
      {
        q: 'Ik heb een papieren samenvatting besteld. Hoe link ik deze aan mijn account?',
        a: `<p>Je kunt papieren bestellingen niet koppelen aan mijn account. Dit kan enkel met digitale bestellingen.</p>`,
      },
      {
        q: 'Hoe kan ik mijn digitale samenvatting lezen?',
        a: `<p>Nadat je jouw digitale bestelling hebt gekoppeld aan je account, kun je deze in jouw account lezen bij ‘Mijn samenvattingen’. Digitale samenvattingen zijn in je browser te bekijken via je smartphone, tablet of laptop.</p>`,
      },
      {
        q: 'Kan ik een digitale samenvatting printen of downloaden?',
        a: `<p>Het is helaas niet mogelijk om digitale samenvattingen te printen of te downloaden. Ook leveren we geen PDF's aan. Dit staat van tevoren aangegeven op de productpagina. Mocht je de bestelling toch op papier willen, dan kun je het verschil met papier bijbetalen en dan sturen we de papieren samenvattingen naar je op. Stuur ons in dat geval een mail of een berichtje via WhatsApp.</p>`,
      },
      {
        q: 'Ik kan geen plaatjes of examenvragen zien in de samenvatting. Wat nu?',
        a: `<p>Onze digitale samenvattingen zijn het beste te lezen in de browsers Google Chrome, Firefox of Safari. Bij Microsoft Edge worden plaatjes en examenvragen niet (goed) getoond. We raden je aan om de digitale samenvatting te lezen in een andere browser. Mocht je daarna toch nog problemen ondervinden, neem dan contact met ons op. Dan gaan we samen op zoek naar een oplossing.</p>`,
      },
      {
        q: 'Ik ben mijn wachtwoord vergeten.',
        a: `<p>Dat kan gebeuren. Stuur ons een mail of een berichtje via WhatsApp, waarin je aangeeft op welke naam je hebt besteld en uit welke samenvattingen je bestelling bestond. Dan lossen we het direct voor je op.</p>`,
      },
      {
        q: 'Hoe lang kan ik mijn digitale producten bekijken?',
        a: `<p>De digitale samenvattingen zijn één schooljaar geldig. Het schooljaar start jaarlijks op 1 september en eindigt op 31 augustus. Digitale samenvattingen die na de eindexamens van tijdvak 1 worden gekocht tussen 1 juni en 31 augustus, blijven ook het schooljaar daarna geldig.</p>`,
      },
      {
        q: 'Hoe kan ik mijn persoonsgegevens bij ExamenOverzicht verwijderen?',
        a: `<p>Je kunt je gegevens op verschillende manieren verwijderen. Als je niet wilt dat je e-mailadres wordt gebruikt in mailings, kun je je onderaan de mailings afmelden. Je persoonsgegevens in de ‘Mijn Account’ omgeving en in de app voor mobiel/tablet kun je zelf aanpassen. Daarnaast kun je daar ook je gehele account verwijderen als je dat wilt. In dat geval heeft ExamenOverzicht geen toegang meer tot de gegevens uit jouw account. Verder is het goed om te weten dat persoonsgegevens die je hebt ingevuld om een bestelling te plaatsen na twee schooljaren worden verwijderd. Wil je dit eerder verwijderen, neem dan contact met ons op.</p>`,
      },
      {
        q: 'Waarom moeten mijn ouders toestemming geven om de ‘Mijn Account-omgeving’ te gebruiken?',
        a: `<p>Als je de ‘Mijn Account-omgeving’ of app voor mobiel/tablet gaat gebruiken, kun je persoonsgegevens invullen om je account te personaliseren. Denk aan het invullen van je vakkenpakket of cijferlijst. Wanneer je ouder bent dan 16 mag je volgens de wet zelf bepalen of je dit doet. Maar ben je jonger dan 16? Dan moeten je ouders akkoord geven. Aangezien bijna 25% van de examenkandidaten nog geen 16 is, vonden we het verstandig op je hier even op te wijzen. Ben je jonger dan 16, overleg even met je ouders. Bij vragen, neem gerust contact met ons op.</p>`,
      },
    ],
  },
  {
    category: 'Producten',
    questions: [
      {
        q: 'Wat is er zo speciaal aan jullie samenvattingen?',
        a: `<p>Onze samenvattingen hebben de examenstof kort en bondig samengevat. Gemiddeld bestaan onze samenvattingen uit ongeveer 50 pagina’s. De samenvattingen zijn geschreven door leerlingen en gecheckt door leraren. Zo weet je dat de samenvatting makkelijk te lezen is, en tegelijkertijd juist en volledig is. Met onze samenvattingen weet je dus zeker dat je alles leert wat belangrijk is voor het examen. In onze samenvattingen zijn daarnaast oefenvragen uit oude examens en QR-codes te vinden. Met de oefenvragen kun je gelijk zien hoe de examenstof terugkomt op het examen. De QR-codes linken naar video’s met extra uitleg op YouTube, zodat je de tekst in onze samenvatting kunt combineren met het kijken naar video’s. Kortom, onze samenvattingen bereiden jou helemaal voor op het komende eindexamen!</p>`,
      },
      {
        q: 'Wat is het verschil met Samengevat?',
        a: `<p>Er zijn meerdere verschillen. Een Samengevat boekje bestaat soms uit wel 250 pagina’s. Dat vinden wij geen samenvatting meer. Je hebt tenslotte al genoeg te leren in de examenperiode. Daarnaast richt Samengevat zich vooral op de uitleg van begrippen, en bevat weinig verhalende uitleg. Ook zitten er geen oefenvragen of QR-codes met uitlegvideo’s in de Samengevat boekjes. De samenvattingen van ExamenOverzicht hebben dat wel. We hebben een <a title="Wat is het verschil tussen ExamenOverzicht en Samengevat?" href="https://www.examenoverzicht.nl/blog/verschil-tussen-samengevat-en-examenoverzicht" target="_self">uitgebreid blogartikel</a> geschreven waarin we alle verschillen tussen Samengevat en ExamenOverzicht voor je op een rijtje hebben gezet.</p>`,
      },
      {
        q: 'Waar kan ik de antwoorden vinden op de oefenvragen in de samenvattingen?',
        a: `<p>Deze zijn hier te vinden: <a title="Antwoorden op de oefenvragen" href="http://bit.ly/AntwoordenOefenvragen" target="_blank">bit.ly/AntwoordenOefenvragen</a>.</p>`,
      },
      {
        q: 'Hoe kan ik een QR-code in jullie samenvatting scannen?',
        a: `<p>Dat gaat door middel van een app. Er zijn vele gratis apps beschikbaar die dat kunnen doen. Zoek in de App Store of Play Store op ‘QR’ of ‘QR scanner’. Dan krijg je tientallen opties, die allemaal hetzelfde doen. Het maakt niet uit welke app je gebruikt.</p>`,
      },
      {
        q: 'Hoe weet ik zeker dat een samenvatting alle examenstof bevat?',
        a: `<p>Elke samenvatting is geschreven volgens de richtlijnen van de syllabus van dat vak. Deze syllabus wordt door het CvTE gepubliceerd op <a title="Examenblad" href="https://www.examenblad.nl/" target="_blank">Examenblad.nl</a>. Zo weten we zeker dat onze samenvattingen de volledige examenstof bevatten.</p>`,
      },
      {
        q: 'Mag jullie woordenboek voor het eindexamen gebruikt worden op het examen?',
        a: `<p>Nee, helaas heeft het CvTE ons woordenboek verboden. Toen we het <a title="Woordenboeken voor het Eindexamen" href="https://www.examenoverzicht.nl/blog/woordenboek" target="_self">woordenboek uitbrachten</a>, voldeden we aan de regels die waren opgesteld door het CvTE. Het CvTE vond dit niet leuk, en heeft direct de regels gewijzigd. Ze hebben op <a title="Examenblad" href="https://www.examenblad.nl/nieuws/20170501/ruis-over-woordenboek-nederlands/2017?regime=hflinks&amp;horizon=" target="_blank">Examenblad</a> kenbaar gemaakt dat het woordenboek toch niet gebruikt mag worden op het eindexamen. Dat vinden wij natuurlijk erg jammer. Het woordenboek kan alsnog worden gebruikt om begrippen te leren of op te zoeken, maar mag helaas niet mee de examenzaal in.</p>`,
      },
      {
        q: 'Van welke vakken hebben jullie nog geen samenvattingen?',
        a: `<p>We bieden samenvattingen aan voor de meest gangbare vakken. Dit doen we voor VMBO, HAVO en VWO. Helaas hebben we nog geen samenvattingen voor alle vakken. We missen nog samenvattingen voor onder andere de vakken filosofie, kunst, tekenen, Wiskunde C, Latijn, Grieks en maatschappijkunde. Het kan zijn dat deze samenvattingen in de toekomst wel zullen verschijnen. Houd onze site daarvoor in de gaten of stuur ons een mail of een berichtje via WhatsApp met welke samenvatting jij graag zou willen zien.</p>`,
      },
      {
        q: 'Ik heb een suggestie om jullie producten te verbeteren.',
        a: `<p>Daar worden wij erg blij van! We zijn constant op zoek naar tips en verbeteringen om onze producten nog beter te maken. Stuur ons een mail of een berichtje via WhatsApp met je suggestie. Goede toevoegingen belonen we graag met een gratis samenvatting!</p>`,
      },
      {
        q: 'Kan ik mijn samenvattingen ook nog volgend schooljaar gebruiken?',
        a: `<p>Ja en nee. Elk jaar updaten wij de samenvattingen op basis van de examenstof van het aankomende examen. Als de examenstof van een vak wijzigt, dan zullen wij ook de samenvatting van dat vak wijzigen. Daarnaast worden elk jaar de nieuwste examenvragen toegevoegd aan de samenvattingen. Ook kan het zijn dat we sommige delen van de samenvattingen hebben gewijzigd, naar aanleiding van feedback van examenkandidaten en leraren. We zijn namelijk continu bezig om onze producten beter te maken, zodat we bepaalde zaken steeds makkelijker uitleggen. Zo weten we zeker dat de meest recente samenvatting het beste aansluit met het examen. Je kunt een oude samenvatting dus in sommige gevallen prima gebruiken, maar wees wel extra kritisch of alle stof nog relevant is voor het nieuwe examenjaar.</p>`,
      },
      {
        q: 'Is de ExamenOverzicht methode geschikt voor alle lesmethoden en boeken?',
        a: `<p>ExamenOverzicht is geschikt om te gebruiken in combinatie met alle lesmethoden en boeken. We gebruiken de richtlijnen die het College voor Toetsen en Examens (CvTE) in de syllabus van elk vak heeft opgesteld. Het CvTE is verantwoordelijk voor de examens en bepaalt wat wel en niet geleerd moet worden voor het examen. ExamenOverzicht volgt de syllabus en zorgt dat alle onderdelen in de samenvatting zitten.</p>`,
      },
      {
        q: 'Zijn de samenvattingen ook geschikt voor VMBO 3, HAVO 4 en VWO 4/5?',
        a: `<p>Ja en nee. De samenvattingen zijn puur gericht op het eindexamen. De examenstof bevat onderwerpen die je in de hele bovenbouw leert. Een deel van de examenstof wordt behandeld in VMBO 3, HAVO 4 en VWO 4/5. In de samenvattingen vind je ook onderwerpen die je pas in het daaropvolgende jaar krijgt. Daarnaast zul je een deel van de onderwerpen die je in deze jaren leert missen, want niet alles wat je in VMBO 3, HAVO 4 en VWO 4/5 leert is examenstof. Je kunt de samenvattingen dus alleen gebruiken voor de onderwerpen van de examenstof die je nu al leert. Kijk in de inhoudsopgave van de samenvatting om te zien welke onderdelen voor jou nu relevant zijn.</p>`,
      },
    ],
  },
  {
    category: 'Service',
    questions: [
      {
        q: 'Hoe kan ik jullie bereiken?',
        a: `<p>Als je ons wilt bereiken, dan kan dat op de volgende manieren:</p>
        <ul>
          <li><strong>Bellen/WhatsApp</strong>: 085-1300865;</li>
          <li><strong>Mailen</strong>: info@examenoverzicht.nl;</li>
          <li><strong>Schrijven</strong>: Van Deventerlaan 31, 3528AG Utrecht;</li>
        </ul>
        <p>Je kunt ons daarnaast via social media volgen:</p>
        <ul>
          <li>Facebook.com/ExamenOverzicht</li>
          <li>Instagram.com/ExamenOverzicht</li>
          <li>Snapchat: ExamenOverzicht</li>
        </ul>`,
      },
      {
        q: 'Hoe kan ik bij jullie betalen?',
        a: `<p>Je kunt bij ons betalen via iDEAL, PayPal en met Bitcoins. Zie voor meer informatie de pagina <a title="Betaalmogelijkheden" href="https://www.examenoverzicht.nl/betaalmogelijkheden" target="_self">betaalmogelijkheden</a>. Als je liever betaalt via bankoverschrijving, dan kan dat ook. Stuur ons in dat geval een mail of een berichtje via WhatsApp.</p>`,
      },
      {
        q: 'Kan ik mijn geld terug krijgen?',
        a: `<p>Ja, dat is geen probleem! Je kunt je geld terugkrijgen binnen 14 dagen na levering van je bestelling. Je hoeft niet aan te geven waarom je je geld terug wilt, alhoewel we graag leren van jouw feedback om onze producten te verbeteren. De enige uitzondering hierop is als je een product bestelt tijdens de examenperiode en het terug wilt sturen na het examen (zie artikel 10 van onze <a title="Algemene voorwaarden" href="https://www.examenoverzicht.nl/algemene-voorwaarden" target="_self">algemene voorwaarden</a>). Als je je geld terug wilt, stuur ons een mail of een berichtje via WhatsApp met je rekeningnummer en de naam waarop je hebt besteld. Dan storten we het geld direct terug!</p>`,
      },
    ],
  },
];
