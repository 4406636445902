import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';

import { BuyableType } from '../models/training/buyable';
import { useGetBuyAbleProductBundlesForUserBySubjectIdQuery } from '../services/productService';

type BuyableModalProps = {
  subject: {
    _id: string;
  };
  buyableTypes?: BuyableType[];
  onClose?: () => void;
};
export const BuyableModalComponent = ({ subject }: BuyableModalProps) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { data: buyables } = useGetBuyAbleProductBundlesForUserBySubjectIdQuery(
    subject._id ?? skipToken
  );

  return (
    <ModalContainer>
      <h1>Summary</h1>
      <p>Section: {'section'}</p>
      {buyables?.map((product) => (
        <ProductItem key={product?._id}>
          <ProductImage
            src={`${apiUrl}${product?.cover}`}
            alt={product?.name}
          />
          <ProductInfo>
            <ProductName>{product?.name}</ProductName>
            <ProductDescription>{product?.description}</ProductDescription>
          </ProductInfo>
          <ActionButton onClick={() => undefined}>
            {product?.price}
          </ActionButton>
        </ProductItem>
      ))}
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  max-width: 560px;
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  padding: 48px 48px;
  position: relative;
  margin: 16px;

  @media (max-width: 768px) {
    padding: 48px 32px;
  }
`;

const ProductItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 0 16px;
  text-align: center;

  &:last-of-type:not(:first-of-type) {
    border-left: solid 1px var(--card-line-color);

    @media (max-width: 768px) {
      border: none;
      border-top: solid 1px var(--card-line-color);
      padding-top: 40px;
      margin-top: 40px;
    }
  }
`;

const ProductImage = styled.img`
  height: 100px;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
  align-items: center;
  justify-content: center;
`;

const ProductName = styled.span`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--textNormal);
`;

const ProductDescription = styled.span`
  padding-top: 4px;
  font-family: Open Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--textNormal60);
`;

const ActionButton = styled.button`
  &.btn-regular {
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;

    &.dynamic-bgcolorf {
      background-color: var(--dynamic-bgcolorf);
    }
  }
`;
