import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams, useLocation } from 'react-router-dom';

import { SubjectProvider } from '../../../context/SubjectContext';
import {
  useGetSubjectByEncodedNameQuery,
  useGetSummarySectionsQuery,
} from '../../../services/subjectsApi';

import { StyledScroller } from './AnimatedDiv';
import { LearnNextCard } from './components/LearnNextCard';
import { ProgressCard } from './components/ProgressCard';
import { SubjectChapter } from './components/SubjectChapter';
import { UpsellCard } from './components/UpsellCard';
import { useSubjectPercentage } from './hooks/useSubjectPercentage';

export const SubjectHome = () => {
  const { encodedSubjectName } = useParams() || {};

  const { data: subject } = useGetSubjectByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );
  const { data: sections } = useGetSummarySectionsQuery(
    encodedSubjectName ?? skipToken
  );
  const location = useLocation();

  const isVisible = () =>
    !!encodedSubjectName && location.pathname.includes(encodedSubjectName);

  const { progressPercentageTraining } = useSubjectPercentage(sections);

  const progressPercentage = sections?.progressPercentage || 0;

  return (
    <SubjectProvider
      encodedSubjectName={encodedSubjectName}
      subject={subject}
      sections={sections}
    >
      <StyledScroller isVisible={isVisible()}>
        <Container>
          <StyledColumn flex={3}>
            {encodedSubjectName && (
              <LearnNextCard
                subjectColor={subject?.color}
                sections={sections}
                encodedSubjectName={encodedSubjectName}
                progressPercentageTraining={progressPercentageTraining}
              />
            )}

            {!!sections?.chapters.length && <SubjectChapter />}
          </StyledColumn>
          <StyledColumn flex={2}>
            <ProgressCard
              subjectColor={subject?.color}
              sections={sections}
              subjectId={subject?._id}
              progressPercentage={progressPercentage}
            />
            <UpsellCard subjectId={subject?._id} />
          </StyledColumn>
        </Container>
      </StyledScroller>
    </SubjectProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media only screen and (min-width: 1120px) {
    flex-direction: row;
  }
`;

const StyledColumn = styled.div<{
  flex?: number;
  margin?: string;
}>`
  flex: ${(props) => props.flex || 1};
  margin: ${(props) => props.margin};
`;
