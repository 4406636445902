import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { ActionSubjectCard } from '../../../../components/ActionSubjectCard';
import { VideoActionSubjectCard } from '../../../../components/VideoActionSectionCard';
import { HookMediaQueries } from '../../../../constants/MediaQueries';
import { ModalTypes, useModal } from '../../../../hooks/useModal';
import { useProductAccess } from '../../../../hooks/useProductAccess';
import { Section, Sections } from '../../../../models/Summary/summary';
import { BuyableType } from '../../../../models/training/buyable';
import { useGetNextUnreadSectionsQuery } from '../../../../services/subjectsApi';
import { CrownIcon } from '../../../Icons/Crown';
import { useSectionActions } from '../useSectionActions';
import { useSubjectHome } from '../useSubjectHome';

type Props = {
  subjectColor?: string;
  sections?: Sections;
  encodedSubjectName: string;
  progressPercentageTraining: number;
};

export const LearnNextCard = ({
  subjectColor,
  sections,
  encodedSubjectName,
  progressPercentageTraining,
}: Props) => {
  const t = useTranslations();
  const { openSummary, openVideo, openTraining } = useSectionActions();
  const {
    firstPracticeSectionAvailable,
    firstUntrainedPassedSection,
    nextVideo,
    firstSection,
    getSectionByEncodedSectionName,
  } = useSubjectHome({
    encodedSubjectName,
  });
  const { hasBoughtExplanationVideo } = useProductAccess();
  const { data: nextUnreadSections } = useGetNextUnreadSectionsQuery();
  const nextUnreadSection = nextUnreadSections?.find(
    (section) => section.encodedSubjectName === encodedSubjectName
  );

  const { showModal } = useModal();

  const learningText = `${nextUnreadSection ? t('continue') : t('start')} ${t('learning_text')}`;

  const trainingText = `${progressPercentageTraining > 0 ? t('continue') : t('start')} ${t('training_text')}`;
  const repeatTrainingText = t('again_training');
  const trainingTitleText =
    progressPercentageTraining !== 1 ? trainingText : repeatTrainingText;

  const getEncodedChapterNameFromSection = (section: Section) => {
    if (!sections) return '';
    const chapter = sections.chapters.find((chapter) =>
      chapter.sections.some(
        (chapterSection) => section._id === chapterSection._id
      )
    );
    return chapter?.encodedChapterName || '';
  };

  const onTrainingClick = () => {
    const section =
      firstUntrainedPassedSection || firstPracticeSectionAvailable();
    if (!section) return;
    openTraining({
      section,
      encodedChapterName: getEncodedChapterNameFromSection(section),
    });
  };

  const onVideoActionSubjectCardPress = () => {
    if (!sections || !firstSection) return;

    if (hasBoughtExplanationVideo(sections)) {
      openVideo(firstSection);
      return;
    }

    showBuyableModal(BuyableType.videos);
  };

  const showBuyableModal = (buyableType: BuyableType) => {
    showModal({
      type: ModalTypes.BuySectionModal,
      data: {
        encodedSubjectName,
        buyableType,
      },
    });
  };

  const openSummarySection = () => {
    const section = nextUnreadSection
      ? getSectionByEncodedSectionName(nextUnreadSection.encodedSectionName)
      : firstSection;

    if (!section) return;

    openSummary(section);
  };

  if (!sections) return <></>;

  return (
    <Container>
      <StyledColumn>
        <ActionSubjectCard
          subjectColor={subjectColor}
          onPress={openSummarySection}
          title={learningText}
          subTitle={
            nextUnreadSection
              ? nextUnreadSection?.sectionTitle
              : firstSection?.sectionTitle
          }
        />

        {nextVideo && (
          <StyledVideoActionSubjectCard
            onPress={onVideoActionSubjectCardPress}
            subjectColor={subjectColor}
            tagTitle={t('common_explanation')}
            backgroundImage={nextVideo?.thumbnailUrl}
            iconVariation={<CrownIcon />}
            highlightedText={t('start')}
            regularText={t('subject_card_video')}
          />
        )}

        {sections?.practiceModuleActive && firstPracticeSectionAvailable() && (
          <ActionSubjectCard
            subjectColor={subjectColor}
            onPress={onTrainingClick}
            title={trainingTitleText}
            subTitle={firstUntrainedPassedSection?.sectionTitle}
          />
        )}
      </StyledColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 20px 20px 0px 20px;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
    padding: 20px 0px 0px 0px;
    width: 100%;
  }
`;
const StyledColumn = styled.div<{
  flex?: number;
}>`
  flex: ${(props) => props.flex || 1};
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media ${HookMediaQueries.isDesktop} {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledVideoActionSubjectCard = styled(VideoActionSubjectCard)`
  grid-row: span 2;
  order: 3;

  @media ${HookMediaQueries.isDesktop} {
    order: 0;
  }
`;
