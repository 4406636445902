import { Video } from '../../../../models/Summary/summary';

import { VideoCard } from './VideoCard';

type Props = {
  videoList: Video[];
  activeVideo: Video | undefined;
  subjectColor: string;
  direction: 'row' | 'column';
  onClickVideoCard: (video: Video) => void;
};

export const VideoList = ({
  videoList,
  activeVideo,
  subjectColor,
  direction,
  onClickVideoCard,
}: Props) => {
  return (
    <>
      {videoList?.map((video, i) => (
        <VideoCard
          direction={direction}
          key={i}
          imgUrl={video.thumbnailUrl}
          title={video.title}
          time={video.durationSeconds}
          subjectColor={subjectColor}
          active={video === activeVideo}
          onClick={() => onClickVideoCard(video)}
        />
      ))}
    </>
  );
};
