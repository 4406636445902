import { Outlet } from 'react-router-dom';

import { SubjectHeader } from '../../components/subject/SubjectHeader';

export const Subject = () => {
  return (
    <>
      <SubjectHeader />
      <Outlet />
    </>
  );
};
