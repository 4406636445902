import styled from '@emotion/styled';

import { Class } from '../../../models/User';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SubjectImage } from '../../common/SubjectImage';

type Props = {
  classItem: Class;
};

export const ClassRow = ({ classItem }: Props) => {
  return (
    <>
      <Row>
        <StyledSubjectImage src={classItem.image} />

        <RowTextWrapper>
          <ClassName>{classItem.name}</ClassName>
          <SubjectTitle>{classItem.subjectName}</SubjectTitle>
        </RowTextWrapper>
      </Row>
      <Divider />
    </>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
`;
const StyledSubjectImage = styled(SubjectImage)`
  width: 48px;
  height: 48px;
`;
const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ClassName = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
const SubjectTitle = styled.h1`
  margin: 0;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
const Divider = styled.div`
  background-color: ${Colors.dividerColor};
  height: 1px;
  width: 100%;
`;
