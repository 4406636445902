import { IconProps, Icon } from './Icon';

export const CartAdd = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M0.9 2.40002C0.40125 2.40002 0 2.80127 0 3.30002C0 3.79877 0.40125 4.20002 0.9 4.20002H2.60625C2.74875 4.20002 2.8725 4.30127 2.9025 4.44377L4.8375 14.6063C5.08125 15.8813 6.195 16.8 7.48875 16.8H12.0263C12.0075 16.6013 12 16.4025 12 16.2C12 15.7913 12.0375 15.39 12.1088 15H7.48875C7.0575 15 6.68625 14.6925 6.60375 14.2688L6.40125 13.2H12.72C13.065 12.525 13.5225 11.9175 14.07 11.4H6.06L4.91625 5.40002H19.5263L18.3938 9.60378C18.4613 9.60002 18.5325 9.60002 18.6 9.60002C19.155 9.60002 19.6912 9.66752 20.205 9.79502L21.3638 5.49002C21.6225 4.53752 20.9025 3.60002 19.9163 3.60002H4.50375C4.1625 2.88002 3.435 2.40002 2.60625 2.40002H0.9ZM6.6 21.6C7.07739 21.6 7.53523 21.4104 7.87279 21.0728C8.21036 20.7353 8.4 20.2774 8.4 19.8C8.4 19.3226 8.21036 18.8648 7.87279 18.5272C7.53523 18.1897 7.07739 18 6.6 18C6.12261 18 5.66477 18.1897 5.32721 18.5272C4.98964 18.8648 4.8 19.3226 4.8 19.8C4.8 20.2774 4.98964 20.7353 5.32721 21.0728C5.66477 21.4104 6.12261 21.6 6.6 21.6ZM13.2 16.2C13.2 17.6322 13.7689 19.0057 14.7816 20.0184C15.7943 21.0311 17.1678 21.6 18.6 21.6C20.0322 21.6 21.4057 21.0311 22.4184 20.0184C23.4311 19.0057 24 17.6322 24 16.2C24 14.7679 23.4311 13.3943 22.4184 12.3816C21.4057 11.369 20.0322 10.8 18.6 10.8C17.1678 10.8 15.7943 11.369 14.7816 12.3816C13.7689 13.3943 13.2 14.7679 13.2 16.2ZM21 16.8H19.2V18.6C19.2 18.93 18.93 19.2 18.6 19.2C18.27 19.2 18 18.93 18 18.6V16.8H16.2C15.87 16.8 15.6 16.53 15.6 16.2C15.6 15.87 15.87 15.6 16.2 15.6H18V13.8C18 13.47 18.27 13.2 18.6 13.2C18.93 13.2 19.2 13.47 19.2 13.8V15.6H21C21.33 15.6 21.6 15.87 21.6 16.2C21.6 16.53 21.33 16.8 21 16.8Z"
      fill={color}
    />
  </Icon>
);
