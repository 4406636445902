import styled from '@emotion/styled';

import { ModalTypes, useModal } from '../../../hooks/useModal';
import { useGetUserQuery } from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { ProfileImageRound } from '../../common/ProfileImageRound';
import { EditPencilIcon } from '../../Icons/EditPencilIcon';

export const ProfileInfoPhotoSection = () => {
  const { data: user } = useGetUserQuery();
  const { showModal } = useModal();

  const onProfileImagePress = () => {
    showModal({
      type: ModalTypes.EditProfilePictureModal,
      data: {
        user: user,
      },
    });
  };

  return (
    <Container>
      <ProfilePictureContainer>
        <StyledProfileImageRound src={user?.profileImage} />
        <ProfilePictureEditOverlay onClick={onProfileImagePress}>
          <EditPencilIcon />
        </ProfilePictureEditOverlay>
      </ProfilePictureContainer>
      <Info>
        <Title>{user?.name}</Title>
        <Email>{user?.email}</Email>
        <MemberSince>{user?.created}</MemberSince>
      </Info>
    </Container>
  );
};

const ProfilePictureContainer = styled.div`
  position: relative;
`;

const ProfilePictureEditOverlay = styled.div`
  position: absolute;
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
  :hover {
    opacity: 0.6;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 20px;
`;
const StyledProfileImageRound = styled(ProfileImageRound)`
  width: 80px;
  height: 80px;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 5;
`;
const Title = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
const Email = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemBody}
`;
const MemberSince = styled.div`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemBody}
`;
