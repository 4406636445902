import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { Question } from '../../../../models/training/Question';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { SCORE_SET_BY_BACKEND } from '../hooks/useAnswerQuestion';

import { AnswerValidText } from './AnswerValidText';
import { CorrectAnswerBlock } from './CorrectAnswerBlock';
import { InputForm } from './InputForm';

type Props = {
  question: Question;
  validAnswer: boolean | null;
  onAnswerQuestion: (
    answer: string,
    questionId: string,
    numberOfPoints: number
  ) => void;
};

export const DictationQuestion = ({
  question,
  validAnswer,
  onAnswerQuestion,
}: Props) => {
  const t = useTranslations();
  const [answer, setAnswer] = useState('');

  const onCheckAnswerClick = (answer: string) => {
    setAnswer(answer);
    onAnswerQuestion(answer, question._id, SCORE_SET_BY_BACKEND);
  };

  return (
    <Container>
      <Title>{t('translation')}</Title>
      <InputForm
        buttonTextKey="test_answer"
        onValidateInput={onCheckAnswerClick}
      />
      {validAnswer !== null && <AnswerValidText correct={validAnswer} />}
      {answer !== '' && <CorrectAnswerBlock question={question} />}
    </Container>
  );
};
const Container = styled.div`
  padding: 8px 0;
`;
const Title = styled.h1`
  color: ${Colors.inputTextColor};
  ${TextStyles.InputHintText}
`;
