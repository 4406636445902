import { FacebookLogin } from '@capacitor-community/facebook-login';

import { SocialProvider } from '../../../utils/socialLoginUtils';

import { SocialUser } from './SocialUser';

const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];

export const signInFacebook = async (): Promise<SocialUser> => {
  try {
    await FacebookLogin.initialize({
      appId: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
    });

    const loginResult = await FacebookLogin.login({
      permissions: FACEBOOK_PERMISSIONS,
    });

    if (!loginResult.accessToken) {
      throw new Error('Facebook login failed: No access token received');
    }

    return {
      provider: SocialProvider.facebook,
      token: loginResult.accessToken.token,
    };
  } catch (error) {
    console.error('Error during Facebook sign-in:', error);
    throw error;
  }
};

export const signOutFacebook = async () => {
  try {
    await FacebookLogin.logout();
  } catch (error) {
    console.error('Error during Facebook sign-out:', error);
  }
};
