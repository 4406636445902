export enum BuyableType {
  summary = 'summary',
  training = 'training',
  videos = 'videos',
}
export const productTypePrefix = {
  summary: 'samenvatting',
  exercises: 'oefenboek',
  practiceModule: 'oefenmodule',
  videos: 'uitlegvideos',
};

export const getProductTypePrefixFromBuyableType = (
  buyableType: BuyableType
) => {
  switch (buyableType) {
    case BuyableType.training:
      return productTypePrefix.practiceModule;
    case BuyableType.summary:
      return productTypePrefix.summary;
    case BuyableType.videos:
      return productTypePrefix.videos;
  }
};
