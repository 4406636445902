import { HeaderRight } from '../practice/HeaderRight';

export const ResultHeaderRight = () => (
  <HeaderRight
    currentQuestionIndex={0}
    amountOfQuestions={0}
    showQuestionAmount={false}
    thesisId=""
  />
);
