import { IconProps, Icon } from './Icon';

export const ArrowUpIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M8.67997 0.539063C8.50028 0.355469 8.25809 0.25 8.00028 0.25C7.74247 0.25 7.50028 0.355469 7.32059 0.539063L0.758093 7.41406C0.398718 7.78906 0.414343 8.38281 0.789343 8.73828C1.16434 9.09375 1.75809 9.08203 2.11356 8.70703L7.06278 3.52734V16.8125C7.06278 17.332 7.48075 17.75 8.00028 17.75C8.51981 17.75 8.93778 17.332 8.93778 16.8125V3.52734L13.8831 8.71094C14.2425 9.08594 14.8323 9.09766 15.2073 8.74219C15.5823 8.38672 15.594 7.79297 15.2386 7.41797L8.67606 0.542969L8.67997 0.539063Z"
      fill={color}
    />
  </Icon>
);
