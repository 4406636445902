import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Results } from '../../models/Results';
import { Slices } from '../Slices';

export type TrainingState = {
  lastResult: Results | undefined;
};

const initialState: TrainingState = {
  lastResult: undefined,
};

const trainingSlice = createSlice({
  name: Slices.Training,
  initialState,
  reducers: {
    setLastResult(state, action: PayloadAction<Results>) {
      state.lastResult = action.payload;
    },
  },
});

export const { setLastResult } = trainingSlice.actions;

export default trainingSlice.reducer;
