import { IconProps, Icon } from './Icon';

export const PlusIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M9.05286 10.948H4.94598C4.67997 10.948 4.45548 10.8565 4.27251 10.6735C4.08953 10.4905 3.99805 10.266 3.99805 10C3.99805 9.73404 4.08953 9.50956 4.27251 9.32659C4.45548 9.14361 4.67997 9.05213 4.94598 9.05213H9.05286V4.94525C9.05286 4.67924 9.14435 4.45475 9.32732 4.27177C9.51029 4.0888 9.73478 3.99731 10.0008 3.99731C10.2668 3.99731 10.4913 4.0888 10.6742 4.27177C10.8572 4.45475 10.9487 4.67924 10.9487 4.94525V9.05213H15.0556C15.3216 9.05213 15.5461 9.14361 15.729 9.32659C15.912 9.50956 16.0035 9.73404 16.0035 10C16.0035 10.266 15.912 10.4905 15.729 10.6735C15.5461 10.8565 15.3216 10.948 15.0556 10.948H10.9487V15.0548C10.9487 15.3208 10.8572 15.5453 10.6742 15.7283C10.4913 15.9113 10.2668 16.0028 10.0008 16.0028C9.73478 16.0028 9.51029 15.9113 9.32732 15.7283C9.14435 15.5453 9.05286 15.3208 9.05286 15.0548V10.948Z"
      fill={color}
    />
  </Icon>
);
