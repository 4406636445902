import styled from '@emotion/styled';

import { FAQContent } from './FAQContent';

export const FAQIndex = () => {
  return (
    <Container>
      <FAQContent />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
