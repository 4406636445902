import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SecondaryButton } from '../../common/Button';

import { EmailVerificationState } from './EmailVerificationState';

type Props = {
  onClick: () => void;
  emailState: EmailVerificationState;
};

export const EmailVerificationFooter = ({ onClick, emailState }: Props) => {
  const t = useTranslations();

  return (
    <>
      {emailState !== EmailVerificationState.resent ? (
        <SecondaryButton
          buttonSize="md"
          onClick={onClick}
          disabled={emailState !== EmailVerificationState.resendable}
        >
          {t('send_code_again')}
        </SecondaryButton>
      ) : (
        <ResendMessage>{t('sent_new_code')}</ResendMessage>
      )}
    </>
  );
};
const ResendMessage = styled.div`
  ${TextStyles.BodySmall}
  color: ${Colors.inputHintText};
`;
