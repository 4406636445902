import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { LocalStorageKey } from '../../constants/localStorage';
import { useModal } from '../../hooks/useModal';
import { User } from '../../models/User';
import { Colors } from '../../styles/colors';
import { CardBase } from '../common/CardBase';

import { ModalCardHeader } from './ModalCardHeader';

export const getUserReadSummaryTutorial = (user: User) => {
  if (user?._id) {
    return JSON.parse(
      localStorage.getItem(`${user._id}:${LocalStorageKey.summaryTutorial}`) ||
        'false'
    );
  }
};

export const setUserReadSummaryTutorial = (user: User) => {
  if (user?._id) {
    localStorage.setItem(
      `${user._id}:${LocalStorageKey.summaryTutorial}`,
      JSON.stringify(true)
    );
  }
};

export const SummaryTutorialModal = () => {
  const t = useTranslations();
  const { dismissModal } = useModal();
  return (
    <CardBase
      fullWidth={true}
      header={
        <ModalCardHeader
          onCancelClick={dismissModal}
          titleKey="what_summary_colors_mean"
        />
      }
    >
      <Container topicLevelIndicatorColor={Colors.topicPositive}>
        <LeftSpacing />
        <Text>{t('you_are_doing_well')}</Text>
      </Container>
      <Container topicLevelIndicatorColor={Colors.topicMedium}>
        <LeftSpacing />
        <Text>{t('you_are_on_track')}</Text>
      </Container>
      <Container topicLevelIndicatorColor={Colors.topicNegative}>
        <LeftSpacing />
        <Text>{t('there_is_still_work')}</Text>
      </Container>
    </CardBase>
  );
};

const LeftSpacing = styled.div`
  flex: 1;
`;

const Text = styled.div`
  font-family: Montserrat;
  text-align: left;
  flex: 3;
`;

const Container = styled.div<{ topicLevelIndicatorColor: string }>`
  position: relative;
  margin: 20px 20px;
  display: flex;
  color: ${Colors.primaryTextColor};
  height: 15px;

  &:before {
    content: '';
    display: none;
    height: 100%;
    width: 4px;
    background-color: transparent;
    position: absolute;

    @media (max-width: 768px) {
      left: -20px;
    }
  }

  &:before {
    display: block;
    background-color: ${({ topicLevelIndicatorColor }) =>
      topicLevelIndicatorColor};
  }
`;
