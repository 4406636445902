import { Capacitor } from '@capacitor/core';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { Platform } from '../../../constants/platforms';
import { useError } from '../../../hooks/useError';
import { Error } from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { AppleButton, FacebookButton, GoogleButton } from '../../common/Button';

import { signInApple } from './AppleLoginProvider';
import { signInFacebook } from './FacebookLoginProvider';
import { signInGoogle } from './GoogleLoginProvider';
import { useSocialLogin } from './hooks/useSocialLogin';
import { SocialUser } from './SocialUser';

export const LoginSocial = () => {
  const t = useTranslations();
  const [error, setError] = useState('');
  const { loginToAPI } = useSocialLogin();
  const { showErrorModal } = useError();

  const handleSocialLogin = async (
    socialSignInMethod: () => Promise<SocialUser>
  ) => {
    setError('');
    try {
      const user = await socialSignInMethod();
      const err = await loginToAPI(user);
      if (err) handleAPIError(err);
    } catch (error) {
      showErrorModal(error);
    }
  };

  const isIOS = Capacitor.getPlatform() === Platform.ios;

  const handleAPIError = (err: Error) => {
    switch (err.status) {
      case 409:
        setError(t('too_many_devices'));
        break;
      default:
        showErrorModal(err);
    }
  };

  return (
    <>
      <FacebookButton
        buttonSize="md"
        fullWidth={true}
        type="button"
        onClick={() => handleSocialLogin(signInFacebook)}
      >
        {t('facebook')}
      </FacebookButton>
      <StyledGoogleButton
        buttonSize="md"
        fullWidth={true}
        type="button"
        onClick={() => handleSocialLogin(signInGoogle)}
      >
        {t('google')}
      </StyledGoogleButton>
      {isIOS && (
        <StyledAppleButton
          buttonSize="md"
          fullWidth={true}
          type="button"
          onClick={() => handleSocialLogin(signInApple)}
        >
          {t('apple')}
        </StyledAppleButton>
      )}
      {error && <ErrorText>{error}</ErrorText>}
      <Line />
    </>
  );
};
const StyledGoogleButton = styled(GoogleButton)`
  margin-top: 12px;
`;
const StyledAppleButton = styled(AppleButton)`
  margin-top: 12px;
`;
const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.dividerColor};
  margin: 16px 0;
`;
const ErrorText = styled.p`
  color: ${Colors.errorColor};
  ${TextStyles.InputTextError}
`;
