import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { Messages } from '../../../global.d';
import { useError } from '../../../hooks/useError';
import {
  usePostForgotPasswordWithEmailMutation,
  Error,
} from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { FooterAlignment } from '../../common/CardBase';
import { InputFieldComponent } from '../../common/Input';
import { LoginCardBase } from '../LoginCardBase';

import { ForgotPasswordFooter } from './ForgotPasswordFooter';
import {
  ForgotPasswordSchema,
  FormData,
  FormFields,
} from './ForgotPasswordFormSchema';
import { ForgotPasswordHeader } from './ForgotPasswordHeader';

type Props = {
  onBackClick: () => void;
};

const enum ForgotPasswordState {
  INITIAL,
  EMAIL_SENT,
  EMAIL_SENT_TWICE,
}

const SEND_AGAIN_TIMEOUT_IN_MILLISECONDS = 10000;

export const ForgotPasswordCard = ({ onBackClick }: Props) => {
  const t = useTranslations();
  const [forgotPasswordState, setForgotPasswordState] =
    useState<ForgotPasswordState>(ForgotPasswordState.INITIAL);
  const [canSendAgain, setCanSendAgain] = useState(true);
  const { showErrorModal } = useError();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const { ref: emailRef, ...emailRest } = register(FormFields.email, {
    required: true,
  });

  const [postForgotPassword] = usePostForgotPasswordWithEmailMutation();

  const onSubmit = (data: FormData) => onSendClick(data.email);

  const onSendClick = async (email: string) => {
    try {
      await postForgotPassword({ email }).unwrap();

      switch (forgotPasswordState) {
        case ForgotPasswordState.INITIAL:
          setForgotPasswordState(ForgotPasswordState.EMAIL_SENT);
          setCanSendAgain(false);
          setTimeout(() => {
            setCanSendAgain(true);
          }, SEND_AGAIN_TIMEOUT_IN_MILLISECONDS);
          break;
        case ForgotPasswordState.EMAIL_SENT:
          setForgotPasswordState(ForgotPasswordState.EMAIL_SENT_TWICE);
          break;
        default:
          break;
      }
    } catch (err) {
      const error = err as Error;
      switch (error.status) {
        case 400:
          setErrorMessage('no_valid_email');
          break;
        case 404:
          setErrorMessage('user_not_found');
          break;
        default:
          showErrorModal(error);
          break;
      }
    }
  };

  const setErrorMessage = (messageKey: keyof Messages) => {
    setError(FormFields.email, {
      type: 'manual',
      message: t(messageKey),
    });
  };

  const getErrorMessage = (error?: FieldError) =>
    error?.message !== undefined ? t(error.message as keyof Messages) : '';

  return (
    <LoginCardBase
      onSubmit={handleSubmit(onSubmit)}
      header={<ForgotPasswordHeader onBackClick={onBackClick} />}
      footerButtons={
        <ForgotPasswordFooter
          mailSuccessfullySent={
            forgotPasswordState !== ForgotPasswordState.INITIAL
          }
          hasBeenSentForTheSecondTime={
            forgotPasswordState === ForgotPasswordState.EMAIL_SENT_TWICE
          }
          sendDisabled={!canSendAgain}
          onBackClick={onBackClick}
        />
      }
      footerAlignment={
        forgotPasswordState === ForgotPasswordState.INITIAL
          ? FooterAlignment.right
          : FooterAlignment['space-between']
      }
    >
      {forgotPasswordState !== ForgotPasswordState.INITIAL ? (
        <Success>{t('forgot_password_email_sent')}</Success>
      ) : (
        <InputFieldComponent
          label={t('forgot_password_explanation')}
          type="e-mail"
          hasError={errors.email !== undefined}
          errorText={getErrorMessage(errors.email)}
          placeholder={t('placeholder_e-mail')}
          {...emailRest}
          inputRef={emailRef}
        />
      )}
    </LoginCardBase>
  );
};

const Success = styled.p`
  ${TextStyles.InputHintText};
  color: ${Colors.inputTextColor};
`;
