import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { appRoutes } from '../../../constants/routes';
import { Messages } from '../../../global.d';
import { useError } from '../../../hooks/useError';
import { usePutProductPairMutation } from '../../../services/dashboardApi';
import { Error } from '../../../services/userApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { CardBase, FooterAlignment } from '../../common/CardBase';
import { BigInput } from '../../Inputs/BigInput';
import { CardHeader } from '../CardHeader';

import { LinkProductCardFooter } from './LinkProductCardFooter';
import { LinkProductSchema, FormData, FormFields } from './LinkProductSchema';

export const LinkProductCard = () => {
  const navigate = useNavigate();
  const t = useTranslations();
  const [pairProductTrigger, { isLoading }] = usePutProductPairMutation();
  const { showErrorModal } = useError();
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(LinkProductSchema),
  });

  const { ref: codeRef, ...codeRegister } = register(FormFields.Code, {
    required: true,
  });

  const onSubmit = (data: FormData) => linkProduct(data.code);

  const linkProduct = async (code: string) => {
    setIsSuccess(false);
    try {
      await pairProductTrigger({ accessCode: code }).unwrap();
      setIsSuccess(true);
      reset();
    } catch (error) {
      handleError(error as Error);
    }
  };

  const handleError = (error: Error) => {
    const errorMessages: Record<number, keyof Messages> = {
      400: 'invalid_code',
      403: 'code_already_used_by_someone_else',
      404: 'invalid_code',
      409: 'code_already_paired',
    };

    const messageKey = errorMessages[error.status];
    if (messageKey) {
      setErrorMessage(messageKey);
    } else {
      showErrorModal(error);
    }
  };

  const setErrorMessage = (messageKey: keyof Messages) => {
    setError(FormFields.Code, {
      message: t(messageKey),
    });
  };

  const getErrorMessage = (error?: FieldError) =>
    error?.message ? t(error.message as keyof Messages) : '';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledCardBase
        fullWidth={true}
        header={<CardHeader title={t('link_products')} />}
        footerButtons={
          <LinkProductCardFooter
            onSkipClick={() => navigate(appRoutes.Dashboard)}
            linkDisabled={isLoading}
          />
        }
        footerAlignment={FooterAlignment['space-between']}
        fullHeightBody={true}
      >
        <ScrollContainer>
          <Container>
            <TextContainer>
              <Text>{t('did_you_already_buy_a_product')}</Text>
              <Highlight>{t('fill_in_the_code')}</Highlight>
            </TextContainer>

            {!isLoading && (
              <BigInput
                type="text"
                errorText={getErrorMessage(errors.code)}
                {...codeRegister}
                inputRef={codeRef}
              />
            )}
            {isSuccess && (
              <Success>{t('your_new_product_has_been_paired')}</Success>
            )}

            <Text>{t('skip_if_no_products')}</Text>
          </Container>
        </ScrollContainer>
      </StyledCardBase>
    </form>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: 100vh;
  overflow-y: auto;

  @media ${HookMediaQueries.isDesktop} {
    margin-top: 24px;
    height: 80vh;
  }
`;
const TextContainer = styled.div``;

const ScrollContainer = styled.div`
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 580px;
  gap: 24px;
`;

const Header = styled.div`
  text-align: center;
`;

const Text = styled.div`
  white-space: pre-line;
  color: ${Colors.inputHintText};
  ${TextStyles.BodyMedium}
  text-align: center;
`;

const Highlight = styled.span`
  color: ${Colors.primary};
`;

const Success = styled.p`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyText}
`;
