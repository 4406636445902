import styled from '@emotion/styled';
import { ComponentProps, InputHTMLAttributes, Ref } from 'react';
import { useTranslations } from 'use-intl';

import { config } from '../../../config';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { Checkbox } from '../../common/Checkbox';

type RegisterCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  hasError: boolean;
  errorText: string;
  inputRef?: Ref<HTMLInputElement>;
} & ComponentProps<'input'>;

export const RegisterCheckbox = ({
  hasError,
  errorText,
  inputRef,
  ...props
}: RegisterCheckboxProps) => {
  const t = useTranslations();
  return (
    <Checkbox
      hasError={hasError}
      errorText={errorText}
      inputRef={inputRef}
      {...props}
    >
      <TermsText>
        {t('i_accept_the')}
        <Link
          href={`${config.EXO_WEBSITE}/algemene-voorwaarden`}
          target="_blank"
        >
          {t('terms_and_conditions')}
        </Link>
      </TermsText>
    </Checkbox>
  );
};

const TermsText = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.InputText};
  margin: 0;
`;
const Link = styled.a``;
