import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { ModalTypes, useModal } from '../../../hooks/useModal';
import { Class } from '../../../models/User';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { CardBase } from '../../common/CardBase';
import { ProfileCardHeader } from '../ProfileCardHeader';

import { ClassRow } from './ClassRow';

type Props = {
  userClasses: Class[] | undefined;
  userName: string;
};

export const ProfileClasses = ({ userClasses, userName }: Props) => {
  const t = useTranslations();
  const { showModal } = useModal();

  const showEmptyMessage = !userClasses?.length;

  const onAddClassClick = () => {
    showModal({
      type: ModalTypes.AddClassModal,
      data: {
        name: userName,
      },
    });
  };

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <ProfileCardHeader
          titleTranslationKey="my_classes"
          buttonTranslationKey="add_class"
          onButtonClick={onAddClassClick}
        />
      }
    >
      <Container>
        {userClasses?.map((classItem) => (
          // change key to classItem._id when backend has it: https://digitalclub.atlassian.net/browse/EXO-4201
          <ClassRow key={classItem.name} classItem={classItem} />
        ))}

        {showEmptyMessage && (
          <EmptyStateContainer>
            <EmptyStateText>{t('no_classes')}</EmptyStateText>
          </EmptyStateContainer>
        )}
      </Container>
    </CardBase>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const EmptyStateText = styled.p`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.BodyText}
`;
