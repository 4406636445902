import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { config } from '../../../config';
import { HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT } from '../../../constants/Sizes';
import { Messages } from '../../../global.d';
import { OldExamPeriod } from '../../../models/OldExams';
import { useGetOldExamsQuery } from '../../../services/subjectsApi';
import { TextStyles } from '../../../styles/textStyles';
import { SecondaryButton } from '../../common/Button';
import { CardBase } from '../../common/CardBase';
import { ArrowBackIcon } from '../../Icons/ArrowBackIcon';

import { ExamPeriod } from './OldExamDetailsComponent';

export const OldExamDocumentComponent = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { encodedSubjectName, examYear, examPeriod, oldExamDocument } =
    useParams();
  const { data: oldExams } = useGetOldExamsQuery(
    encodedSubjectName ?? skipToken
  );

  const selectedOldExam = oldExams?.find(
    (exam) => exam.year === Number(examYear)
  );

  const getSelectedPeriod = () => {
    if (examPeriod && selectedOldExam) {
      if (examPeriod === ExamPeriod.firstPeriod) {
        return selectedOldExam.firstPeriod;
      }
      if (examPeriod === ExamPeriod.secondPeriod) {
        return selectedOldExam.secondPeriod;
      }
    }
  };

  const selectedPeriod = getSelectedPeriod();
  const selectedDocument =
    oldExamDocument &&
    selectedPeriod &&
    selectedPeriod[oldExamDocument as keyof OldExamPeriod];

  const documentUrl = `${config.API_BASE_URL}${selectedDocument}`;

  const onReturnPress = () => {
    navigate(-1);
  };

  const onDownloadPress = () => {
    window.open(documentUrl, '_blank');
  };

  return (
    <CardBase
      fullWidth={true}
      header={
        <HeaderContent>
          <NavigationContainer onClick={onReturnPress}>
            <ArrowBackIcon />
            <ExamTextContainer>
              {t('exam')} {t(oldExamDocument as keyof Messages)}{' '}
              {t('school_period', { count: examPeriod })} {examYear}
            </ExamTextContainer>
          </NavigationContainer>
          <StyledSecondaryButton buttonSize="md" onClick={onDownloadPress}>
            {t('download')}
          </StyledSecondaryButton>
        </HeaderContent>
      }
    >
      <DocumentFrame src={`${documentUrl}#toolbar=0&view=FitH`}></DocumentFrame>
    </CardBase>
  );
};

const StyledSecondaryButton = styled(SecondaryButton)`
  ${TextStyles.ButtonTextExtraSmallText}
`;
const HeaderContent = styled.div`
  ${TextStyles.OldExamsBase}
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NavigationContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const ExamTextContainer = styled.span`
  margin: 0 5px;
`;

const DocumentFrame = styled.embed`
  height: calc(100vh - ${HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT});
  width: 100%;
`;
