import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { useUserManagement } from '../../../hooks/useUserManagement';
import { CourseLevel } from '../../../models/CourseLevel';
import { Subject, SubjectCategory } from '../../../models/Subject';
import {
  useGetCourseLevelsQuery,
  useLazyGetSubjectsByCourseLevelQuery,
} from '../../../services/onboardingApi';
import { useAppDispatch } from '../../../store/hooks';
import {
  addSubject,
  removeSubject,
} from '../../../store/slices/userManagementSlice';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { PrimaryButton } from '../../common/Button';
import { CardBase, FooterAlignment } from '../../common/CardBase';
import { SubjectImage } from '../../common/SubjectImage';
import { CardHeader } from '../CardHeader';
import { SelectableItem } from '../SelectableItem';
import { SelectableItemContainer } from '../SelectableItemContainer';

type SubjectGroup = {
  courseLevel: CourseLevel;
  subjects: Subject[] | undefined;
};

type Props = {
  mainCourseLevel: string;
  category: SubjectCategory;
  onContinue: () => void;
  onBack: () => void;
};

export const DifferentLevelSubjectCard = ({
  mainCourseLevel,
  category,
  onContinue,
  onBack,
}: Props) => {
  const userManagementState = useUserManagement();
  const selectedSubjects = userManagementState.subjects || [];
  const extraSubjectsSelected = selectedSubjects.filter(
    (subject) => subject.courseLevel !== mainCourseLevel
  );
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const { data: courseLevels } = useGetCourseLevelsQuery();
  const [getSubjectsByCourseLevel] = useLazyGetSubjectsByCourseLevelQuery();

  const [courseLevelSubjects, setCourseLevelSubjects] = useState<
    SubjectGroup[]
  >([]);

  /* 
    At the moment not possible to fetch all [category] subjects for multiple course levels in one call.
    That is why this component has a for loop for each course level. 
    Better solution is one call to the backend where you can pass multiple courseLevels. Request in ticket: https://digitalclub.atlassian.net/browse/EXO-4204 
  */
  useEffect(() => {
    const fetchSubjects = async () => {
      if (courseLevels) {
        const otherCourseLevels = courseLevels.filter(
          (courseLevel) => courseLevel.name !== mainCourseLevel
        );

        const subjectGroups = await Promise.all(
          otherCourseLevels.map(async (courseLevel) => {
            const { data: subjects } = await getSubjectsByCourseLevel({
              courseLevelEncoded: courseLevel.name,
              categories: [category],
            });
            return { courseLevel, subjects };
          })
        );

        setCourseLevelSubjects(subjectGroups);
      }
    };

    fetchSubjects();
  }, [courseLevels, getSubjectsByCourseLevel, mainCourseLevel, category]);

  const onSubjectSelect = (subject: Subject) => {
    if (
      userManagementState.subjects?.some(
        (s) => s.encodedName === subject.encodedName
      )
    ) {
      dispatch(removeSubject(subject._id));
    } else {
      dispatch(addSubject(subject));
    }
  };

  return (
    <StyledCardBase
      fullWidth={true}
      header={
        <CardHeader
          title={t('select_subject_for_other_levels')}
          onBack={onBack}
        />
      }
      footerButtons={
        <PrimaryButton
          buttonSize="md"
          onClick={onContinue}
          disabled={!extraSubjectsSelected.length}
        >
          {t('add')}
        </PrimaryButton>
      }
      footerAlignment={FooterAlignment.right}
      fullHeightBody={true}
    >
      <ScrollContainer>
        {courseLevelSubjects.map((subjectGroup) => {
          if (!subjectGroup.subjects?.length) {
            return <></>;
          }

          return (
            <Container key={subjectGroup.courseLevel.encodedName}>
              <Header>{subjectGroup.courseLevel.name}</Header>
              <SelectableItemContainer>
                {subjectGroup.subjects?.map((subject) => (
                  <SelectableItem
                    key={subject.encodedName}
                    text={subject.name}
                    icon={<StyledSubjectImage src={subject?.subjectImage} />}
                    onClick={() => onSubjectSelect(subject)}
                    active={selectedSubjects.some(
                      (selectedSubject) =>
                        selectedSubject.encodedName === subject.encodedName
                    )}
                  />
                ))}
              </SelectableItemContainer>
            </Container>
          );
        })}
      </ScrollContainer>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: 100vh;
  overflow-y: scroll;

  @media ${HookMediaQueries.isDesktop} {
    margin-top: 24px;
    height: 80vh;
  }
`;

const Container = styled.div`
  padding-bottom: 24px;
`;

const ScrollContainer = styled.div`
  overflow-y: scroll;
`;

const Header = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
  padding: 16px 0;
`;

const StyledSubjectImage = styled(SubjectImage)`
  width: 40px;
  height: 40px;
`;
