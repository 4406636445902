import styled from '@emotion/styled';

import { MediaQueries } from '../../constants/MediaQueries';
import { useGetUserQuery } from '../../services/userApi';

import { ProfileClasses } from './classes/ProfileClasses';
import { ProfileInfo } from './info/ProfileInfo';

export const ProfileLeftContent = () => {
  const { data: user } = useGetUserQuery();
  return (
    <Container>
      <ProfileInfo />
      <ProfileClasses userClasses={user?.classes} userName={user?.name || ''} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;

  @media (min-width: ${MediaQueries.desktop}) {
    width: 400px;
  }
`;
