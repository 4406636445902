import { IconProps, Icon } from './Icon';

export const ArrowBackIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M1.53906 10.6795C1.35547 10.4998 1.25 10.2576 1.25 9.99979C1.25 9.74198 1.35547 9.49979 1.53906 9.3201L8.41406 2.7576C8.78906 2.39823 9.38281 2.41385 9.73828 2.78885C10.0938 3.16385 10.082 3.7576 9.70703 4.11307L4.52734 9.06229H17.8125C18.332 9.06229 18.75 9.48026 18.75 9.99979C18.75 10.5193 18.332 10.9373 17.8125 10.9373H4.52734L9.71094 15.8826C10.0859 16.242 10.0977 16.8318 9.74219 17.2068C9.38672 17.5818 8.79297 17.5935 8.41797 17.2381L1.54297 10.6756L1.53906 10.6795Z"
      fill={color}
    />
  </Icon>
);
