import styled from '@emotion/styled';

import { LeaderBoardScore } from '../../../models/LeaderBoard';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  leaderBoardScore: LeaderBoardScore;
};

export const LeaderBoardPodiumUserRow = ({ leaderBoardScore }: Props) => {
  return (
    <TextContainer isMe={leaderBoardScore.isMe}>
      <PodiumPositionContainer className="rank-no">
        #{leaderBoardScore.position}
      </PodiumPositionContainer>
      <NickNameContainer>{leaderBoardScore.nickname}</NickNameContainer>
      <ScoreTextContainer>{leaderBoardScore.score}</ScoreTextContainer>
    </TextContainer>
  );
};

const PodiumPositionContainer = styled.h1`
  ${TextStyles.LeaderBoardPodium}
  color: ${Colors.disabledTextColor};
`;

const NickNameContainer = styled.span`
  word-wrap: break-word;
  display: block;
  max-width: 100px;
`;

const TextContainer = styled.div<{ isMe: boolean }>`
  ${TextStyles.LeaderBoardText};
  flex: 1;
  ${({ isMe }) => isMe && 'font-weight: 600;'};
`;

const ScoreTextContainer = styled.div`
  ${TextStyles.LeaderBoardScore}
`;
