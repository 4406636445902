import styled from '@emotion/styled';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ForgotPasswordCard } from './forgot/ForgotPasswordCard';
import { LoginCard } from './login/LoginCard';
import { RegisterCard } from './register/RegisterCard';
import { ResetCard } from './reset/ResetCard';

type LoginFormsProps = {
  reset: boolean;
};

/*
  In the Angular App, when the user presses 'register' the app will navigate to /login?register=true. 
  In Angular when the user presses 'forgot password' the app will stay at /Login, but show a different CardBase.
  This is why this component gets register from the searchParams and forgotPassword from click listeners. 
  I did not want to change this behavior due to maybe breaking existing linking.
*/

export const LoginForms = ({ reset }: LoginFormsProps) => {
  const [searchParams] = useSearchParams();
  const [forgotPassword, setForgotPassword] = useState(false);

  const toForgotPassword = () => {
    setForgotPassword(true);
  };

  const toLogin = () => {
    setForgotPassword(false);
  };

  const getCard = () => {
    if (reset) return <ResetCard />;
    if (searchParams.get('register')) {
      return <RegisterCard />;
    } else if (forgotPassword) {
      return <ForgotPasswordCard onBackClick={() => toLogin()} />;
    }
    return <LoginCard onForgotClick={() => toForgotPassword()} />;
  };

  return <Container>{getCard()}</Container>;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
