import { IconProps, Icon } from './Icon';

export const CheckIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M18.4766 4.02344C18.8438 4.39062 18.8438 4.98437 18.4766 5.34766L8.16406 15.6641C7.79688 16.0312 7.20313 16.0312 6.83984 15.6641L1.52344 10.3516C1.15625 9.98438 1.15625 9.39063 1.52344 9.02734C1.89062 8.66406 2.48438 8.66016 2.84766 9.02734L7.49609 13.6758L17.1484 4.02344C17.5156 3.65625 18.1094 3.65625 18.4727 4.02344H18.4766Z"
      fill={color}
    />
  </Icon>
);
