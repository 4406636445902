import { Section } from './Summary/summary';

export type Course = {
  category: string;
  code: string;
  color: string;
  courseLevel: string;
  name: string;
  sku: string[];
  subjectImage: string;
  startDate: string;
  endDate: string;
  hasSummary: boolean;
  hasExercise: boolean;
  timeslots: {
    '1': {
      startDate: string;
      endDate: string;
    };
    '2': {
      startDate: string;
      endDate: string;
    };
  };
  _id: string;
  encodedName: string;
};

export type Subject = Course & {
  subjectRating: number;
  practiceModuleActive: boolean;
  userUnlockedExamTrainingVideo: boolean;
  examTrainingVideoActive: boolean;
  grade: number;
  sections?: Section[];
};

export type UserSubject = {
  _id: string;
  subject: Subject;
  grade: number;
};

export type UserObjectSubject = {
  _id: string;
  grade?: number;
};

export type OnboardingSubject = {
  _id: string;
  encodedName: string;
};

export enum SubjectCategory {
  Exact = 'Exacte vakken',
  Languages = 'Talen',
  Social = 'Maatschappij vakken',
  Extra = 'Extra vakken',
}
