import { z, ZodType } from 'zod';

import { hasForbiddenWords, PASSWORD_REGEX } from '../utils/FormHelper';

export type FormData = {
  password: string;
  repeatPassword: string;
};

export enum FormFields {
  password = 'password',
  repeatPassword = 'repeatPassword',
}

export const ResetFormSchema: ZodType<FormData> = z
  .object({
    password: z
      .string()
      .min(8, { message: 'password_should_have_8_characters' })
      .max(72, { message: 'password_max_72_characters' })
      .regex(PASSWORD_REGEX, { message: 'password_pattern' })
      .refine((password) => !hasForbiddenWords(password), {
        message: 'password_forbidden_words',
      }),
    repeatPassword: z.string(),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: 'passwords_not_the_same',
    path: [FormFields.repeatPassword],
  });
