import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Result } from '../../../components/subject/results/Result';
import { useError } from '../../../hooks/useError';
import { Results } from '../../../models/Results';
import { useTrainingResultsMutation } from '../../../services/trainingApi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setLastResult } from '../../../store/slices/trainingSlice';

export const ResultPage = () => {
  const { encodedSubjectName } = useParams();
  const [practiceResult, setPracticeResult] = useState<Results | undefined>();
  const trainingState = useAppSelector((state) => state.training);
  const [getResults] = useTrainingResultsMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showErrorModal } = useError();

  const showResult = async () => {
    if (!encodedSubjectName) return;
    try {
      // Using a lazy query instead of a hook because getResult is a POST
      const result = await getResults({ encodedSubjectName });

      if (result.error) {
        throw result.error;
      }

      dispatch(setLastResult(result.data));
      setPracticeResult(result.data);
    } catch (error) {
      showErrorModal(error);

      if (trainingState.lastResult) {
        setPracticeResult(trainingState.lastResult);
      } else {
        navigate('..', { relative: 'path' });
      }
    }
  };

  useEffect(() => {
    if (!encodedSubjectName) return;
    showResult();
  }, [encodedSubjectName]);

  if (!encodedSubjectName) {
    return <></>;
  }

  return (
    <Result
      encodedSubjectName={encodedSubjectName}
      practiceResult={practiceResult}
    />
  );
};
