import styled from '@emotion/styled';
import DOMPurify from 'dompurify';

import { Question } from '../../../../models/training/Question';
import { Colors } from '../../../../styles/colors';
import { PracticeCMS } from '../PracticeCMS';

type Props = {
  question: Question;
};

export const CorrectAnswerBlock = ({ question }: Props) => {
  return (
    <>
      <CorrectAnswer>
        <PracticeCMS>
          <Introduction
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(question.explanation || ''),
            }}
          />
        </PracticeCMS>
      </CorrectAnswer>
    </>
  );
};
const Introduction = styled.div`
  box-sizing: border-box;

  p {
    overflow-x: auto;
    overflow-y: hidden;
  }

  // MathJax containment
  mjx-container {
    font-size: 100% !important;
  }
`;
const CorrectAnswer = styled.div`
  border-radius: 4px;
  padding: 12px 16px;
  background-color: ${Colors.backgroundColor};
  box-sizing: border-box;
  overflow-x: hidden;
`;
