import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { PropsWithChildren } from 'react';

import { HookMediaQueries } from '../constants/MediaQueries';
import { FOOTER_PADDING_PX, HEADER_HEIGHT_PX } from '../constants/Sizes';

type Props = PropsWithChildren;

export const Page = ({ children }: Props) => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  return <Margin isDesktop={isDesktop}>{children}</Margin>;
};

const Margin = styled.div<{ isDesktop: boolean }>`
  max-width: 1080px;
  margin: auto;
  ${({ isDesktop }) => isDesktop && 'padding: 20px;'}

  padding-top: calc(${HEADER_HEIGHT_PX}px + env(safe-area-inset-top));
  padding-bottom: calc(${FOOTER_PADDING_PX}px + env(safe-area-inset-bottom));
`;
