import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useSubjectColorContext } from '../../../context/SubjectColorContext';
import { ModalTypes, useModal } from '../../../hooks/useModal';
import { ResultTopics } from '../../../models/Results';
import { Chapter, Section } from '../../../models/Summary/summary';
import { useGetSummarySectionsQuery } from '../../../services/subjectsApi';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SubjectColorButton } from '../../common/Button';
import { PracticeContainer } from '../practice/PracticeContainer';

import { ResultTopicRow } from './ResultTopicRow';

type Props = {
  topics: ResultTopics[];
};

export const ResultTopicsComponent = ({ topics }: Props) => {
  const t = useTranslations();
  const [searchParams] = useSearchParams();
  const encodedSectionName = searchParams.get('section');
  const { subjectColor } = useSubjectColorContext();
  const { encodedSubjectName } = useParams();
  const { showModal } = useModal();
  const { data: summary } = useGetSummarySectionsQuery(
    encodedSubjectName ?? skipToken
  );

  const currentSection = summary?.chapters.reduce(
    (acc, chapter) => {
      const section = chapter.sections.find(
        (section) => section.encodedSectionName === encodedSectionName
      );
      if (section) {
        return { chapter, section };
      }
      return acc;
    },
    undefined as { chapter: Chapter; section: Section } | undefined
  );

  const onTakeAnotherTestPress = () => {
    showModal({
      type: ModalTypes.StartTrainingModal,
      data: {
        encodedSubjectName,
        encodedSectionName: currentSection?.section.encodedSectionName,
        encodedChapterName: currentSection?.chapter.encodedChapterName,
      },
    });
  };

  return (
    <PracticeContainer shouldAlignCenter={false} isLeftContainer={false}>
      <TopicsContainer>
        <Topics>{t('topics')}</Topics>
        {topics.map((topic) => (
          <ResultTopicRow topic={topic} key={topic.encodedTopicName} />
        ))}
      </TopicsContainer>
      <SubjectColorButton
        buttonSize="md"
        buttonColor={subjectColor}
        onClick={onTakeAnotherTestPress}
        fullWidth={false}
      >
        {t('take_another_test')}
      </SubjectColorButton>
    </PracticeContainer>
  );
};

const TopicsContainer = styled.div`
  height: 90%;
`;

const Topics = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.TitleBig}
`;
