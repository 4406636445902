import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import {
  FREE_SHIPPING_REQUIREMENT_PRICE,
  SHIPPING_COST,
  Price,
} from '../store/slices/cartSlice';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

export const DiscountDetails = ({ price }: { price: Price }) => {
  const t = useTranslations();

  const postageCost =
    price.subTotal < FREE_SHIPPING_REQUIREMENT_PRICE
      ? `+ €${SHIPPING_COST},00`
      : t('free');

  const hasDiscount = price.discountAmount > 0;

  return (
    <Container>
      <DetailsContainer>
        <Postage>{t('postage')}</Postage>
        <TotalPrice>{t('totaal')}</TotalPrice>
        {hasDiscount && (
          <DiscountedTotalPrice>{t('you_save')}</DiscountedTotalPrice>
        )}
        <Postage>{`${t('free_postage')} ${FREE_SHIPPING_REQUIREMENT_PRICE}`}</Postage>
      </DetailsContainer>
      <PriceContainer>
        <Postage>{postageCost}</Postage>
        <TotalPriceContainer>
          {hasDiscount && (
            <OriginalPrice>{`€${price.initialSubTotal.toFixed(2)}`}</OriginalPrice>
          )}
          <TotalPrice>{`€${price.grandTotal.toFixed(2)}`}</TotalPrice>
        </TotalPriceContainer>
        {hasDiscount && (
          <DiscountedTotalPrice>{`€${price.discountAmount.toFixed(2)}`}</DiscountedTotalPrice>
        )}
      </PriceContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Postage = styled.h1`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
const TotalPrice = styled.h1`
  ${TextStyles.PriceBigText}
`;

const DiscountedTotalPrice = styled.h1`
  ${TextStyles.PriceBigText}
  color: ${Colors.discountLabelBackgroundGreen};
`;

const OriginalPrice = styled.h1`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.PriceSmallTextDiscount}
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
