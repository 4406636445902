import { IconProps, Icon } from './Icon';

export const GraduationCapIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M12.0003 6.49971C12.0862 6.49971 12.1721 6.5122 12.2546 6.54032L19.7482 8.99922L12.2546 11.4581C12.1721 11.4862 12.0862 11.4987 12.0003 11.4987C11.9143 11.4987 11.8284 11.4862 11.7459 11.4581L7.35631 10.0178L12.1962 8.36809C12.4815 8.27123 12.6259 7.98379 12.5193 7.72447C12.4128 7.46514 12.0965 7.33392 11.8112 7.43077L6.32509 9.29916C6.14634 9.36165 5.97104 9.43038 5.79916 9.50537L4.25576 8.99922L11.7493 6.54032C11.8318 6.5122 11.9178 6.49971 12.0037 6.49971H12.0003ZM1.54364 9.70533L4.2042 10.577C3.26922 11.4737 2.70205 12.661 2.65393 13.942C2.65049 13.9607 2.65049 13.9795 2.65049 13.9982C2.65049 14.8856 2.27925 15.8041 1.88395 16.5227C1.66051 16.9289 1.40614 17.3288 1.11053 17.6975C1.00053 17.8319 0.969592 18.0068 1.03147 18.1662C1.09334 18.3255 1.23771 18.4442 1.41646 18.4849L3.6164 18.9848C3.76078 19.0191 3.91546 18.9941 4.04264 18.9223C4.16983 18.8504 4.2592 18.7317 4.2867 18.5973C4.58232 17.2601 4.43451 16.0603 4.21451 15.2011C4.10452 14.7637 3.95671 14.3169 3.7539 13.9045C3.81921 12.7485 4.41388 11.6893 5.35917 10.9613L11.1856 12.8735C11.4468 12.9578 11.7218 13.0016 12.0003 13.0016C12.2787 13.0016 12.5537 12.9578 12.8149 12.8735L22.4569 9.70533C22.7834 9.5991 23 9.31478 23 8.99922C23 8.68365 22.7834 8.40246 22.4569 8.2931L12.8149 5.1281C12.5537 5.04374 12.2787 5 12.0003 5C11.7218 5 11.4468 5.04374 11.1856 5.1281L1.54364 8.2931C1.21709 8.40246 1.00053 8.68365 1.00053 8.99922C1.00053 9.31478 1.21709 9.59597 1.54364 9.70533ZM18.0707 12.2048L16.4723 12.7297L16.9089 16.4946C16.7439 16.604 16.4689 16.7446 16.0633 16.8883C15.0939 17.2351 13.6502 17.4976 12.0003 17.4976C10.3503 17.4976 8.90659 17.2351 7.9338 16.8914C7.52818 16.7477 7.25319 16.604 7.0882 16.4977L7.52475 12.7329L5.92635 12.208L5.40042 16.7477C5.40042 17.8506 8.3566 18.9973 12.0003 18.9973C15.6439 18.9973 18.6001 17.8506 18.6001 16.7477L18.0742 12.2048H18.0707Z"
      fill={color}
    />
  </Icon>
);
