import { IconProps, Icon } from './Icon';

export const SuccessCircleIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color="#00000000">
    <rect
      x="2"
      y="2"
      width="156"
      height="156"
      rx="78"
      stroke={color}
      strokeWidth="4"
    />
    <path
      d="M113.906 56.0938C115.375 57.5625 115.375 59.9375 113.906 61.3906L72.6562 102.656C71.1875 104.125 68.8125 104.125 67.3594 102.656L46.0938 81.4062C44.625 79.9375 44.625 77.5625 46.0938 76.1094C47.5625 74.6563 49.9375 74.6406 51.3906 76.1094L69.9844 94.7031L108.594 56.0938C110.062 54.625 112.438 54.625 113.891 56.0938H113.906Z"
      fill={color}
    />
  </Icon>
);
