import styled from '@emotion/styled';
import { ComponentProps, PropsWithChildren } from 'react';

export type IconSize = 'md' | 'lg' | 'sm' | 'xl';
export type IconProps = {
  color?: string;
  size?: IconSize;
  isButton?: boolean;
} & ComponentProps<'svg'>;

export const Icon = ({
  children,
  color,
  isButton,
  size = 'md',
  ...props
}: PropsWithChildren<IconProps>) => (
  <StyledSvg
    isButton={isButton}
    size={size}
    aria-hidden="true"
    fill={color}
    {...props}
  >
    {children}
  </StyledSvg>
);

const StyledSvg = styled.svg<{ size: IconSize; isButton?: boolean }>`
  ${({ size }) => size == 'sm' && 'width: 12px; height: 12px'};
  ${({ size }) => size == 'md' && 'width: 20px; height: 20px'};
  ${({ size }) => size == 'lg' && 'width: 24px; height: 24px'};
  ${({ size }) => size == 'xl' && 'width: 40px; height: 40px'};
  ${({ isButton }) => isButton && 'cursor: pointer'};
`;
