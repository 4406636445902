import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { useSubjectColorContext } from '../../../../context/SubjectColorContext';
import { Question } from '../../../../models/training/Question';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { SubjectColorButton } from '../../../common/Button';
import { RadioGrid } from '../../../Inputs/RadioGrid';

type Props = {
  question: Question;
  onSubmitPoints: (points: number) => void;
};

export const GrantPoints = ({ question, onSubmitPoints }: Props) => {
  const t = useTranslations();
  const [pointsGiven, setPointsGiven] = useState(false);
  const [points, setPoints] = useState<number | null>(null);
  const { subjectColor } = useSubjectColorContext();

  const handleRadioChange = (selected: number) => {
    setPoints(selected);
  };

  const onPointsGivenClick = () => {
    if (points === null) return;
    onSubmitPoints(points);
    setPointsGiven(true);
  };

  return (
    <>
      <Title>
        {t('for_this_question_you_can_earn_x_points', {
          count: question.totalPoints,
        })}
      </Title>
      <RadioGrid
        amountOfItems={(question.totalPoints || 0) + 1}
        onChange={handleRadioChange}
        color={subjectColor}
        disabled={pointsGiven}
      />
      {!pointsGiven && (
        <SubjectColorButton
          fullWidth={true}
          buttonSize="md"
          buttonColor={subjectColor}
          disabled={points === null}
          onClick={onPointsGivenClick}
        >
          {t('submit_points')}
        </SubjectColorButton>
      )}
    </>
  );
};
const Title = styled.h1`
  color: ${Colors.inputTextColor};
  ${TextStyles.InputHintText}
`;
