import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslations } from 'use-intl';

import { Video } from '../../../../models/Summary/summary';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { SummaryMode } from '../utils/summaryUtils';

import { VideoList } from './VideoList';

type Props = {
  videoList: Video[];
  activeVideo: Video | undefined;
  subjectColor: string;
  summaryMode: SummaryMode;
  onClickVideoCard: (video: Video) => void;
} & ComponentProps<'div'>;

export const VideoRecommendations = ({
  videoList,
  activeVideo,
  subjectColor,
  summaryMode,
  onClickVideoCard,
  ...props
}: Props) => {
  const t = useTranslations();
  const displayBigCards = summaryMode === SummaryMode.Both && !isDesktop;

  return (
    <Container {...props}>
      <Title>{t('other_videos_for_you')}</Title>
      {!displayBigCards && (
        <List>
          <VideoList
            direction="row"
            videoList={videoList}
            activeVideo={activeVideo}
            subjectColor={subjectColor}
            onClickVideoCard={onClickVideoCard}
          />
        </List>
      )}
      {displayBigCards && (
        <SideScroller>
          <VideoList
            direction="column"
            videoList={videoList}
            activeVideo={activeVideo}
            subjectColor={subjectColor}
            onClickVideoCard={onClickVideoCard}
          />
        </SideScroller>
      )}
    </Container>
  );
};
const Container = styled.div``;
const List = styled.div`
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;
const SideScroller = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 20px;
`;
const Title = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.SummaryReadingTitle}
`;
