import styled from '@emotion/styled';

import { ProductBundle } from '../../../models/ProductBundle';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { setCoverUrlToSummary } from '../../../utils/apiUtils';

type Props = {
  product: ProductBundle;
};

export const ProductRow = ({ product }: Props) => {
  return (
    <Container>
      <Image src={setCoverUrlToSummary(product.cover)} alt="Product Image" />
      <ProductDetails>
        <Title>{product.name}</Title>
        <Subtitle>{product.description}</Subtitle>
      </ProductDetails>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  gap: 20px;
`;

const Title = styled.div`
  ${TextStyles.ListItemTitle}
`;
const Subtitle = styled.div`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
const ProductDetails = styled.div`
  gap: 4px;
  flex: 1;
  justify-content: center;
  align-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 68px;
  max-height: 80px;
  object-fit: contain;
`;
