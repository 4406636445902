import Fuse from 'fuse.js';

import { fuseOptions } from '../../../../constants/Fuse';
import { Category, SubCategory } from '../../../../models/Definition';

export const fuseSearch = (
  searchQuery: string,
  categories?: Category[]
): Category[] => {
  let searchResults: Category[] = [];

  if (!categories) return searchResults;
  if (!searchQuery) return categories;

  const flattenedDefinitions = flattenData(categories);
  const fuse = new Fuse(flattenedDefinitions, fuseOptions);

  const fuseResults = new Set(
    fuse.search(searchQuery).map((result) => result.item._id)
  );

  searchResults = filterCategoriesWithSearchResult(fuseResults, categories);

  return searchResults;
};

const flattenData = (categories: Category[]) => {
  return categories.flatMap((category) =>
    category.subcategories.flatMap(
      (subCategory: SubCategory) => subCategory.definitions
    )
  );
};

const filterCategoriesWithSearchResult = (
  searchResult: Set<string>,
  categories: Category[]
) => {
  return categories
    .map((category) => {
      const updatedSubcategories = category.subcategories
        .map((subCategory) => {
          const filteredDefinitions = subCategory.definitions.filter(
            (definition) => searchResult.has(definition._id)
          );
          if (!filteredDefinitions.length) return null;
          return { ...subCategory, definitions: filteredDefinitions };
        })
        .filter(
          (subCategory): subCategory is SubCategory => subCategory !== null
        );

      if (!updatedSubcategories.length) return null;
      return { ...category, subcategories: updatedSubcategories };
    })
    .filter((category): category is Category => category !== null);
};
