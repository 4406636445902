import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Messages } from '../../global.d';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { SecondaryButton } from '../common/Button';

type Props = {
  titleTranslationKey: keyof Messages;
  buttonTranslationKey?: keyof Messages;
  onButtonClick?: () => void;
};

export const ProfileCardHeader = ({
  titleTranslationKey,
  buttonTranslationKey,
  onButtonClick,
}: Props) => {
  const t = useTranslations();

  return (
    <Container>
      <Title>{t(titleTranslationKey)}</Title>
      {buttonTranslationKey && (
        <ButtonContainer>
          <StyledSecondaryButton buttonSize="sm" onClick={onButtonClick}>
            {t(buttonTranslationKey)}
          </StyledSecondaryButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Title = styled.h1`
  flex: 1;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  height: 30px;
`;
