import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslations } from 'use-intl';

import { ModalTypes, useModal } from '../../../hooks/useModal';
import { useGetLastResultsQuery } from '../../../services/trainingApi';
import { Colors } from '../../../styles/colors';
import { SecondaryButton } from '../../common/Button';

import { BuyableType } from './utils/summaryUtils';

type Props = {
  buttonColor: string;
  encodedSubjectName: string;
  encodedChapterName: string;
  encodedSectionName: string;
  isPreview: boolean;
};

export const TestSectionButton = ({
  buttonColor,
  encodedSubjectName,
  encodedChapterName,
  encodedSectionName,
  isPreview,
}: Props) => {
  const t = useTranslations();
  const { data: trainingResult } = useGetLastResultsQuery(
    encodedSubjectName ?? skipToken
  );
  const { showModal } = useModal();

  const sectionResult = trainingResult?.find(
    (result) => result.encodedSectionName === encodedSectionName
  );
  const onTestClick = () => {
    if (isPreview) {
      showModal({
        type: ModalTypes.BuySectionModal,
        data: {
          encodedSubjectName,
          buyableType: BuyableType.Training,
        },
      });
      return;
    }
    showModal({
      type: ModalTypes.StartTrainingModal,
      data: {
        encodedSubjectName,
        encodedChapterName,
        encodedSectionName,
      },
    });
  };

  return (
    <TestSubjectButton
      color={buttonColor}
      buttonSize="md"
      onClick={onTestClick}
    >
      {t('test_topic')}
      {sectionResult && ` ${t('again')}`}
    </TestSubjectButton>
  );
};
const TestSubjectButton = styled(SecondaryButton) <{ color: string }>`
  color: ${Colors.primaryTextInverted};
  ${({ color }) => color && `background-color: ${color};`};
`;
