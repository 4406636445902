import styled from '@emotion/styled';
import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { Messages } from '../../global.d';
import { InputFieldComponent } from '../common/Input';

import { FormData, FormFields } from './ShippingFormSchema';

type Props = {
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
  setFocus: (name: keyof FormData) => void;
};

export const ShipmentForm = ({ register, errors, setFocus }: Props) => {
  const t = useTranslations();

  const { ref: firstNameRef, ...firstNameRest } = register(
    FormFields.firstName,
    {
      required: true,
    }
  );
  const { ref: lastNameRef, ...lastNameRest } = register(FormFields.lastName, {
    required: true,
  });
  const { ref: emailRef, ...emailRest } = register(FormFields.email, {
    required: true,
  });
  const { ref: streetRef, ...streetRest } = register(FormFields.street, {
    required: true,
  });
  const { ref: zipCodeRef, ...zipCodeRest } = register(FormFields.zipCode, {
    required: true,
  });
  const { ref: houseNumberRef, ...houseNumberRest } = register(
    FormFields.houseNumber,
    {
      required: true,
    }
  );
  const { ref: additionRef, ...additionRest } = register(FormFields.addition);
  const { ref: cityRef, ...cityRest } = register(FormFields.city, {
    required: true,
  });

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    nextField: Extract<keyof FormData, string>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setFocus(nextField);
    }
  };

  const getErrorText = (error?: FieldError) =>
    error?.message !== undefined ? t(error.message as keyof Messages) : '';

  return (
    <div>
      <InputFieldComponent
        label={t('first_name')}
        type="text"
        aria-label="first-name"
        hasError={!!errors.firstName}
        errorText={getErrorText(errors.firstName)}
        placeholder={t('placeholder_first_name')}
        onKeyDown={(e) => handleKeyDown(e, FormFields.lastName)}
        {...firstNameRest}
        inputRef={firstNameRef}
      />
      <InputFieldComponent
        label={t('last_name')}
        type="text"
        aria-label="last-name"
        hasError={!!errors.lastName}
        errorText={getErrorText(errors.lastName)}
        placeholder={t('placeholder_last_name')}
        onKeyDown={(e) => handleKeyDown(e, FormFields.email)}
        {...lastNameRest}
        inputRef={lastNameRef}
      />
      <InputFieldComponent
        label={t('email')}
        type="email"
        aria-label="email"
        hasError={!!errors.email}
        errorText={getErrorText(errors.email)}
        placeholder={t('placeholder_email')}
        onKeyDown={(e) => handleKeyDown(e, FormFields.street)}
        {...emailRest}
        inputRef={emailRef}
      />
      <InputFieldComponent
        label={t('street')}
        type="text"
        aria-label="street"
        hasError={!!errors.street}
        errorText={getErrorText(errors.street)}
        placeholder={t('placeholder_street')}
        onKeyDown={(e) => handleKeyDown(e, FormFields.zipCode)}
        {...streetRest}
        inputRef={streetRef}
      />
      <InputFieldComponent
        label={t('zip_code')}
        type="text"
        aria-label="zip-code"
        hasError={!!errors.zipCode}
        errorText={getErrorText(errors.zipCode)}
        placeholder={t('placeholder_zip_code')}
        onKeyDown={(e) => handleKeyDown(e, FormFields.houseNumber)}
        {...zipCodeRest}
        inputRef={zipCodeRef}
      />
      <HouseNumberContainer>
        <InputFieldComponent
          label={t('house_number')}
          type="text"
          aria-label="house-number"
          hasError={!!errors.houseNumber}
          errorText={getErrorText(errors.houseNumber)}
          placeholder={t('placeholder_house_number')}
          onKeyDown={(e) => handleKeyDown(e, FormFields.addition)}
          {...houseNumberRest}
          inputRef={houseNumberRef}
        />
        <InputFieldComponent
          label={t('addition')}
          type="text"
          aria-label="addition"
          hasError={!!errors.addition}
          errorText={getErrorText(errors.addition)}
          placeholder={t('placeholder_addition')}
          onKeyDown={(e) => handleKeyDown(e, FormFields.city)}
          {...additionRest}
          inputRef={additionRef}
        />
      </HouseNumberContainer>
      <InputFieldComponent
        label={t('city')}
        type="text"
        aria-label="city"
        hasError={!!errors.city}
        errorText={getErrorText(errors.city)}
        placeholder={t('placeholder_city')}
        {...cityRest}
        inputRef={cityRef}
      />
    </div>
  );
};

const HouseNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
