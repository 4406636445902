import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Messages } from '../../global.d';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CloseIcon } from '../Icons/CloseIcon';

type Props = {
  onCancelClick: () => void;
  titleKey: keyof Messages;
};

export const ModalCardHeader = ({ onCancelClick, titleKey }: Props) => {
  const t = useTranslations();
  return (
    <>
      <HeaderText>{t(titleKey)}</HeaderText>
      <CloseContainer onClick={onCancelClick}>
        <CloseIcon />
      </CloseContainer>
    </>
  );
};
const HeaderText = styled.h1`
  margin: 0;
  text-align: center;
  width: 100%;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const CloseContainer = styled.div`
  cursor: pointer;
`;
