import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { Messages } from '../../../global.d';
import { Subject, SubjectCategory } from '../../../models/Subject';
import { UserRole } from '../../../models/UserRole';
import { PrimaryButton } from '../../common/Button';
import { CardBase, FooterAlignment } from '../../common/CardBase';
import { ProgressBar } from '../../ProgressBar';
import { CardHeader } from '../CardHeader';

import { SelectGroupRow } from './SelectGroupRow';

const MIN_SUBJECT_NUMBER = 3;

type SubjectGroup = {
  titleKey: keyof Messages;
  subjects: Subject[] | undefined;
  category: SubjectCategory;
};

type Props = {
  courseLevelEncoded: string;
  onContinue: () => void;
  onBack: () => void;
  openDifferentCourseLevelSubjectSelection: (category: SubjectCategory) => void;
  role: UserRole;
  subjects: Subject[];
  selectedSubjects: Subject[];
  onSubjectSelect: (subject: Subject) => void;
  progress?: number;
  previousProgress?: number;
};

export const SelectSubjectsCard = ({
  courseLevelEncoded,
  onContinue,
  onBack,
  openDifferentCourseLevelSubjectSelection,
  role,
  subjects,
  selectedSubjects,
  onSubjectSelect,
  progress,
  previousProgress,
}: Props) => {
  const t = useTranslations();

  const subjectOnOtherLevel = selectedSubjects.filter(
    (subject) => subject.courseLevel !== courseLevelEncoded
  );

  const filterSubjects = (category: SubjectCategory) => {
    return subjects.filter((subject) => subject.category === category);
  };

  const allSubjects: SubjectGroup[] = [
    {
      titleKey: 'subjects_languages',
      subjects: filterSubjects(SubjectCategory.Languages),
      category: SubjectCategory.Languages,
    },
    {
      titleKey: 'subjects_exact',
      subjects: filterSubjects(SubjectCategory.Exact),
      category: SubjectCategory.Exact,
    },
    {
      titleKey: 'subjects_social',
      subjects: filterSubjects(SubjectCategory.Social),
      category: SubjectCategory.Social,
    },
    {
      titleKey: 'subjects_extra',
      subjects: filterSubjects(SubjectCategory.Extra),
      category: SubjectCategory.Extra,
    },
  ];

  const isContinueButtonDisabled = () => {
    if (role === UserRole.parentCaretaker || role === UserRole.student) {
      return selectedSubjects.length < MIN_SUBJECT_NUMBER;
    }
    return !selectedSubjects.length;
  };

  return (
    <StyledCardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <CardHeader
          title={t(`select_your_subjects_${role}`, {
            courseLevel: courseLevelEncoded,
          })}
          onBack={onBack}
        />
      }
      footerButtons={
        <PrimaryButton
          buttonSize="md"
          onClick={onContinue}
          disabled={isContinueButtonDisabled()}
        >
          {t('continue_alt')}
        </PrimaryButton>
      }
      footerAlignment={FooterAlignment.right}
      fullHeightBody={true}
    >
      <ProgressBarContainer>
        {progress && previousProgress && (
          <ProgressBar
            progress={progress}
            roundedCorners={false}
            initialWidth={previousProgress}
          />
        )}
      </ProgressBarContainer>

      <ScrollContainer>
        {allSubjects.map((subjectGroup) => {
          return (
            <SelectGroupRow
              key={subjectGroup.titleKey}
              role={role}
              selectedSubjects={selectedSubjects}
              subjectGroup={subjectGroup}
              subjectOnOtherLevel={subjectOnOtherLevel}
              onSubjectSelect={onSubjectSelect}
              onExtraSubjectClick={() =>
                openDifferentCourseLevelSubjectSelection(subjectGroup.category)
              }
            />
          );
        })}
      </ScrollContainer>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: 100vh;
  overflow-y: auto;
  position: relative;

  @media ${HookMediaQueries.isDesktop} {
    margin-top: 24px;
    height: 80vh;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  padding: 20px;
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  width: 100%;
`;
