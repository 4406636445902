import styled from '@emotion/styled';

import { HookMediaQueries } from '../../constants/MediaQueries';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { ArrowBackIcon } from '../Icons/ArrowBackIcon';

type CardHeaderProps = {
  title: string;
  onBack?: () => void;
};

export const CardHeader = ({ title, onBack }: CardHeaderProps) => {
  return (
    <Container>
      {!!onBack && <StyledArrowBackIcon onClick={onBack} />}
      <Title>{title}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: env(safe-area-inset-top);

  @media ${HookMediaQueries.isDesktop} {
    padding-top: 0;
  }
`;

const Title = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;
