import styled from '@emotion/styled';

import { ModalTypes, useModal } from '../../../hooks/useModal';
import { FlagIcon } from '../../Icons/FlagIcon';

type Props = {
  thesisId: string;
};

export const ReportQuestion = ({ thesisId }: Props) => {
  const { showModal } = useModal();

  const onFlagClick = () => {
    showModal({
      type: ModalTypes.FlagQuestionModal,
      data: {
        thesisId,
      },
    });
  };

  return (
    <IconWrapper onClick={onFlagClick}>
      <FlagIcon />
    </IconWrapper>
  );
};
const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  padding-right: 20px;
  cursor: pointer;
`;
