import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Slices } from '../Slices';

const initialState = {
  type: '',
  data: null,
  modalActive: false,
};

const modalSlice = createSlice({
  name: Slices.Modal,
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        modalActive: true,
      };
    },
    dismissModal(state) {
      return {
        ...state,
        modalActive: false,
      };
    },
    clearModal() {
      return {
        type: '',
        data: null,
        modalActive: false,
      };
    },
  },
});

export const { showModal, dismissModal, clearModal } = modalSlice.actions;

export default modalSlice.reducer;
