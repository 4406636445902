import { NavigateOptions, useNavigate } from 'react-router-dom';

import { appRoutes } from '../constants/routes';
import { usePostLogoutMutation } from '../services/userApi';
import { useAppDispatch } from '../store/hooks';
import { store } from '../store/store';
import { clearSessionData } from '../utils/logoutUtils';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [postLogoutTrigger] = usePostLogoutMutation();

  const logout = async (options?: NavigateOptions) => {
    await postLogoutTrigger();
    clearSessionData(dispatch, store.getState);
    localStorage.removeItem('token');
    navigate(appRoutes.Login, options);
  };

  return { logout };
};
