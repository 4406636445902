import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useLogout } from '../../hooks/useLogout';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type Props = {
  onClose: () => void;
};

export const ProfileMenu = ({ onClose }: Props) => {
  const t = useTranslations();
  const { logout } = useLogout();
  const navigate = useNavigate();

  const onLogoutClick = () => {
    logout();
    onClose();
  };

  const onProfileClick = () => {
    navigate('/profile');
    onClose();
  };

  return (
    <ProfileOptions>
      <Option onClick={onProfileClick}>{t('profile')}</Option>
      <Option onClick={onLogoutClick}>{t('logout')}</Option>
    </ProfileOptions>
  );
};
const ProfileOptions = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background-color: ${Colors.modalBackgroundColor};
  box-shadow: 0px 2px 2px 0px ${Colors.shadowColor};
  border-radius: 8px;
  border: 1px solid ${Colors.dividerColor};
`;
const Option = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
  padding: 10px;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: ${Colors.outlineBackgroundButton};
  }
`;
