import styled from '@emotion/styled';

import LockedBadgeImage from '../../assets/images/badge-locked.png';
import { MediaQueries } from '../../constants/MediaQueries';
import { UserBadgeWithIcon } from '../../models/Badge';
import { useGetBadgesQuery } from '../../services/userApi';

import { BadgeComponent } from './BadgeComponent';

export const Badges = () => {
  const { data: badges } = useGetBadgesQuery();

  const filteredBadges = badges?.reduce((acc, curr) => {
    const alreadyIncludedBadge = acc.find(
      (item) => item.badge.name === curr.badge.name
    );
    if (alreadyIncludedBadge) {
      // this condition assumes the different badge tiers come ordered by ascending importance from the backend
      if (curr.isUnlocked || alreadyIncludedBadge.isUnlocked) {
        return [
          ...acc.filter(
            (item) => item.badge.name !== alreadyIncludedBadge.badge.name
          ),
          {
            ...curr,
            icon: curr.isUnlocked
              ? curr.badge.icon
              : alreadyIncludedBadge.badge.icon,
          },
        ];
      }

      return acc;
    }
    return [
      ...acc,
      {
        ...curr,
        icon: curr.isUnlocked ? curr.badge.icon : LockedBadgeImage,
      },
    ] as UserBadgeWithIcon[];
  }, [] as UserBadgeWithIcon[]);

  return (
    <Container>
      {filteredBadges?.map((badge) => (
        <BadgeComponent key={badge._id} badge={badge} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0px;
  @media (max-width: ${MediaQueries.desktop}) {
    padding-top: 62px;
  }
`;
