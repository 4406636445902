import { z, ZodType } from 'zod';

export type FormData = {
  email: string;
};

export const enum FormFields {
  email = 'email',
}

export const ForgotPasswordSchema: ZodType<FormData> = z.object({
  email: z.string().email({ message: 'no_valid_email' }),
});
