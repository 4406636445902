import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

export const SelectableItemContainer = ({ children }: PropsWithChildren) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 320px), 1fr));
  gap: 16px;
`;
