import { Dispatch } from '@reduxjs/toolkit';

import { clearSocialLoginProvider, clearUser } from '../store/slices/userSlice';
import { RootState } from '../store/store';

import { logoutSocial } from './socialLoginUtils';

export const clearSessionData = (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const userState = getState().user;

  if (userState.socialLoginProvider) {
    logoutSocial(userState.socialLoginProvider);
    dispatch(clearSocialLoginProvider());
  }

  dispatch(clearUser());
};
