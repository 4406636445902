import styled from '@emotion/styled';

import { UserSubject } from '../../../models/Subject';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SubjectImage } from '../../common/SubjectImage';

type Props = {
  userSubject: UserSubject;
};

export const SubjectRow = ({ userSubject }: Props) => {
  return (
    <>
      <Row>
        <StyledSubjectImage src={userSubject.subject.subjectImage} />

        <RowTextWrapper>
          <Title>{userSubject.subject.name}</Title>
          <Subtitle>{userSubject.subject.courseLevel}</Subtitle>
        </RowTextWrapper>
      </Row>
      <Divider />
    </>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
`;
const StyledSubjectImage = styled(SubjectImage)`
  width: 48px;
  height: 48px;
`;
const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Title = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;

const Subtitle = styled.div`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;

const Divider = styled.div`
  background-color: ${Colors.dividerColor};
  height: 1px;
  width: 100%;
`;
