import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Messages } from '../../../global.d';
import { Subject, SubjectCategory } from '../../../models/Subject';
import { UserRole } from '../../../models/UserRole';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { SubjectImage } from '../../common/SubjectImage';
import { SelectableItem } from '../SelectableItem';
import { SelectableItemContainer } from '../SelectableItemContainer';

import { ExtraSubjectSelectableItem } from './ExtraSubjectSelectableItem';

type SubjectGroup = {
  titleKey: keyof Messages;
  subjects: Subject[] | undefined;
  category: SubjectCategory;
};

type Props = {
  role: UserRole;
  selectedSubjects: Subject[];
  onSubjectSelect: (subject: Subject) => void;
  subjectGroup: SubjectGroup;
  subjectOnOtherLevel: Subject[];
  onExtraSubjectClick: () => void;
};

export const SelectGroupRow = ({
  role,
  selectedSubjects,
  onSubjectSelect,
  subjectGroup,
  subjectOnOtherLevel,
  onExtraSubjectClick,
}: Props) => {
  const t = useTranslations();

  if (!subjectGroup.subjects?.length) {
    return <div key={subjectGroup.titleKey + 'empty'}></div>;
  }

  return (
    <Container key={subjectGroup.titleKey}>
      <Header>{t(subjectGroup.titleKey)}</Header>
      <SelectableItemContainer>
        {subjectGroup.subjects?.map((subject) => (
          <SelectableItem
            key={subject.encodedName}
            text={subject.name}
            icon={<StyledSubjectImage src={subject?.subjectImage} />}
            onClick={() => onSubjectSelect(subject)}
            active={selectedSubjects.some(
              (selectedSubject) =>
                selectedSubject.encodedName === subject.encodedName
            )}
          />
        ))}
        {role !== UserRole.teacher &&
          subjectOnOtherLevel
            .filter((subject) => subject.category === subjectGroup.category)
            .map((subject) => (
              <SelectableItem
                key={subject.encodedName}
                text={subject.name}
                courseLevel={subject.courseLevel}
                icon={<StyledSubjectImage src={subject?.subjectImage} />}
                onClick={() => onSubjectSelect(subject)}
                active={subjectOnOtherLevel.some(
                  (selectedSubject) =>
                    selectedSubject.encodedName === subject.encodedName
                )}
              />
            ))}
        {role !== UserRole.teacher && (
          <ExtraSubjectSelectableItem
            key={subjectGroup.titleKey}
            onSelect={onExtraSubjectClick}
          />
        )}
      </SelectableItemContainer>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 24px;
`;

const Header = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
  padding: 16px 0;
`;

const StyledSubjectImage = styled(SubjectImage)`
  width: 40px;
  height: 40px;
`;
