import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../../constants/routes';
import { useError } from '../../../hooks/useError';
import { useUserManagement } from '../../../hooks/useUserManagement';
import { Token } from '../../../models/User';
import { RegisterSocialUserBody } from '../../../models/user-management/RegisterSocialUserBody';
import { UserRole } from '../../../models/UserRole';
import { usePatchSocialUserMutation } from '../../../services/dashboardApi';
import { useRegisterMutation } from '../../../services/onboardingApi';
import { useAppDispatch } from '../../../store/hooks';
import { clearUserManagementState } from '../../../store/slices/userManagementSlice';
import { setNewUser, setToken } from '../../../store/slices/userSlice';

export const useRegister = () => {
  const [registerTrigger] = useRegisterMutation();
  const [patchSocialUserTrigger] = usePatchSocialUserMutation();
  const { showErrorModal } = useError();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userManagementState = useUserManagement();
  const isSocialUser = !!userManagementState.socialUser;

  const register = async () => {
    if (isSocialUser) {
      registerSocialUser();
    } else {
      registerUser();
    }
  };

  const registerSocialUser = async () => {
    try {
      await patchSocialUserTrigger(
        userManagementState as RegisterSocialUserBody
      ).unwrap();

      onSuccessfulRegistration();
    } catch (error) {
      showErrorModal(error as Error);
    }
  };

  const registerUser = async () => {
    try {
      await registerTrigger(userManagementState).unwrap();
      handleNavigation(appRoutes.OnboardingEmailVerification, true);
    } catch (error) {
      showErrorModal(error as Error);
    }
  };

  const onSuccessfulRegistration = (token?: Token) => {
    if (token) {
      dispatch(setToken(token));
    }

    dispatch(setNewUser());

    if (userManagementState.accountType === UserRole.teacher) {
      handleNavigation(appRoutes.TeacherDashboard, true);
    } else {
      handleNavigation(appRoutes.OnboardingLinkProduct, false);
    }
    dispatch(clearUserManagementState());
  };

  const handleNavigation = (route: string, useWindowLocation = false) => {
    if (useWindowLocation) {
      // disable navigating back to the onboarding flow after registration
      window.history.pushState({}, '', appRoutes.Login);
      window.location.href = route;
    } else {
      navigate(route);
    }
  };

  return {
    register,
    onSuccessfulRegistration,
  };
};
