import { IconProps, Icon } from './Icon';

export const StarIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M16.606 1.77783C16.3116 1.16672 15.6894 0.777832 15.006 0.777832C14.3227 0.777832 13.706 1.16672 13.406 1.77783L9.83382 9.12783L1.85604 10.3056C1.18938 10.4056 0.633822 10.8723 0.428266 11.5112C0.22271 12.1501 0.389377 12.8556 0.867155 13.3278L6.65604 19.0556L5.28938 27.1501C5.17827 27.8167 5.45604 28.4945 6.00604 28.8889C6.55604 29.2834 7.28382 29.3334 7.88382 29.0167L15.0116 25.2112L22.1394 29.0167C22.7394 29.3334 23.4672 29.2889 24.0172 28.8889C24.5672 28.4889 24.8449 27.8167 24.7338 27.1501L23.3616 19.0556L29.1505 13.3278C29.6283 12.8556 29.8005 12.1501 29.5894 11.5112C29.3783 10.8723 28.8283 10.4056 28.1616 10.3056L20.1783 9.12783L16.606 1.77783Z"
      fill={color}
    />
  </Icon>
);
