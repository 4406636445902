import styled from '@emotion/styled';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  title?: string;
  subTitle: string;
  padding?: boolean;
};

export const SubCategoryListHeader = ({
  title,
  subTitle,
  padding = true,
}: Props) => {
  return (
    <TitleWrapper padding={padding}>
      {title && <Title>{title}</Title>}
      <SubTitle>{subTitle}</SubTitle>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div<{ padding: boolean }>`
  ${({ padding }) => (padding ? 'padding: 5px 20px;' : 'padding: 0px;')}
  background-color: ${Colors.inputBackgroundColor};
  border-top: 1px solid ${Colors.dividerColor};
  border-bottom: 1px solid ${Colors.dividerColor};
`;
const Title = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const SubTitle = styled.h1`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.CardSubtitleText}
`;
