import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';

import { ProductBundle } from '../../models/ProductBundle';
import { useLazyGetUserProductsQuery } from '../../services/userApi';
import { useAppDispatch } from '../../store/hooks';
import { deleteFromCart } from '../../store/slices/cartSlice';
import { RootState } from '../../store/store';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { setCoverUrlToSummary } from '../../utils/apiUtils';
import { CardType, CartItem } from '../CartItem';
import { CardBase } from '../common/CardBase';

export const CartItemCard = () => {
  const t = useTranslations();
  const [getUserProducts] = useLazyGetUserProductsQuery();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const dispatch = useAppDispatch();

  const onRemoveItem = (product: ProductBundle) => {
    dispatch(deleteFromCart(product));
  };

  const alreadyBoughtCheck = async () => {
    try {
      const userProductsResult = await getUserProducts();
      const purchasedProductIds = new Set(
        userProductsResult?.data?.map((product) => product._id)
      );

      const alreadyBoughtItems = cartItems.filter((cartItem) =>
        cartItem.productIds?.some((productId) =>
          purchasedProductIds.has(productId)
        )
      );

      alreadyBoughtItems?.forEach((product) => {
        dispatch(deleteFromCart(product));
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    alreadyBoughtCheck();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        alreadyBoughtCheck();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <CardBase header={<Title>{t('your_order')}</Title>} fullWidth={true}>
      {cartItems.map((product: ProductBundle) => {
        return (
          <CartItem
            key={product._id}
            image={setCoverUrlToSummary(product.cover)}
            title={product.description}
            subtitle={`${product.name} ${product.courseLevels?.[0] || ''}`}
            price={`€${product.price}`}
            onPress={() => onRemoveItem(product)}
            cardType={CardType.removeFromCart}
          />
        );
      })}
      {cartItems.length === 0 && (
        <EmptyCartText>{t('empty_cart')}</EmptyCartText>
      )}
    </CardBase>
  );
};

const Title = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;

const EmptyCartText = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemBody}
`;
