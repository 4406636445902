import { z } from 'zod';

export type FormData = z.infer<typeof DiscountFormSchema>;

export enum FormFields {
  discountCode = 'discountCode',
}

export const DiscountFormSchema = z.object({
  discountCode: z.string().min(1, { message: 'no_valid_discount_code' }),
});
