import styled from '@emotion/styled';
import { useRef, useState } from 'react';

import { Category, SubCategory } from '../../../models/Definition';

import { useScrollToSubCategory } from './hooks/useScrollToSubCategory';
import useVisibleCategoryObserver from './hooks/useVisibleCategoryObserver';
import { SubCategoryList } from './SubCategoryList';

type Props = {
  categories: Category[];
  selectedSubCategory?: SubCategory;
  onChangeVisibleCategory: (categoryId: string) => void;
};

export const CategoryList = ({
  categories,
  selectedSubCategory,
  onChangeVisibleCategory,
}: Props) => {
  const subCategoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [previousSelectedSubCategory, setPreviousSelectedSubCategory] =
    useState<SubCategory | null>();

  const scrollToSubCategory = useScrollToSubCategory(subCategoryRefs);

  useVisibleCategoryObserver(
    subCategoryRefs,
    onChangeVisibleCategory,
    categories
  );

  if (previousSelectedSubCategory !== selectedSubCategory) {
    setPreviousSelectedSubCategory(selectedSubCategory);
    if (selectedSubCategory) scrollToSubCategory(selectedSubCategory);
  }

  const isFirstCategory = (category: Category) => categories[0] === category;

  const refHandler = (el: HTMLDivElement | null, subCategoryId: string) => {
    subCategoryRefs.current[subCategoryId] = el;
  };

  return (
    <>
      {categories?.map((category) => (
        <CategoryContainer key={category._id}>
          <SubCategoryList
            category={category}
            disableFirstHeader={isFirstCategory(category)}
            refHandler={refHandler}
          />
        </CategoryContainer>
      ))}
    </>
  );
};

const CategoryContainer = styled.div``;
