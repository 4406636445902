import { ApiTagTypes } from '../constants/api';
import { Results } from '../models/Results';
import { AnswerResponse } from '../models/training/AnswerResponse';
import { Thesis } from '../models/training/Thesis';
import { ThesisCategory } from '../models/training/ThesisCategory';
import { Training } from '../models/training/training';
import { TrainingResults } from '../models/training/trainingResults';
import { UserAnswer } from '../models/training/UserAnswer';

import { baseApi } from './baseApi';

export const trainingApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [
      ApiTagTypes.Results,
      ApiTagTypes.Training,
      ApiTagTypes.Thesis,
      ApiTagTypes.Summary,
      ApiTagTypes.Sections,
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getLastResults: build.query<TrainingResults[], string>({
        query: (encodedSubjectName) => ({
          url: `/subjects/${encodedSubjectName}/training/last-results`,
        }),
        providesTags: [ApiTagTypes.Training],
      }),
      getTrainingProgress: build.query<Training, string>({
        query: (encodedSubjectName) => ({
          url: `/training/${encodedSubjectName}`,
        }),
        providesTags: [ApiTagTypes.Training],
      }),
      getThesisCategories: build.query<ThesisCategory[], void>({
        query: () => ({
          url: `/thesis-categories`,
        }),
      }),
      createTraining: build.mutation<
        Training,
        { encodedSubjectName: string; params: any }
      >({
        query: (props) => ({
          url: `/training/${props.encodedSubjectName}`,
          params: props.params,
          method: 'POST',
        }),
      }),
      getThesisById: build.query<Thesis, string>({
        query: (thesisId) => ({
          url: `/thesis/${thesisId}`,
        }),
      }),
      postAnswer: build.mutation<
        AnswerResponse,
        { questionId: string; body: UserAnswer }
      >({
        query: (props) => ({
          url: `/question/${props.questionId}/answers`,
          body: props.body,
          method: 'POST',
        }),
      }),
      postRating: build.mutation<
        void,
        { thesisId: string; body: { rating: number; message: string } }
      >({
        query: (props) => ({
          url: `/thesis/${props.thesisId}/rating`,
          body: props.body,
          method: 'POST',
        }),
      }),
      trainingResults: build.mutation<Results, { encodedSubjectName: string }>({
        query: ({ encodedSubjectName }) => ({
          url: `/subjects/${encodedSubjectName}/training/results`,
          method: 'POST',
        }),
        invalidatesTags: [
          ApiTagTypes.Summary,
          ApiTagTypes.Sections,
          ApiTagTypes.Training,
        ],
      }),
      flagQuestion: build.mutation<
        void,
        {
          thesisId: string;
          body: { explanation: string; reasons: string[] };
        }
      >({
        query: (props) => ({
          url: `/thesis/${props.thesisId}/report`,
          body: props.body,
          method: 'POST',
        }),
      }),
    }),
  });

export const {
  useGetLastResultsQuery,
  useGetTrainingProgressQuery,
  useLazyGetTrainingProgressQuery,
  useGetThesisCategoriesQuery,
  useCreateTrainingMutation,
  useGetThesisByIdQuery,
  usePostAnswerMutation,
  usePostRatingMutation,
  useTrainingResultsMutation,
  useFlagQuestionMutation,
} = trainingApi;
