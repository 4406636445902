import styled from '@emotion/styled';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { DateFormat } from '../../../utils/dateUtils';

type Props = {
  lastLogin: Date;
};

export const LastLogin = ({ lastLogin }: Props) => {
  const t = useTranslations();
  const formattedDate = format(lastLogin, DateFormat.fullDateWithTime, {
    locale: nl,
  });

  return (
    <LastLoginText>{`${t('last_login')}: ${formattedDate}`}</LastLoginText>
  );
};

const LastLoginText = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemBody}
`;
