import { AnswerState } from './answerState';
import { UserAnswer } from './UserAnswer';

export type Question = {
  _id: string;
  title: string;
  explanation?: string;
  hint: string;
  introduction: string;
  translation?: string;

  state?: AnswerState;

  userAnswer?: UserAnswer;
  scoredPoints?: number;
  totalPoints?: number;
  answerInput?: string;
  openQuestionIsAnswered?: boolean;

  isAnswered?: boolean;
  selectedChoice?: string;
  assistState?: string;
  usedAssist?: boolean;

  isCorrect?: boolean;

  startDate?: string;
  endDate?: string;

  choices: string[];
  correctAnswer: string;
  type: string;

  pointsExplanation: string;
  resultTitle: string;

  submitted: boolean;

  questionId: string;
  questionCount: number;
  questionsAnsweredCount: number;
};

export const enum QuestionType {
  Dictation = 'Dictation',
  Open = 'OpenQuestion',
  MultipleChoice = 'MultipleChoice',
}
