import styled from '@emotion/styled';
import DOMPurify from 'dompurify';

import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';

type Props = {
  title: string;
  position: number;
  color: string;
  onClick: () => void;
};

const UPPERCASE_A_CHAR_CODE = 65;

export const MultipleChoiceOption = ({
  title,
  position,
  onClick,
  color,
}: Props) => {
  const letter = String.fromCharCode(position + UPPERCASE_A_CHAR_CODE);

  return (
    <Container onClick={onClick} color={color}>
      <Title>{letter}.</Title>
      <Title
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(title),
        }}
      />
    </Container>
  );
};

const Container = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
  gap: 12px;
  color: ${({ color }) =>
    color
      ? Colors.primaryButtonContentColor
      : Colors.secondaryButtonContentColor};
  background-color: ${({ color }) =>
    color ? color : Colors.secondaryButtonFill};
`;
const Title = styled.h1`
  margin: 0;
  ${TextStyles.ButtonTextLargeText}
`;
