import { useState, useEffect, useRef } from 'react';

import { useError } from '../../../../hooks/useError';
import { Chapter, Section } from '../../../../models/Summary/summary';
import { usePutReadStatusMutation } from '../../../../services/summaryApi';

import useBottomOfPage from './useBottomOfPage';

const SECONDS_PER_WORD = 0.075;
const SECONDS_TO_MILLISECONDS = 1000;
const PAGE_SCROLL_CHECK_AFTER_X_MS = 2000;
const regexForStripSVG = /<path(?:\s[^>]*?)?\/?>/gi;
const regexForHTMLTags = /<\/?[a-zA-Z]+(?:\s[^>]*?)?>/g;

export const useReadTracker = () => {
  const [updateReadStatus] = usePutReadStatusMutation();
  const { showErrorModal } = useError();
  const [doneScrolling, setDoneScrolling] = useState(false);
  const [timerDone, setTimerDone] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [chapter, setChapter] = useState<Chapter | undefined>(undefined);
  const [section, setSection] = useState<Section | undefined>(undefined);

  const handleBottomOfPage = () => {
    setDoneScrolling(true);
  };

  const { isPageScrollable } = useBottomOfPage({ handleBottomOfPage });

  const getTimeItTakesToReadInSeconds = (text: string) => {
    if (!text) return 0;

    text = text.replace(regexForStripSVG, '');
    text = text.replace(regexForHTMLTags, '');

    const requiredTimeForTextInSeconds =
      text.split(' ').length * SECONDS_PER_WORD;
    return requiredTimeForTextInSeconds;
  };

  const getRequiredReadingTimeInMS = (chapter: Chapter, section: Section) => {
    let readingTime = 0;

    readingTime += getTimeItTakesToReadInSeconds(chapter.encodedChapterName);
    readingTime += getTimeItTakesToReadInSeconds(section.encodedSectionName);

    section.topics.forEach((topic) => {
      readingTime += getTimeItTakesToReadInSeconds(topic.html);
    });

    return readingTime * SECONDS_TO_MILLISECONDS;
  };

  const startTracking = (chapter: Chapter, section: Section) => {
    reset();
    scrollCheck();

    setChapter(chapter);
    setSection(section);

    const requiredReadTimeInMS = getRequiredReadingTimeInMS(chapter, section);

    timerRef.current = setTimeout(() => {
      setTimerDone(true);
    }, requiredReadTimeInMS);
  };

  const scrollCheck = () => {
    setTimeout(() => {
      if (isPageScrollable()) {
        handleBottomOfPage();
      }
    }, PAGE_SCROLL_CHECK_AFTER_X_MS);
  };

  const completedCheck = () => {
    if (timerDone && doneScrolling) {
      completeReading();
    }
  };

  const completeReading = async () => {
    if (!chapter || !section) return;

    try {
      await updateReadStatus({
        encodedSubjectName: chapter.encodedSubjectName,
        encodedChapterName: chapter.encodedChapterName,
        encodedSectionName: section.encodedSectionName,
        body: { read: true },
      });
    } catch (error) {
      showErrorModal(error);
    }
    reset();
  };

  useEffect(() => {
    completedCheck();
  }, [timerDone, doneScrolling]);

  const reset = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setTimerDone(false);
    setDoneScrolling(false);
  };

  return {
    startTracking,
  };
};
