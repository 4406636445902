import styled from '@emotion/styled';

import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';
import { secondsToMMss } from '../../../../utils/dateUtils';

type Props = {
  imgUrl: string;
  title: string;
  time: number;
  subjectColor: string;
  active: boolean;
  direction: 'row' | 'column';
  onClick: () => void;
};

export const VideoCard = ({
  imgUrl,
  title,
  time,
  subjectColor,
  active,
  direction,
  onClick,
}: Props) => {
  const duration = secondsToMMss(time);

  return (
    <Container
      onClick={onClick}
      color={active ? subjectColor : undefined}
      direction={direction}
    >
      <Thumbnail src={imgUrl} direction={direction} />
      <TextContainer active={active}>
        <Title>{title}</Title>
        <Time>{duration}</Time>
      </TextContainer>
    </Container>
  );
};
const Container = styled.div<{ color: string | undefined; direction: string }>`
  flex: 1;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;

  ${({ color }) => color && `background-color: ${color};`};

  ${({ direction }) =>
    direction === 'row'
      ? `  
    flex-direction: row;
    gap: 12px;
    `
      : `
    flex-direction: column;
    gap: 8px;
    `};
`;
const Title = styled.h1`
  margin: 0;
  ${TextStyles.VideoCardTitle}
`;
const Thumbnail = styled.img<{ direction: string }>`
  border-radius: 4px;
  height: ${({ direction }) => (direction === 'row' ? `72px` : `130px`)};
  aspect-ratio: 16/9;
`;
const TextContainer = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${({ active }) =>
    active ? Colors.primaryTextInverted : Colors.primaryTextColor};
`;
const Time = styled.div`
  ${TextStyles.VideoCardTime}
`;
