import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { PrimaryButton, SecondaryButton } from '../../common/Button';

type LoginFooterProps = {
  onCreateAccountClick: () => void;
  onLoginClick?: () => void;
  disabled: boolean;
};

export const LoginFooter = ({
  onCreateAccountClick,
  disabled,
}: LoginFooterProps) => {
  const t = useTranslations();
  return (
    <>
      <StyledSecondaryButton
        buttonSize="md"
        onClick={onCreateAccountClick}
        type="button"
      >
        {t('create_new_account')}
      </StyledSecondaryButton>
      <PrimaryButton
        type="submit"
        buttonSize="md"
        disabled={disabled}
        aria-label="login"
      >
        {t('login')}
      </PrimaryButton>
    </>
  );
};

const StyledSecondaryButton = styled(SecondaryButton)`
  text-wrap: nowrap;
`;
