import { IconProps, Icon } from './Icon';

export const CartIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M0.666667 0.888672C0.297222 0.888672 0 1.18589 0 1.55534C0 1.92478 0.297222 2.22201 0.666667 2.22201H1.93056C2.03611 2.22201 2.12778 2.29701 2.15 2.40256L3.58333 9.93034C3.76389 10.8748 4.58889 11.5553 5.54722 11.5553H13.5556C13.925 11.5553 14.2222 11.2581 14.2222 10.8887C14.2222 10.5192 13.925 10.222 13.5556 10.222H5.54722C5.22778 10.222 4.95278 9.99423 4.89167 9.68034L4.74167 8.88867H12.7556C13.6611 8.88867 14.4528 8.28312 14.6861 7.40812L15.825 3.17756C16.0167 2.47201 15.4833 1.77756 14.7528 1.77756H3.33611C3.08333 1.24423 2.54444 0.888672 1.93056 0.888672H0.666667ZM3.64167 3.11089H14.4639L13.4 7.06089C13.3222 7.35256 13.0583 7.55534 12.7556 7.55534H4.48889L3.64167 3.11089ZM4.88889 15.1109C5.24251 15.1109 5.58165 14.9704 5.8317 14.7204C6.08175 14.4703 6.22222 14.1312 6.22222 13.7776C6.22222 13.4239 6.08175 13.0848 5.8317 12.8348C5.58165 12.5847 5.24251 12.4442 4.88889 12.4442C4.53527 12.4442 4.19613 12.5847 3.94608 12.8348C3.69603 13.0848 3.55556 13.4239 3.55556 13.7776C3.55556 14.1312 3.69603 14.4703 3.94608 14.7204C4.19613 14.9704 4.53527 15.1109 4.88889 15.1109ZM14.2222 13.7776C14.2222 13.4239 14.0817 13.0848 13.8317 12.8348C13.5816 12.5847 13.2425 12.4442 12.8889 12.4442C12.5353 12.4442 12.1961 12.5847 11.9461 12.8348C11.696 13.0848 11.5556 13.4239 11.5556 13.7776C11.5556 14.1312 11.696 14.4703 11.9461 14.7204C12.1961 14.9704 12.5353 15.1109 12.8889 15.1109C13.2425 15.1109 13.5816 14.9704 13.8317 14.7204C14.0817 14.4703 14.2222 14.1312 14.2222 13.7776Z"
      fill={color}
    />
  </Icon>
);
