import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT } from '../../constants/Sizes';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CardBase } from '../common/CardBase';
import { NoContentIcon } from '../Icons/NoContentIcon';

export const NoContentCard = () => {
  const t = useTranslations();
  return (
    <StyledCardBase fullWidth={true}>
      <Container>
        <NoContentIcon color={Colors.primaryIconColor} size="xl" />
        <Text>{t('no_content')}</Text>
      </Container>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 20px 0px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - ${HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT});
`;
const Text = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
