import { IconProps, Icon } from './Icon';

export const NoContentIcon = ({ size, color }: IconProps) => (
  <Icon size={size} color={color}>
    <path
      d="M20 3.75C24.3098 3.75 28.443 5.46205 31.4905 8.50951C34.5379 11.557 36.25 15.6902 36.25 20C36.25 24.3098 34.5379 28.443 31.4905 31.4905C28.443 34.5379 24.3098 36.25 20 36.25C15.6902 36.25 11.557 34.5379 8.50951 31.4905C5.46205 28.443 3.75 24.3098 3.75 20C3.75 15.6902 5.46205 11.557 8.50951 8.50951C11.557 5.46205 15.6902 3.75 20 3.75ZM20 40C25.3043 40 30.3914 37.8929 34.1421 34.1421C37.8929 30.3914 40 25.3043 40 20C40 14.6957 37.8929 9.60859 34.1421 5.85786C30.3914 2.10714 25.3043 0 20 0C14.6957 0 9.60859 2.10714 5.85786 5.85786C2.10714 9.60859 0 14.6957 0 20C0 25.3043 2.10714 30.3914 5.85786 34.1421C9.60859 37.8929 14.6957 40 20 40ZM13.6719 13.6719C12.9375 14.4062 12.9375 15.5937 13.6719 16.3203L17.3438 19.9922L13.6719 23.6641C12.9375 24.3984 12.9375 25.5859 13.6719 26.3125C14.4062 27.0391 15.5937 27.0469 16.3203 26.3125L19.9922 22.6406L23.6641 26.3125C24.3984 27.0469 25.5859 27.0469 26.3125 26.3125C27.0391 25.5781 27.0469 24.3906 26.3125 23.6641L22.6406 19.9922L26.3125 16.3203C27.0469 15.5859 27.0469 14.3984 26.3125 13.6719C25.5781 12.9453 24.3906 12.9375 23.6641 13.6719L19.9922 17.3438L16.3203 13.6719C15.5859 12.9375 14.3984 12.9375 13.6719 13.6719Z"
      fill={color}
    />
  </Icon>
);
