import styled from '@emotion/styled';

import { DevicesCard } from '../../components/profile/DevicesCard';

export const Devices = () => {
  return (
    <Container>
      <ContentContainer>
        <DevicesCard />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ContentContainer = styled.div`
  flex: 1;
`;
