import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetUserBadgesUnlockedCountQuery } from '../../services/dashboardApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CardBase } from '../common/CardBase';
import { SimpleBadgeIcon } from '../Icons/SimpleBadgeIcon';

import { ProfileCardHeader } from './ProfileCardHeader';

export const ProfileBadges = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { data: badgesUnlockedCount } = useGetUserBadgesUnlockedCountQuery();
  return (
    <CardBase
      fullWidth
      header={
        <ProfileCardHeader
          titleTranslationKey="badges"
          buttonTranslationKey="see_all_badges"
          onButtonClick={() => navigate('/profile/badges')}
        />
      }
    >
      <BadgeContentContainer>
        <SimpleBadgeIcon size="xl" />
        <Text>
          {t('you_have_acquired_badges', {
            count: badgesUnlockedCount?.amount || 0,
          })}
        </Text>
      </BadgeContentContainer>
    </CardBase>
  );
};

const BadgeContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyMedium}
  padding: 20px;
  margin: 0;
`;
