import styled from '@emotion/styled';

import { Category, SubCategory } from '../../../models/Definition';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  category: Category;
  visibleSubCategoryId: string;
  handleClick: (subCategory: SubCategory) => void;
};

export const DefinitionChapterIndex = ({
  category,
  visibleSubCategoryId,
  handleClick,
}: Props) => {
  const hasSubCategory = (category: Category) =>
    category.subcategories.length > 0;

  const handleCategoryClick = () => {
    if (hasSubCategory(category)) handleClick(category.subcategories[0]);
  };

  return (
    <TableContent key={category._id}>
      <Title onClick={handleCategoryClick}>{category.name}</Title>
      {category.subcategories?.map((subCategory) => (
        <SubTitle
          active={subCategory._id === visibleSubCategoryId}
          key={subCategory._id}
          onClick={() => handleClick(subCategory)}
        >
          {subCategory.name}
        </SubTitle>
      ))}
    </TableContent>
  );
};

const TableContent = styled.div`
  padding-bottom: 20px;
`;
const Title = styled.h1`
  cursor: pointer;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
const SubTitle = styled.p<{ active: boolean }>`
  cursor: pointer;
  ${({ active }) =>
    active
      ? 'color: ' + Colors.primary + ';'
      : 'color: ' + Colors.primaryTextColor + ';'}
  ${TextStyles.ListItemSubtitle}
`;
