import { z, ZodType } from 'zod';

import { hasForbiddenWords, PASSWORD_REGEX } from '../utils/FormHelper';

export type FormData = {
  email: string;
  password: string;
  repeatPassword: string;
  termsAndConditions: boolean;
};

export enum FormFields {
  email = 'email',
  password = 'password',
  repeatPassword = 'repeatPassword',
  termsAndConditions = 'termsAndConditions',
}

export const RegisterSchema: ZodType<FormData> = z
  .object({
    email: z.string().email({ message: 'no_valid_email' }),
    password: z
      .string()
      .min(8, { message: 'password_should_have_8_characters' })
      .max(72, { message: 'password_max_72_characters' })
      .regex(PASSWORD_REGEX, { message: 'password_pattern' })
      .refine((password) => !hasForbiddenWords(password), {
        message: 'password_forbidden_words',
      }),
    repeatPassword: z.string(),
    termsAndConditions: z.literal<boolean>(true, {
      errorMap: () => ({ message: 'password_accept_terms_and_conditions' }),
    }),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: 'passwords_not_the_same',
    path: ['repeatPassword'],
  });
