import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { useGetVideoOfTheDayQuery } from '../../services/dashboardApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { getYoutubeEmbeddedUrl } from '../../utils/videoUtils';
import { CardBase } from '../common/CardBase';

export const VideoOfTheDayCard = () => {
  const t = useTranslations();
  const { data: video } = useGetVideoOfTheDayQuery();

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <Title>
          {t('video_of_the_day')} ({video?.title})
        </Title>
      }
    >
      {video && (
        <YTiFrame
          src={getYoutubeEmbeddedUrl(video?.link)}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={video.title}
        />
      )}
    </CardBase>
  );
};
const Title = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const YTiFrame = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
  display: block;
`;
