import styled from '@emotion/styled';

import { SubjectRow } from '../../components/common/SubjectRow';
import { NoSubjects } from '../../components/NoSubjects';
import { useGetUserSubjectsQuery } from '../../services/dashboardApi';

export const Subjects = () => {
  const { data: subjects, isLoading } = useGetUserSubjectsQuery();

  if (isLoading) {
    return <></>;
  }

  return (
    <Container>
      {subjects?.map((userSubject) => (
        <SubjectRow userSubject={userSubject} key={userSubject._id} />
      ))}

      {subjects?.length === 0 && <NoSubjects />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
`;
