import { FAQQuestions } from '../../../constants/FAQ';
import { PrimaryHeaderText } from '../../../styles/elements/PrimaryHeaderText';
import { CardBase } from '../../common/CardBase';

import { FAQQuestionComponent } from './FAQQuestionComponent';

type Props = {
  category: string;
  questions: FAQQuestions[];
};
export const FAQCategoryComponent = ({ category, questions }: Props) => {
  return (
    <CardBase
      fullWidth={true}
      header={<PrimaryHeaderText>{category}</PrimaryHeaderText>}
    >
      {questions.map(({ q, a }) => (
        <FAQQuestionComponent key={q} question={q} answer={a} />
      ))}
    </CardBase>
  );
};
