import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type Props = {
  fullBorder?: boolean;
};

export const CardBaseHeader = ({ children }: PropsWithChildren<Props>) => {
  return <CardHeader>{children}</CardHeader>;
};

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${Colors.dividerColor};

  ${TextStyles.CardTitleText}
`;
