import { useMemo } from 'react';
import { browserName, osName } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

export type DeviceInfo = {
  identifier: string;
  description: string;
  platform: string;
  lastLogin?: Date;
};

const STORAGE_KEY_DEVICE_IDENTIFIER = 'deviceIdentifier';

export const useDevice = (): DeviceInfo => {
  const identifier = useMemo(() => {
    const storedIdentifier = localStorage.getItem(
      STORAGE_KEY_DEVICE_IDENTIFIER
    );
    if (storedIdentifier) {
      return storedIdentifier;
    } else {
      const newIdentifier = uuidv4();
      localStorage.setItem(STORAGE_KEY_DEVICE_IDENTIFIER, newIdentifier);
      return newIdentifier;
    }
  }, []);

  const deviceInfo: DeviceInfo = {
    identifier,
    description: browserName,
    platform: osName,
  };

  return deviceInfo;
};
