import { IconProps, Icon } from './Icon';

export const CloseIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M15.9766 5.35156C16.3437 4.98438 16.3437 4.39062 15.9766 4.02734C15.6094 3.66406 15.0156 3.66016 14.6523 4.02734L10.0039 8.67578L5.35156 4.02344C4.98438 3.65625 4.39062 3.65625 4.02734 4.02344C3.66406 4.39062 3.66016 4.98437 4.02734 5.34766L8.67578 9.99609L4.02344 14.6484C3.65625 15.0156 3.65625 15.6094 4.02344 15.9727C4.39062 16.3359 4.98438 16.3398 5.34766 15.9727L9.99609 11.3242L14.6484 15.9766C15.0156 16.3437 15.6094 16.3437 15.9727 15.9766C16.3359 15.6094 16.3398 15.0156 15.9727 14.6523L11.3242 10.0039L15.9766 5.35156Z"
      fill={color}
    />
  </Icon>
);
