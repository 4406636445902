import { signOutFacebook } from '../components/login/social/FacebookLoginProvider';
import { signOutGoogle } from '../components/login/social/GoogleLoginProvider';

export const enum SocialProvider {
  apple = 'apple',
  google = 'google',
  facebook = 'facebook',
}

export const logoutSocial = (socialProvider: SocialProvider) => {
  switch (socialProvider) {
    case SocialProvider.facebook:
      signOutFacebook();
      break;
    case SocialProvider.google:
      signOutGoogle();
      break;
    default:
      break;
  }
};
