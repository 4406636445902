import styled from '@emotion/styled';
import { ComponentProps, PropsWithChildren } from 'react';

import { CardBase, RenderStyle } from '../common/CardBase';

type Props = PropsWithChildren<{
  onSubmit: () => void;
}> &
  ComponentProps<typeof CardBase>;

export const LoginCardBase = ({ children, onSubmit, ...props }: Props) => (
  <StyledForm onSubmit={onSubmit}>
    <CardBase fullWidth={true} renderStyle={RenderStyle.desktop} {...props}>
      {children}
    </CardBase>
  </StyledForm>
);

const StyledForm = styled.form`
  width: 90%;
  max-width: 500px;
`;
