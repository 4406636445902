import { z } from 'zod';

export type FormData = z.infer<typeof EmailVerificationSchema>;

export const enum FormFields {
  Code = 'code',
}

export const EmailVerificationSchema = z.object({
  code: z.string().min(1, { message: 'code_required' }),
});
