import { IconProps, Icon } from './Icon';

export const CrownIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} viewBox="0 0 18 18" color={color}>
    <path
      d="M12.4947 7.4569C13.0642 7.03519 14.3962 6.04817 15.4709 5.25135C15.7339 5.0559 16.1217 5.26187 16.0626 5.56707C15.858 6.63226 15.3642 9.95939 15.0733 11.9507C14.9434 12.8407 14.1143 13.5 13.1332 13.5H4.86365C3.88255 13.5 3.05345 12.8407 2.9228 11.9515C2.63192 9.96011 2.13726 6.63377 1.93183 5.56783C1.87267 5.26262 2.26051 5.05666 2.52346 5.25135C3.59824 6.04893 4.92939 7.03519 5.49965 7.4569C5.65166 7.56966 5.86777 7.55388 5.9976 7.41932C6.58676 6.81418 8.1077 5.24985 8.7281 4.61163C8.87275 4.46279 9.12167 4.46279 9.26632 4.61163C9.88585 5.24985 11.4068 6.81418 11.996 7.41932C12.1266 7.55388 12.3428 7.56966 12.4947 7.4569Z"
      fill="white"
    />
  </Icon>
);
