import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { SecondaryButton } from '../../common/Button';

type Props = {
  onSelectNextTopicClick: () => void;
};

export const NextTopic = ({ onSelectNextTopicClick }: Props) => {
  const t = useTranslations();
  return (
    <>
      <Divider />
      <ButtonContainer>
        <SecondaryButton buttonSize="md" onClick={onSelectNextTopicClick}>
          {t('next_topic')}
        </SecondaryButton>
      </ButtonContainer>
    </>
  );
};
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 40px;
`;
const Divider = styled.div`
  background-color: ${Colors.dividerColor};
  width: 100%;
  height: 1px;
  margin: 20px 0;
`;
