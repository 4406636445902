'use client';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

export const SearchFieldComponent = ({
  onChange,
  ...props
}: ComponentProps<'input'>) => {
  return (
    <InputWrapper>
      <InputField onChange={onChange} {...props} />
    </InputWrapper>
  );
};
const InputField = styled.input<{ hasError?: boolean }>`
  width: 100%;
  padding: 8px 20px 8px 40px;
  border: 1px solid ${Colors.inputBorderColor};
  border-radius: 50px;
  background-color: ${Colors.inputBackgroundColor};
  ${TextStyles.InputText};

  background-image: url('/ic_search.svg');
  padding-left: 44px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 16px;

  &:focus {
    outline: none;
    border-color: ${Colors.inputBorderColor};
  }
`;
const InputWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;
