import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { ModalTypes, useModal } from '../../hooks/useModal';
import { useGetUserQuery } from '../../services/userApi';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CardBase } from '../common/CardBase';

import {
  CompleteProfileStep,
  CompleteProfileStepEnum,
} from './CompleteProfileStep';

export const CompleteProfileCard = () => {
  const t = useTranslations();
  const { data: user } = useGetUserQuery();
  const { showModal } = useModal();

  const hasCustomUsername = () => {
    return !!(user?.nickname && !user?.nickname.isGenerated);
  };

  const hasSchool = () => (user?.school ? true : false);

  const addUsername = () => {
    showModal({
      type: ModalTypes.ChooseUsernameModal,
      data: {
        currentUsername: user?.nickname.value ?? '',
      },
    });
  };

  const addSchool = () => {
    showModal({
      type: ModalTypes.ChooseSchoolModal,
    });
  };

  return (
    <>
      <CardBase
        fullWidth={true}
        header={<HeaderText>{t('complete_your_profile')}</HeaderText>}
      >
        <Steps>
          <Step active={hasCustomUsername() || hasSchool()}></Step>
          <Step active={hasCustomUsername() && hasSchool()}></Step>
        </Steps>
        <CompleteProfileStep
          step={
            !hasCustomUsername()
              ? CompleteProfileStepEnum.Username
              : CompleteProfileStepEnum.School
          }
          onClick={!hasCustomUsername() ? addUsername : addSchool}
        />
      </CardBase>
    </>
  );
};
const HeaderText = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const Steps = styled.div`
  display: flex;
  padding-bottom: 20px;
  span {
    height: 8px;
    width: 100%;
    margin-right: 8px;
    border-radius: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
const Step = styled.span<{ active: boolean }>`
  background: ${({ active }) =>
    active ? Colors.progressBarHighlighted : Colors.progressBarDefault};
`;
