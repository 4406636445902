import { createContext, MutableRefObject, useEffect, useRef } from 'react';

import { config } from '../config';
import { useLogout } from '../hooks/useLogout';
import { ModalTypes, useModal } from '../hooks/useModal';
import { UserBadge } from '../models/Badge';
import { useAppSelector } from '../store/hooks';

import { SocketEvent, SocketInterface } from './socket.types';
import SocketFactory from './SocketFactory';

type SocketContextValue = {
  loginSocket: MutableRefObject<SocketInterface | undefined> | undefined;
  badgeSocket: MutableRefObject<SocketInterface | undefined> | undefined;
  trackingSocket: MutableRefObject<SocketInterface | undefined> | undefined;
};

export const SocketContext = createContext<SocketContextValue>({
  loginSocket: undefined,
  badgeSocket: undefined,
  trackingSocket: undefined,
});

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const loginSocket = useRef<SocketInterface>(
    SocketFactory.create(config.API_BASE_URL, {
      secure: true,
      rejectUnauthorized: false,
    })
  );
  const badgeSocket = useRef<SocketInterface | undefined>(undefined);
  const trackingSocket = useRef<SocketInterface | undefined>(undefined);

  const userState = useAppSelector((state) => state.user);
  const { logout } = useLogout();
  const { showModal } = useModal();

  useEffect(() => {
    if (userState.token?.userId) {
      loginSocket.current.socket.emit(
        SocketEvent.loginRegister,
        userState?.token.userId
      );
      loginSocket.current.socket.on(SocketEvent.loginDevice, () => {
        showModal({
          type: ModalTypes.AutomaticLogOutModal,
        });
        logout();
      });
    }

    return () => {
      loginSocket.current.socket.off(SocketEvent.loginDevice);
    };
  }, [userState.token?.userId]);

  useEffect(() => {
    if (userState && userState.token?.token) {
      const socket = SocketFactory.create(config.SOCKET_BADGES, {
        transportOptions: {
          polling: {
            extraHeaders: {
              authorization: `Bearer ${userState.token.token}`,
            },
          },
        },
      });
      socket.socket.on(SocketEvent.badgeCompleted, (userBadge: UserBadge) => {
        showModal({
          type: ModalTypes.BadgeUnlockedModal,
          data: {
            badge: userBadge,
          },
        });
      });
      badgeSocket.current = socket;
    }
  }, [showModal, userState]);

  useEffect(() => {
    if (userState && userState.token?.token) {
      const socket = SocketFactory.create(config.SOCKET_TRACKING, {
        transportOptions: {
          polling: {
            extraHeaders: {
              authorization: `Bearer ${userState.token.token}`,
            },
          },
        },
      });

      trackingSocket.current = socket;
    }
  }, [userState]);

  return (
    <SocketContext.Provider
      value={{ loginSocket, badgeSocket, trackingSocket }}
    >
      {children}
    </SocketContext.Provider>
  );
};
