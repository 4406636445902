import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { LockIcon } from '../../Icons/LockIcon';

type Props = {
  locked: 'videos' | 'summary';
};

export const SummaryLocked = ({ locked }: Props) => {
  const t = useTranslations();

  const lockedText =
    locked === 'videos' ? t('videos_locked') : t('summary_locked');

  return (
    <LockContainer>
      <StyledLockIcon color={Colors.disabledContentColor} />
      <LockedText>{lockedText}</LockedText>
    </LockContainer>
  );
};

const LockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 100%;
`;

const StyledLockIcon = styled(LockIcon)`
  height: 80px;
  width: 80px;
`;

const LockedText = styled.h1`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.BodyText};
  text-align: center;
`;
