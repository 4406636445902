import styled from '@emotion/styled';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { Question } from '../../../../models/training/Question';
import { Colors } from '../../../../styles/colors';
import { TextStyles } from '../../../../styles/textStyles';

import { CorrectAnswerBlock } from './CorrectAnswerBlock';
import { GrantPoints } from './GrantPoints';
import { InputForm } from './InputForm';

type Props = {
  question: Question;
  onAnswerQuestion: (
    answer: string,
    questionId: string,
    numberOfPoints: number
  ) => void;
};

export const OpenQuestion = ({ question, onAnswerQuestion }: Props) => {
  const t = useTranslations();
  const [userAnswer, setUserAnswer] = useState('');

  const onSubmitPoints = (points: number) => {
    onAnswerQuestion(userAnswer, question._id, points);
  };

  return (
    <Container>
      <Title>{question.title}</Title>
      <Introduction
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(question.introduction),
        }}
      />
      <Title>{t('answer')}</Title>
      <InputForm
        buttonTextKey="confirm_answer"
        onValidateInput={setUserAnswer}
      />
      {userAnswer && (
        <>
          <CorrectAnswerBlock question={question} />
          <GrantPoints question={question} onSubmitPoints={onSubmitPoints} />
        </>
      )}
    </Container>
  );
};
const Container = styled.div`
  padding: 8px 0;
`;
const Title = styled.h1`
  color: ${Colors.inputTextColor};
  ${TextStyles.InputHintText}
`;
const Introduction = styled.div``;
