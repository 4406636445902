import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { useSubjectColorContext } from '../../../../context/SubjectColorContext';
import { Messages } from '../../../../global.d';
import { SubjectColorButton } from '../../../common/Button';
import { InputFieldComponent } from '../../../common/Input';

import { FormFields, OpenQuestionSchema, FormData } from './InputFormSchema';

type Props = {
  buttonTextKey: keyof Messages;
  onValidateInput: (answer: string) => void;
};

export const InputForm = ({ buttonTextKey, onValidateInput }: Props) => {
  const t = useTranslations();
  const [isAnswered, setIsAnswered] = useState(false);
  const { subjectColor } = useSubjectColorContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(OpenQuestionSchema),
  });

  const { ref: answerRef, ...answerRegister } = register(FormFields.answer, {
    required: true,
  });

  const onSubmit = (data: FormData) => {
    setIsAnswered(true);
    onValidateInput(data.answer);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputFieldComponent
        inputRef={answerRef}
        disabled={isAnswered}
        hasError={!!errors.answer?.message}
        errorText={
          errors.answer?.message
            ? t(errors.answer.message as keyof Messages)
            : ''
        }
        {...answerRegister}
      />
      {!isAnswered && (
        <SubjectColorButton
          fullWidth={true}
          buttonSize="md"
          type="submit"
          buttonColor={subjectColor}
        >
          {t(buttonTextKey)}
        </SubjectColorButton>
      )}
    </form>
  );
};
