import { useTranslations } from 'use-intl';

import { PrimaryButton } from '../../common/Button';

type ResetFooterProps = {
  isChangeButtonEnabled: boolean;
};

export const ResetFooter = ({ isChangeButtonEnabled }: ResetFooterProps) => {
  const t = useTranslations();
  return (
    <PrimaryButton
      buttonSize="md"
      type="submit"
      disabled={!isChangeButtonEnabled}
    >
      {t('change')}
    </PrimaryButton>
  );
};
