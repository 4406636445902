import { useAppSelector } from '../store/hooks';

export const useIsLoggedIn = () => {
  const userState = useAppSelector((state) => state.user);

  // The teacher dashboard logout function only removes the localStorage token, not the react token. They both need to exist for the user to be logged in.
  const isLoggedIn = () => {
    const teacherDashboardToken = localStorage.getItem('token');
    const reactToken = userState.token;
    return teacherDashboardToken && reactToken;
  };

  return isLoggedIn;
};
