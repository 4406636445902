import { useGetUserDevicesQuery } from '../../services/userApi';
import { CardBase } from '../common/CardBase';
import { useDevice } from '../login/hooks/useDevice';

import { DeviceRow } from './devices/DeviceRow';
import { ProfileCardHeader } from './ProfileCardHeader';

export const DevicesCard = () => {
  const { data: devices } = useGetUserDevicesQuery();
  const currentDevice = useDevice();

  if (!devices) {
    return <></>;
  }

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={<ProfileCardHeader titleTranslationKey="linked_devices" />}
    >
      {devices.map((device) => {
        return (
          <DeviceRow
            key={device.identifier}
            device={device}
            enableDeleteButton={currentDevice.identifier !== device.identifier}
          />
        );
      })}
    </CardBase>
  );
};
