import { PropsWithChildren } from 'react';
import { IntlProvider } from 'use-intl';

import messages from '../locales/common.json';

export const TranslationsProvider = ({
  children,
}: Readonly<PropsWithChildren>) => {
  return (
    <IntlProvider messages={messages} locale="en">
      {children}
    </IntlProvider>
  );
};
