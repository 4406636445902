import { z } from 'zod';

const postalCodePattern = /^[1-9]\d{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
const numberPattern = /^\d+$/;

export type FormData = z.infer<typeof ShippingFormSchema>;

export enum FormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  street = 'street',
  zipCode = 'zipCode',
  houseNumber = 'houseNumber',
  addition = 'addition',
  city = 'city',
}

export const ShippingFormSchema = z.object({
  firstName: z.string().min(1, { message: 'no_valid_name' }),
  lastName: z.string().min(1, { message: 'no_valid_name' }),
  email: z.string().email({ message: 'no_valid_email' }),
  street: z.string().min(1, { message: 'no_valid_street' }),
  zipCode: z
    .string()
    .regex(postalCodePattern, { message: 'no_valid_zip_code' })
    .min(1, { message: 'no_valid_zip_code' }),
  houseNumber: z
    .string()
    .regex(numberPattern, { message: 'no_valid_house_number' })
    .min(1, { message: 'no_valid_house_number' }),
  addition: z.string().max(40, { message: 'no_valid_addition' }),
  city: z.string().min(1, { message: 'no_valid_city' }),
});
