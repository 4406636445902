import styled from '@emotion/styled';
import { ComponentProps } from 'react';

import { Illustrations } from '../../constants/Illustrations';
type Props = {
  src?: string;
} & ComponentProps<'div'>;

export const ProfileImageRound = ({ src, ...props }: Props) => {
  return (
    <ProfileImage src={src ?? Illustrations.emptyProfileImage} {...props} />
  );
};
const ProfileImage = styled.div<{ src: string }>`
  ${({ src }) => `background-image: url(${src});`}
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;
