import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { Platform } from '../../../constants/platforms';
import { SocialProvider } from '../../../utils/socialLoginUtils';

import { SocialUser } from './SocialUser';

const GOOGLE_SCOPE_EMAIL = 'email';
const GOOGLE_SCOPE_PROFILE = 'profile';

export const signInGoogle = async () => {
  try {
    await GoogleAuth.initialize({
      clientId: getClientId(),
      scopes: [GOOGLE_SCOPE_EMAIL, GOOGLE_SCOPE_PROFILE],
      grantOfflineAccess: true,
    });

    const res = await GoogleAuth.signIn();

    const user: SocialUser = {
      provider: SocialProvider.google,
      token: res.authentication.accessToken,
    };

    return user;
  } catch (error) {
    console.error('Google sign-in error:', error);
    throw error;
  }
};

const getClientId = () =>
  Capacitor.getPlatform() === Platform.ios
    ? process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID
    : process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const signOutGoogle = async () => {
  try {
    await GoogleAuth.signOut();
  } catch (err) {
    console.log('error while signing out Google account', err);
  }
};
