import styled from '@emotion/styled';
import { useState } from 'react';

import { Topic, Section } from '../models/Summary/summary';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';
import { LOWEST_PASS_GRADE } from '../utils/constants';

import { ArrowDownIcon } from './Icons/ArrowDownIcon';
import { TopicStatusIcon } from './Icons/TopicStatusIcon';
import { SectionActions } from './subject/home/components/SectionActions';

export const UnlockedSubjectChapter = ({
  chapterSection,
  encodedChapterName,
  videosActive,
}: {
  chapterSection: Section;
  encodedChapterName: string;
  videosActive: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { sectionTitle, sectionRating, topics } = chapterSection;

  return (
    <>
      <Container isExpanded={isExpanded}>
        <Title>{sectionTitle}</Title>
        <TrailerContainer>
          {!!sectionRating && (
            <Grade isLowGrade={sectionRating < LOWEST_PASS_GRADE}>
              {sectionRating.toFixed(1)}
            </Grade>
          )}
          <RotatingIcon
            onClick={() => setIsExpanded(!isExpanded)}
            isExpanded={isExpanded}
          >
            <ArrowDownIcon />
          </RotatingIcon>
        </TrailerContainer>
      </Container>

      {isExpanded && (
        <>
          <SectionActions
            chapterSection={chapterSection}
            encodedChapterName={encodedChapterName}
            videosActive={videosActive}
          />
          {topics?.map((topic: Topic, i: number) => (
            <TopicTile key={i} isExpanded={true}>
              <TopicStatusIcon size="sm" color={Colors.disabledContentColor} />
              {topic.topicTitle}
            </TopicTile>
          ))}
        </>
      )}
    </>
  );
};

const Container = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h4`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;

const TrailerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const Grade = styled.span<{ isLowGrade: boolean }>`
  color: ${(props) =>
    props.isLowGrade ? Colors.textLessGrade : Colors.topicPositive};
  ${TextStyles.ListItemTitle};
`;

const RotatingIcon = styled.div<{ isExpanded: boolean }>`
  transform: ${(props) =>
    props.isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s;
  cursor: pointer;
`;

const TopicTile = styled.div<{ isExpanded: boolean }>`
  ${TextStyles.ListItemBody}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 20px 0;
  border-bottom: none;

  ${({ isExpanded }) =>
    isExpanded
      ? `
    max-height: 56px !important;
    opacity: 1;
    transition: max-height 0.2s, padding 0.2s, opacity 0.2s;
  `
      : `
    max-height: 0 !important;
    overflow: hidden;
    box-sizing: border-box;
    opacity: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    transition: max-height 0.3s, padding 0.3s, opacity 0.3s;
  `}
`;
