import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { ModalTypes, useModal } from '../../../hooks/useModal';
import { useGetUserQuery } from '../../../services/userApi';
import { accountTypeNumberToTranslationKey } from '../../../utils/accountTypeUtils';
import { genderNumberToTranslationKey } from '../../../utils/genderUtils';
import { CardBase } from '../../common/CardBase';
import { ListRow } from '../../common/ListRow';
import { ProfileCardHeader } from '../ProfileCardHeader';

export const ProfileInfo = () => {
  const { data: user } = useGetUserQuery();
  const t = useTranslations();
  const { showModal } = useModal();

  const openEditModal = () => {
    if (!user) return;

    showModal({
      type: ModalTypes.EditProfileInfoModal,
      data: {
        user: user,
      },
    });
  };

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <ProfileCardHeader
          titleTranslationKey="profile"
          buttonTranslationKey="edit_profile"
          onButtonClick={openEditModal}
        />
      }
    >
      <Container>
        {user?.gender !== undefined && (
          <ListRow
            title={`${t('gender')}:`}
            description={t(genderNumberToTranslationKey(user?.gender))}
          />
        )}
        {user?.school && (
          <ListRow title={`${t('school')}:`} description={user?.school.name} />
        )}
        {user?.accountType !== undefined && (
          <ListRow
            title={`${t('account_type')}:`}
            description={t(accountTypeNumberToTranslationKey(user.accountType))}
            isLast={true}
          />
        )}
      </Container>
    </CardBase>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
