import styled from '@emotion/styled';
import { ReactElement, useState, cloneElement } from 'react';
import { NavLink } from 'react-router-dom';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { IconProps } from '../Icons/Icon';

type Props = {
  name: string;
  logo: ReactElement<IconProps>;
  navigateTo: string;
};

export const NavBarTab = ({ name, logo, navigateTo }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container
      to={navigateTo}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {({ isActive }) => {
        const color =
          isActive || isHovered ? Colors.primary : Colors.secondaryTextColor;
        const clonedLogo = cloneElement(logo, { color: color });

        return (
          <>
            <LogoContainer>{clonedLogo}</LogoContainer>
            <Title color={color}>{name}</Title>
          </>
        );
      }}
    </Container>
  );
};

const Container = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  &:active,
  &:hover {
    color: ${Colors.primary};
  }
`;
const LogoContainer = styled.div`
  margin: auto;
`;
const Title = styled.span<{ color: string }>`
  ${({ color }) => color && 'color: ' + color + ';'}
  padding-top: 4px;
  ${TextStyles.TabbarText}
`;
