import { useEffect } from 'react';

import { CONVERSION_EXPERIMENT_NAME } from '../constants/experiments';
import { useAddUserMutation } from '../services/experimentsApi';
import { useGetUserQuery } from '../services/userApi';

import { useError } from './useError';
import { ModalTypes, useModal } from './useModal';

export const useWelcomingGift = () => {
  const { data: user } = useGetUserQuery();
  const { showModal } = useModal();
  const [addUserTrigger] = useAddUserMutation();
  const { showErrorModal } = useError();

  const isExperimentsActive =
    user?.experiments &&
    user?.experiments[CONVERSION_EXPERIMENT_NAME] === 'active';

  useEffect(() => {
    if (isExperimentsActive) {
      addUserTrigger().then((experiment) => {
        try {
          if (experiment.data?.claimUrl) {
            showModal({
              type: ModalTypes.WelcomingGiftModal,
              data: {
                experiment: experiment.data,
              },
            });
          }
        } catch (error) {
          showErrorModal(error);
        }
      });
    }
  }, [addUserTrigger, isExperimentsActive]);
};
