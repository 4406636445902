import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { ROLE_SELECTION } from '../../../constants/onboardingSteps';
import { appRoutes } from '../../../constants/routes';
import { Messages } from '../../../global.d';
import { CourseLevel } from '../../../models/CourseLevel';
import { UserRole } from '../../../models/UserRole';
import { useAppDispatch } from '../../../store/hooks';
import {
  clearCourseLevels,
  removeAllSubjects,
  setAccountType,
} from '../../../store/slices/userManagementSlice';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { PrimaryButton } from '../../common/Button';
import { CardBase, FooterAlignment } from '../../common/CardBase';
import { GraduationCapIcon } from '../../Icons/GraduationCapIcon';
import { ParentCaretakerIcon } from '../../Icons/ParentCaretakerIcon';
import { TeacherChalkboardIcon } from '../../Icons/TeacherChalkboardIcon';
import { ProgressBar } from '../../ProgressBar';
import { CardHeader } from '../CardHeader';
import { useAccountCourseLevel } from '../hooks/useAccountCourseLevel';
import { useOnboardingProgress } from '../hooks/useOnboardingProgress';
import { SelectableItemContainer } from '../SelectableItemContainer';

import { RoleSelectableItem } from './RoleSelectableItem';
import { SelectCourseLevelSection } from './SelectCourseLevelSection';

const roleOptions = [
  { role: UserRole.student, Icon: GraduationCapIcon, text: 'student' },
  { role: UserRole.teacher, Icon: TeacherChalkboardIcon, text: 'teacher' },
  {
    role: UserRole.parentCaretaker,
    Icon: ParentCaretakerIcon,
    text: 'parent_caretaker',
  },
];

const ONBOARDING_STEP = ROLE_SELECTION;

export const SelectRoleCard = () => {
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const navigate = useNavigate();

  const { progress } = useOnboardingProgress(ONBOARDING_STEP);

  const { onCourseLevelSelect, selectedCourseLevels, selectedRole } =
    useAccountCourseLevel();

  const onCourseLevelClick = (courseLevel: CourseLevel) => {
    onCourseLevelSelect(courseLevel);
    if (selectedRole !== UserRole.teacher) {
      onContinue();
    }
  };

  const onSelectRoleClick = (role: UserRole) => {
    dispatch(setAccountType(role));
    dispatch(clearCourseLevels());
  };

  const onContinue = () => {
    dispatch(removeAllSubjects());
    navigate(appRoutes.OnboardingSubjects);
  };

  const footerButtons = () => {
    if (selectedRole !== UserRole.teacher) return undefined;

    return (
      <PrimaryButton
        buttonSize="md"
        onClick={onContinue}
        disabled={!selectedCourseLevels.length}
      >
        {t('continue_alt')}
      </PrimaryButton>
    );
  };

  return (
    <StyledCardBase
      fullWidth={true}
      fullWidthBody={true}
      header={<CardHeader title={t('select_role_header')} />}
      footerButtons={footerButtons()}
      footerAlignment={FooterAlignment.right}
      fullHeightBody={true}
    >
      <ProgressBar progress={progress} roundedCorners={false} />
      <ScrollContainer>
        <Container>
          <Header>{t('what_is_your_role')}</Header>
          <SelectableItemContainer>
            {roleOptions.map(({ role, Icon, text }) => (
              <RoleSelectableItem
                key={role}
                role={role}
                Icon={Icon}
                text={t(text as keyof Messages)}
                selectedRole={selectedRole}
                onSelect={onSelectRoleClick}
              />
            ))}
          </SelectableItemContainer>
        </Container>
        {selectedRole !== undefined && (
          <SelectCourseLevelSection
            onCourseLevelClick={onCourseLevelClick}
            selectedCourseLevels={selectedCourseLevels}
            selectedRole={selectedRole}
          />
        )}
      </ScrollContainer>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: 100vh;
  overflow-y: auto;

  @media ${HookMediaQueries.isDesktop} {
    margin-top: 24px;
    height: 80vh;
  }
`;

const Container = styled.div`
  padding-bottom: 24px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  padding: 20px;
`;

const Header = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
  padding: 16px 0;
`;
