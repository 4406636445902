import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import { useGetSubjectVideosByEncodedNameQuery } from '../../../services/subjectsApi';
import { CardBase } from '../../common/CardBase';
import { NoContentCard } from '../NoContentCard';

import { Video } from './Video';

export const Videos = () => {
  const { encodedSubjectName } = useParams();
  const { data } = useGetSubjectVideosByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );

  if (!data) {
    return <NoContentCard />;
  }

  return (
    <CardBase fullWidth={true}>
      <FlexBox>
        {data?.map((video) => (
          <StyledVideo title={video.title} url={video.link} key={video._id} />
        ))}
      </FlexBox>
    </CardBase>
  );
};

const FlexBox = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledVideo = styled(Video)`
  margin: auto;
  flex: 1;
  min-width: 300px;
  padding-bottom: 20px;
`;
