import styled from '@emotion/styled';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Route } from '../../../constants/routes';
import { Colors } from '../../../styles/colors';
import { ChevronDown } from '../../Icons/ChevronDown';
import { ChevronUp } from '../../Icons/ChevronUp';
import { IconSize } from '../../Icons/Icon';

import { MobileNavBarItem } from './MobileNavBarItem';

type Props = {
  routes: Route[];
};

export const MobileNavMenu = ({ routes }: Props) => {
  const t = useTranslations();
  const [menuOpen, setMenuOpen] = useState(false);
  const handleClick = () => setMenuOpen(false);

  const chevronSize: IconSize = 'md';

  const location = useLocation();
  const currentPath = location.pathname;

  const activeRoute = routes.find((item) => currentPath.includes(item.path));
  const possibleRoutes = routes.filter((route) => route !== activeRoute);

  return (
    <Container>
      {activeRoute && (
        <Active onClick={() => setMenuOpen((prev) => !prev)}>
          <MobileNavBarItem
            to={activeRoute.path}
            title={t(activeRoute.titleKey)}
          />
          <ChevronContainer>
            {menuOpen ? (
              <ChevronUp size={chevronSize} />
            ) : (
              <ChevronDown size={chevronSize} />
            )}
          </ChevronContainer>
        </Active>
      )}
      {menuOpen && (
        <List>
          {possibleRoutes.map((item) => (
            <MobileNavBarItem
              key={item.path}
              to={item.path}
              title={t(item.titleKey)}
              onClick={handleClick}
            />
          ))}
        </List>
      )}
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid ${Colors.dividerColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  background-color: ${Colors.modalBackgroundColor};
  box-shadow: 0px 2px 2px 0px ${Colors.shadowColor};
`;
const Active = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
`;
const ChevronContainer = styled.div`
  padding: 16px;
`;
