import styled from '@emotion/styled';
import { useState } from 'react';

import { TextStyles } from '../../../styles/textStyles';
import { ArrowDownIcon } from '../../Icons/ArrowDownIcon';

type Props = {
  question: string;
  answer: string;
};

export const FAQQuestionComponent = ({ question, answer }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div>
      <QuestionContainer onClick={() => setIsExpanded(!isExpanded)}>
        <Title>{question}</Title>
        <RotatingIcon isRotated={isExpanded}>
          <ArrowDownIcon />
        </RotatingIcon>
      </QuestionContainer>
      {isExpanded && (
        <AnswerContainer dangerouslySetInnerHTML={{ __html: answer }} />
      )}
    </div>
  );
};

const QuestionContainer = styled.strong`
  display: flex;
  margin: 32px 0px;
  cursor: pointer;
  justify-content: space-between;
  ${TextStyles.FAQBaseText}
`;

const Title = styled.div``;

const AnswerContainer = styled.div`
  ${TextStyles.FAQBaseText}
`;

const RotatingIcon = styled.div<{ isRotated: boolean }>`
  transform: ${(props) =>
    props.isRotated ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s;
`;
