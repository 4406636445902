import styled from '@emotion/styled';
import { skipToken } from '@reduxjs/toolkit/query';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT } from '../../../constants/Sizes';
import { productTypePrefix } from '../../../models/ProductBundle';
import {
  useGetSubjectByEncodedNameQuery,
  useGetSubjectExercisesByIdQuery,
} from '../../../services/subjectsApi';
import { useGetBuyAbleProductBundlesForUserBySubjectIdQuery } from '../../../services/userApi';
import { CardBase } from '../../common/CardBase';

import { ExerciseCardHeader } from './ExerciseCardHeader';
import { useLoadBoxPDFResources } from './hooks/useLoadBoxPDFResources';

export const SubjectExercises = () => {
  const t = useTranslations();
  const { encodedSubjectName } = useParams();
  const previewContainerRef = useRef<HTMLDivElement>(null);

  const { data: subjectData } = useGetSubjectByEncodedNameQuery(
    encodedSubjectName ?? skipToken
  );
  const subjectId = subjectData?._id;
  const { data: exerciseData } = useGetSubjectExercisesByIdQuery(
    subjectId ?? skipToken
  );
  const { data: buyableProducts } =
    useGetBuyAbleProductBundlesForUserBySubjectIdQuery(subjectId ?? skipToken);

  const buyableProduct = buyableProducts?.find(
    (bundle) =>
      bundle.bundleProducts?.every((product) =>
        product.baseSku.startsWith(productTypePrefix.exercises)
      ) &&
      bundle.bundleProducts.some((product) =>
        exerciseData?.baseSku.includes(product.baseSku)
      )
  );

  useLoadBoxPDFResources(exerciseData, previewContainerRef);

  return (
    <CardBase
      fullWidth
      fullWidthBody
      header={
        buyableProduct && <ExerciseCardHeader buyableProduct={buyableProduct} />
      }
    >
      {exerciseData ? (
        <BoxDocPreview
          ref={previewContainerRef}
          className="preview-container"
        />
      ) : (
        <NoExerciseBookContainer>
          {t('there_is_no_exercise_book')}
        </NoExerciseBookContainer>
      )}
    </CardBase>
  );
};

const BoxDocPreview = styled.div`
  height: calc(100vh - ${HEADER_AND_SUBJECT_HEADER_COMBINED_HEIGHT});
  width: 100%;
`;

const NoExerciseBookContainer = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
