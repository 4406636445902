import styled from '@emotion/styled';
import { InputHTMLAttributes, PropsWithChildren, Ref, useState } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  hasError?: boolean;
  errorText?: string;
  inputRef?: Ref<HTMLInputElement>;
};

export const Checkbox = ({
  children,
  label,
  hasError = false,
  errorText = '',
  inputRef,
  ...props
}: PropsWithChildren<InputFieldProps>) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <InputContainer>
      <InputWrapper>
        {inputRef ? (
          <InputField
            type="checkbox"
            hasError={hasError}
            ref={inputRef}
            {...props}
          />
        ) : (
          <InputField
            type="checkbox"
            hasError={hasError}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            ref={inputRef}
            {...props}
          />
        )}
        {children}
        {label && <Label>{label}</Label>}
      </InputWrapper>
      {hasError && <ErrorText>{errorText}</ErrorText>}
    </InputContainer>
  );
};
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: ${Colors.inputHintText};
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const InputField = styled.input<{ hasError?: boolean }>`
  accent-color: ${Colors.primaryButtonFill};
  padding: 7px;
  margin: 0 12px 0 0;
  height: 16px;
  width: 16px;
`;

const ErrorText = styled.span`
  margin-top: 0.5rem;
  color: ${Colors.inputHintError};
  ${TextStyles.InputTextError};
`;

export default Checkbox;
