import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ComponentProps } from 'react';

import { Colors } from '../styles/colors';

type Props = {
  progress?: number;
  full?: boolean;
  roundedCorners?: boolean;
  initialWidth?: number;
} & ComponentProps<'div'>;

const transition = {
  duration: 0.3,
  delay: 0.0,
  ease: 'easeIn',
};

export const ProgressBar = ({
  full,
  progress,
  roundedCorners = true,
  initialWidth = 0,
  ...props
}: Props) => {
  return (
    <Container rounded={roundedCorners} {...props}>
      <Bar
        initial={{ width: initialWidth + '%' }}
        animate={{ width: `${full ? 100 : progress}%` }}
        transition={transition}
        rounded={roundedCorners}
      />
    </Container>
  );
};

const Container = styled.div<{ rounded?: boolean }>`
  width: 100%;
  height: 8px;
  flex: 1;
  border-radius: ${({ rounded }) => (rounded ? '5px' : '0px')};
  position: relative;
  background-color: ${Colors.progressBarDefault};
`;

const Bar = styled(motion.div)<{
  progress?: number;
  full?: boolean;
  rounded?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  border-radius: ${({ rounded }) => (rounded ? '5px' : '0px 5px 5px 0px')};
  background-color: ${Colors.progressBarHighlighted};
`;
