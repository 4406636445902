import { IconProps, Icon } from './Icon';

export const ChevronUp = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M9.33594 4.33594C9.70313 3.96875 10.2969 3.96875 10.6602 4.33594L18.1641 11.8359C18.5313 12.2031 18.5313 12.7969 18.1641 13.1602C17.7969 13.5234 17.2031 13.5273 16.8398 13.1602L10.0039 6.32422L3.16406 13.1641C2.79688 13.5312 2.20313 13.5312 1.83985 13.1641C1.47656 12.7969 1.47266 12.2031 1.83985 11.8398L9.33594 4.33594Z"
      fill={color}
    />
  </Icon>
);
