import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useDeepLinking = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let listener: PluginListenerHandle | undefined;

    App.addListener('appUrlOpen', (event) => {
      const slug = event.url.split('.nl').pop();
      console.log('URL opened:', slug);
      if (!slug) return;

      navigate(slug);
    }).then((createdListener) => {
      listener = createdListener;
    });

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, []);
};
