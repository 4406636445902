import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { useAppDispatch } from '../../store/hooks';
import { welcomeMessageClosed } from '../../store/slices/userSlice';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { CardBase } from '../common/CardBase';
import { CloseIcon } from '../Icons/CloseIcon';

export const WelcomeMessageCard = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(welcomeMessageClosed());
  };

  return (
    <CardBase
      fullWidth={true}
      header={
        <>
          <HeaderText>{t('welcome_to_your_EXO_account')}</HeaderText>
          <CloseContainer onClick={close}>
            <CloseIcon />
          </CloseContainer>
        </>
      }
    >
      <Body>{t('welcome_message')}</Body>
    </CardBase>
  );
};
const Body = styled.p`
  white-space: pre-line;
  color: ${Colors.primaryTextColor};
  ${TextStyles.BodyText}
`;
const HeaderText = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const CloseContainer = styled.div`
  cursor: pointer;
`;
