import { IconProps, Icon } from './Icon';

export const ScheduleIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M5 0C5.35357 0 5.64286 0.28125 5.64286 0.625V2.5H13.3571V0.625C13.3571 0.28125 13.6464 0 14 0C14.3536 0 14.6429 0.28125 14.6429 0.625V2.5H15.9286C17.3469 2.5 18.5 3.62109 18.5 5V6.25V7.5V17.5C18.5 18.8789 17.3469 20 15.9286 20H3.07143C1.65313 20 0.5 18.8789 0.5 17.5V7.5V6.25V5C0.5 3.62109 1.65313 2.5 3.07143 2.5H4.35714V0.625C4.35714 0.28125 4.64643 0 5 0ZM17.2143 7.5H1.78571V17.5C1.78571 18.1914 2.36027 18.75 3.07143 18.75H15.9286C16.6397 18.75 17.2143 18.1914 17.2143 17.5V7.5ZM15.9286 3.75H3.07143C2.36027 3.75 1.78571 4.30859 1.78571 5V6.25H17.2143V5C17.2143 4.30859 16.6397 3.75 15.9286 3.75ZM4.35714 14.375C4.35714 14.0312 4.64643 13.75 5 13.75H10.1429C10.4964 13.75 10.7857 14.0312 10.7857 14.375C10.7857 14.7188 10.4964 15 10.1429 15H5C4.64643 15 4.35714 14.7188 4.35714 14.375ZM5 10H14C14.3536 10 14.6429 10.2812 14.6429 10.625C14.6429 10.9688 14.3536 11.25 14 11.25H5C4.64643 11.25 4.35714 10.9688 4.35714 10.625C4.35714 10.2812 4.64643 10 5 10Z"
      fill={color}
    />
  </Icon>
);
