import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type NavBarItemProps = {
  to: string;
  title: string;
};

export const NavBarItem = ({ to, title }: NavBarItemProps) => {
  return <StyledNavLink to={to}>{title}</StyledNavLink>;
};

const StyledNavLink = styled(NavLink)`
  padding: 12px 16px;
  text-decoration: none;
  color: ${Colors.disabledTextColor};
  ${TextStyles.CardTitleText}

  &.active,
  &:hover {
    color: ${Colors.primaryTextColor};
  }
`;
