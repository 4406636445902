import { RefObject, useEffect } from 'react';

import { BoxAPI } from '../../../../constants/box';
import { useError } from '../../../../hooks/useError';
import { Exercise } from '../../../../models/Exercise';

/*
This hook loads the CSS and the SCRIPT of api.box.com. Currently loading the script in the body of the html (index.html) is not working, although that would be the best implementation. 
the box.com API also has a npm package, but has issues with react. Installing with the legacy dependencies flag is also not working. 
So this hook loads the css and script dynamically, and removes it after use.  
*/
export const useLoadBoxPDFResources = (
  exerciseData: Exercise | undefined,
  previewContainerRef: RefObject<HTMLDivElement>
) => {
  const { showErrorModal } = useError();

  useEffect(() => {
    const loadResources = async () => {
      if (!exerciseData) return;

      try {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = BoxAPI.styleURL;
        document.head.appendChild(link);

        const script = document.createElement('script');
        script.src = BoxAPI.scriptURL;
        script.async = true;
        await new Promise<void>((resolve, reject) => {
          script.onload = () => resolve();
          script.onerror = () => reject(new Error('Failed to load Box script'));
          document.body.appendChild(script);
        });

        if (window.Box?.Preview && previewContainerRef.current) {
          const preview = new window.Box.Preview();
          preview.show(exerciseData.doc_id, exerciseData.token.access_token, {
            container: previewContainerRef.current,
            showDownload: false,
            header: 'none',
            viewers: {
              CSV: { disableTextLayer: true },
              Document: { disableTextLayer: true },
              Presentation: { disableTextLayer: true },
              Markdown: { disableTextLayer: true },
              Text: { disableTextLayer: true },
            },
          });
        } else {
          console.error(
            'Box.Preview is not available or previewContainerRef is null'
          );
        }
      } catch (error) {
        showErrorModal(error);
      }
    };

    loadResources();

    return () => {
      const headLinks = document.head.getElementsByTagName('link');
      for (let i = 0; i < headLinks.length; i++) {
        if (headLinks[i].href === BoxAPI.styleURL) {
          document.head.removeChild(headLinks[i]);
        }
      }

      const bodyScripts = document.body.getElementsByTagName('script');
      for (let i = 0; i < bodyScripts.length; i++) {
        if (bodyScripts[i].src === BoxAPI.scriptURL) {
          document.body.removeChild(bodyScripts[i]);
        }
      }
    };
  }, [exerciseData, previewContainerRef]);
};
