import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { CardBase } from '../../components/common/CardBase';
import { PrimaryHeaderText } from '../../styles/elements/PrimaryHeaderText';

export const Disclaimer = () => {
  const t = useTranslations();
  return (
    <Container>
      <ContentContainer>
        <CardBase
          fullWidth={true}
          fullWidthBody={true}
          header={<PrimaryHeaderText>{t('disclaimer')}</PrimaryHeaderText>}
        >
          <ContentBody>{t('disclaimer_content')}</ContentBody>
        </CardBase>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const ContentBody = styled.div`
  padding: 24px 20px;
  white-space: pre-line;
`;
