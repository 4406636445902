import { useEffect, useRef } from 'react';

import { Category } from '../../../../models/Definition';

function useVisibleCategoryObserver(
  subCategoryRefs: React.MutableRefObject<{
    [key: string]: HTMLElement | null;
  }>,
  onChangeVisibleCategory: (visibleSubCategoryId: string) => void,
  categories: Category[]
) {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const visibleEntries = entries.filter((entry) => entry.isIntersecting);

      visibleEntries.sort(
        (a, b) => a.boundingClientRect.top - b.boundingClientRect.top
      );

      if (visibleEntries.length > 0) {
        const visibleSubCategoryId = visibleEntries[0].target.id;
        onChangeVisibleCategory(visibleSubCategoryId);
      }
    };

    observerRef.current = new IntersectionObserver(observerCallback, {
      threshold: 1,
    });

    Object.values(subCategoryRefs.current).forEach((subCategoryElement) => {
      if (subCategoryElement) {
        observerRef.current?.observe(subCategoryElement);
      }
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [subCategoryRefs, onChangeVisibleCategory, categories]);
}

export default useVisibleCategoryObserver;
