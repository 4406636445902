import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { makeSubjectHomeRoute } from '../../constants/routes';
import { ModalTypes, useModal } from '../../hooks/useModal';
import { SubjectCategory, UserSubject } from '../../models/Subject';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

import { SubjectImage } from './SubjectImage';

type Props = {
  userSubject: UserSubject;
};

export const SubjectRow = ({ userSubject }: Props) => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const navigateToSubject = (subjectEncodedName: string) => {
    if (userSubject.subject.category === SubjectCategory.Extra) {
      showModal({
        type: ModalTypes.ExtraSubjectModal,
        data: {
          subject: userSubject,
        },
      });
      return;
    }
    navigate(makeSubjectHomeRoute(subjectEncodedName));
  };
  return (
    <Container
      onClick={() => {
        navigateToSubject(userSubject.subject.encodedName);
      }}
      key={userSubject._id}
    >
      <StyledSubjectImage src={userSubject.subject.subjectImage} />
      <TextContent>
        <Name>{userSubject.subject.name}</Name>
        <Start>{userSubject.subject.courseLevel}</Start>
      </TextContent>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.1s linear;
  overflow: hidden;
  &:hover {
    background-color: ${Colors.subjectRowHover};
    border-radius: 5px;
  }
`;

const StyledSubjectImage = styled(SubjectImage)`
  width: 40px;
  height: 40px;
`;

const TextContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  text-align: left;
  overflow: hidden;
  max-width: 100%;
`;

const Name = styled.h1`
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
`;
const Start = styled.h1`
  margin: 0;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
