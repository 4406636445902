import styled from '@emotion/styled';

import { LeaderBoardScore } from '../../../models/LeaderBoard';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

type Props = {
  leaderBoardScore: LeaderBoardScore;
};

export const LeaderBoardUserRow = ({ leaderBoardScore }: Props) => {
  return (
    <Container isMe={leaderBoardScore.isMe}>
      <UserDetailsContainer>
        #{leaderBoardScore.position} <span>{leaderBoardScore.nickname}</span>
      </UserDetailsContainer>
      <ScoreTextContainer>{leaderBoardScore.score}</ScoreTextContainer>
    </Container>
  );
};

const Container = styled.div<{ isMe: boolean }>`
  border-top: 1px solid ${Colors.dividerColor};
  padding: 16px 20px;
  ${({ isMe }) => isMe && 'font-weight: 600;'};
`;

const ScoreTextContainer = styled.div`
  ${TextStyles.LeaderBoardScore}
`;

const UserDetailsContainer = styled.div`
  ${TextStyles.LeaderBoardText}
`;
