import { ApiTagTypes } from '../constants/api';
import { Payment } from '../models/payments';

import { baseApi } from './baseApi';

export const paymentService = baseApi
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ProductBundles],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getDiscount: build.query<
        { discount: number; description: string },
        { code: string; productBundles: string[] }
      >({
        query: ({ code, productBundles }) => {
          const productBundlesQueryString = productBundles
            .map((bundle) => `&productBundles=${bundle}`)
            .join('');

          return {
            url: `/discount?code=${code}${productBundlesQueryString}`,
          };
        },
        providesTags: [ApiTagTypes.ProductBundles],
      }),
      getPaymentStatus: build.query<Payment, { paymentId: string }>({
        query: ({ paymentId }) => ({
          url: `/payments/${paymentId}`,
        }),
      }),
    }),
  });

export const {
  useGetDiscountQuery,
  useLazyGetDiscountQuery,
  useGetPaymentStatusQuery,
  useLazyGetPaymentStatusQuery,
} = paymentService;
