import styled from '@emotion/styled';
import { ComponentProps } from 'react';

type Props = { src?: string } & ComponentProps<'img'>;

export const SubjectImage = ({ src, ...props }: Props) => {
  return (
    <RoundedImage
      src={`${process.env.REACT_APP_API_BASE_URL}${src}`}
      {...props}
    />
  );
};

const RoundedImage = styled.img`
  border-radius: 8px;
`;
