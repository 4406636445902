import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import { Sections } from '../../../../models/Summary/summary';
import { useGetNextUnreadSectionsQuery } from '../../../../services/subjectsApi';
import { useGetLastResultsQuery } from '../../../../services/trainingApi';
import {
  calcSubjectProgress,
  flattenArray,
  uniqueByKey,
} from '../../../../utils/mathUtils';

export const useSubjectPercentage = (sections?: Sections) => {
  const { encodedSubjectName } = useParams<{ encodedSubjectName: string }>();

  const allSections = sections?.chapters.map((chapter) => chapter.sections);
  const flattenedSections = flattenArray(allSections ?? []);

  const uniqueSections = uniqueByKey(flattenedSections, 'encodedSectionName');

  const { data: nextUnreadSections } = useGetNextUnreadSectionsQuery();

  const { data: trainingResult } = useGetLastResultsQuery(
    encodedSubjectName ?? skipToken
  );

  const sectionsRead = uniqueSections.filter((section) =>
    nextUnreadSections?.find(
      (_section) => _section.encodedSectionName === section.encodedSectionName
    )
  );

  const {
    progressPercentage,
    progressPercentageTraining,
    progressPercentageSummary,
  } = calcSubjectProgress(uniqueSections, sectionsRead, trainingResult);

  return {
    progressPercentage,
    progressPercentageTraining,
    progressPercentageSummary,
  };
};
