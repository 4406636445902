import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';

import { HookMediaQueries } from '../../constants/MediaQueries';
import { profileRoutes } from '../../constants/routes';
import { SubNavigationMenu } from '../navbar/SubNavigationMenu';

import { ProfileInfoPhotoSection } from './info/ProfileInfoPhotoSection';

export const ProfileHeader = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  return (
    <Container isMobile={!isDesktop}>
      <ProfileInfoPhotoSection />
      <SubNavigationMenu routes={profileRoutes} />
    </Container>
  );
};
const Container = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => isMobile && 'padding: 0 20px;'}
`;
