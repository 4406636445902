import { IconProps, Icon } from './Icon';

export const FlagIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M2.10714 1.44775C2.10714 1.01481 1.74888 0.666504 1.30357 0.666504C0.858259 0.666504 0.5 1.01481 0.5 1.44775V2.74984V12.076V13.6873V16.5519C0.5 16.9849 0.858259 17.3332 1.30357 17.3332C1.74888 17.3332 2.10714 16.9849 2.10714 16.5519V13.2967L4.79576 12.6424C6.17188 12.3071 7.62835 12.4634 8.89732 13.0786C10.3772 13.798 12.0949 13.8859 13.6417 13.3195L14.8036 12.8963C15.2221 12.7433 15.5 12.356 15.5 11.9198V2.81494C15.5 2.06624 14.6897 1.57796 14 1.91325L13.6786 2.0695C12.1283 2.82471 10.3036 2.82471 8.75335 2.0695C7.57812 1.49658 6.22879 1.35335 4.95312 1.6626L2.10714 2.35921V1.44775ZM2.10714 3.97054L5.34152 3.18278C6.24554 2.96468 7.19978 3.06559 8.03348 3.46924C9.87165 4.36117 12.0078 4.43604 13.8929 3.69059V11.5617L13.0759 11.8579C11.9475 12.2681 10.692 12.2062 9.61384 11.6821C8 10.8976 6.15513 10.7023 4.40402 11.1255L2.10714 11.6854V3.97054Z"
      fill={color}
    />
  </Icon>
);
