import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { ArrowBackIcon } from '../../Icons/ArrowBackIcon';

type ForgotPasswordHeaderProps = {
  onBackClick: () => void;
};

export const ForgotPasswordHeader = ({
  onBackClick,
}: ForgotPasswordHeaderProps) => {
  const t = useTranslations();
  return (
    <>
      <ClickableContainer onClick={onBackClick}>
        <ArrowBackIcon />
      </ClickableContainer>
      <Title>{t('forgot_password')}</Title>
    </>
  );
};

const ClickableContainer = styled.div`
  cursor: pointer;
  position: absolute;
`;
const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText};
`;
