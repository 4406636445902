import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Outlet } from 'react-router-dom';

import { OnboardingHeader } from '../../components/onboarding/OnboardingHeader';
import { Page } from '../../components/Page';
import { HookMediaQueries } from '../../constants/MediaQueries';

export const Onboarding = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  const onboardingIndex = <Outlet />;

  return (
    <Container>
      <OnboardingHeader />
      {isDesktop ? <Page>{onboardingIndex}</Page> : onboardingIndex}
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
`;
