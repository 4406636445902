import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useError } from '../../hooks/useError';
import { usePutUnblockAccountMutation } from '../../services/userApi';

export const Unblock = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const [unblock] = usePutUnblockAccountMutation();
  const [apiCallDone, setApiCallDone] = useState(false);
  const { showErrorModal } = useError();

  const unblockAccount = async () => {
    if (!key) {
      navigate('/login');
      return;
    }

    try {
      await unblock({ key });
    } catch (error) {
      showErrorModal(error);
    }
    navigate('/login');
  };

  if (!apiCallDone) {
    setApiCallDone((prev) => !prev);
    unblockAccount();
  }

  return <></>;
};
