import { useMediaQuery } from '@uidotdev/usehooks';

import { HookMediaQueries } from '../../constants/MediaQueries';
import { Route } from '../../constants/routes';
import { DesktopNavMenu } from '../subject/nav/DesktopNavMenu';
import { MobileNavMenu } from '../subject/nav/MobileNavMenu';

type Props = {
  routes: Route[];
};

export const SubNavigationMenu = ({ routes }: Props) => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  return isDesktop ? (
    <DesktopNavMenu routes={routes} />
  ) : (
    <MobileNavMenu routes={routes} />
  );
};
