import styled from '@emotion/styled';
import { useState } from 'react';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';

type RadioGridProps = {
  amountOfItems: number;
  onChange: (selected: number) => void;
  color: string;
  disabled: boolean;
};

export const RadioGrid = ({
  amountOfItems,
  onChange,
  color,
  disabled,
}: RadioGridProps) => {
  const [selected, setSelected] = useState<number | null>(null);

  const handleSelect = (index: number) => {
    if (disabled) return;
    setSelected(index);
    onChange(index);
  };

  return (
    <Grid>
      {[...Array(amountOfItems)].map((_, index) => (
        <Item key={index}>
          <RadioCircle
            key={index}
            color={color}
            selected={selected === index}
            onClick={() => handleSelect(index)}
          ></RadioCircle>
          {index}
        </Item>
      ))}
    </Grid>
  );
};
const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${Colors.primaryTextColor};
  ${TextStyles.InputHintText}
`;
const Grid = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: fit-content;
`;
const RadioCircle = styled.div<{ selected: boolean; color: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ selected, color }) =>
    selected ? color : Colors.radioColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
`;
