import { IconProps, Icon } from './Icon';

export const TeacherChalkboardIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M6.5736 7.1875C7.04038 7.1875 7.48803 7.01959 7.81809 6.7207C8.14815 6.42182 8.33357 6.01644 8.33357 5.59375C8.33357 5.17106 8.14815 4.76568 7.81809 4.4668C7.48803 4.16791 7.04038 4 6.5736 4C6.10683 4 5.65918 4.16791 5.32912 4.4668C4.99906 4.76568 4.81363 5.17106 4.81363 5.59375C4.81363 6.01644 4.99906 6.42182 5.32912 6.7207C5.65918 7.01959 6.10683 7.1875 6.5736 7.1875ZM6.07128 9.84375H7.16393V14.0938H5.99061V9.84707C6.01628 9.84707 6.04561 9.84375 6.07495 9.84375H6.07128ZM5.98695 20.2031V15.6875H7.16026V20.2031C7.16026 20.6447 7.55259 21 8.04025 21C8.5279 21 8.92023 20.6447 8.92023 20.2031V9.84375H13.9068C14.3945 9.84375 14.7868 9.48848 14.7868 9.04688C14.7868 8.60527 14.3945 8.25 13.9068 8.25H13.0268V5.59375H21.24V13.0312H13.0268V11.4375H11.2669V13.0312C11.2669 13.9111 12.0552 14.625 13.0268 14.625H21.24C22.2117 14.625 23 13.9111 23 13.0312V5.59375C23 4.71387 22.2117 4 21.24 4H13.0268C12.0552 4 11.2669 4.71387 11.2669 5.59375V8.25H8.44724H6.07128C4.90896 8.25 3.83465 8.81777 3.26266 9.73418L1.11403 13.1707C0.875698 13.5525 1.02236 14.0406 1.44769 14.2564C1.87302 14.4723 2.40834 14.3395 2.64667 13.9543L4.22698 11.4242V20.2031C4.22698 20.6447 4.6193 21 5.10696 21C5.59462 21 5.98695 20.6447 5.98695 20.2031Z"
      fill={color}
    />
  </Icon>
);
