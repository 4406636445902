import { CardBase } from '../../../../components/common/CardBase';
import { useSubjectContext } from '../../../../context/SubjectContext';
import { SubjectTile } from '../../../SubjectTile';

export const SubjectChapter = () => {
  const { sections } = useSubjectContext();
  const { chapters } = sections || {};

  return (
    <>
      {chapters?.map((chapter, i) => (
        <CardBase key={i} fullWidth={true} header={<>{chapter.chapterTitle}</>}>
          <SubjectTile
            chapterSections={chapter.sections}
            encodedChapterName={chapter.encodedChapterName}
          />
        </CardBase>
      ))}
    </>
  );
};
