import { DefinitionDetail } from '../../../models/Definition';
import { ListRow } from '../../common/ListRow';

type Props = {
  definitions: DefinitionDetail[];
};

export const DefinitionList = ({ definitions }: Props) => {
  return (
    <>
      {definitions.map((definition: DefinitionDetail) => (
        <ListRow
          key={definition._id}
          title={definition.term}
          description={definition.definition}
        />
      ))}
    </>
  );
};
