import styled from '@emotion/styled';
import { MathJax } from 'better-react-mathjax';
import { PropsWithChildren } from 'react';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

export const PracticeCMS = ({ children }: PropsWithChildren) => {
  return (
    <CMS>
      <MathJax>{children}</MathJax>
    </CMS>
  );
};

const CMS = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.SummaryReadingText}

  img {
    max-width: 100%;
  }

  svg {
    max-width: 100%;
  }
`;
