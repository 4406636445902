import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginForms } from '../../components/login/LoginForms';
import { LoginGreeting } from '../../components/login/LoginGreeting';
import { HookMediaQueries } from '../../constants/MediaQueries';
import { appRoutes } from '../../constants/routes';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';
import { baseApi } from '../../services/baseApi';
import { useAppDispatch } from '../../store/hooks';

type Props = {
  reset: boolean;
};

export const Login = ({ reset }: Props) => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    dispatch(baseApi.util.resetApiState());
    if (isLoggedIn()) {
      navigate(appRoutes.Dashboard);
    }
  }, []);

  return (
    <Container isDesktop={isDesktop}>
      {isDesktop && <LoginGreeting />}
      <LoginForms reset={reset} />
    </Container>
  );
};

const Container = styled.div<{ isDesktop: boolean }>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${({ isDesktop }) =>
    !isDesktop &&
    `  
    background-image: url('/assets/login-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `}
`;
