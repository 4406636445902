import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../../constants/routes';
import { useAppDispatch } from '../../../store/hooks';
import {
  setDevice,
  setPassword,
  setEmail,
  clearUserManagementState,
  setSocialUser,
} from '../../../store/slices/userManagementSlice';
import { SocialUser } from '../social/SocialUser';

import { useDevice } from './useDevice';

export const useOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const device = useDevice();

  const onboardSocialUser = (socialUser: SocialUser) => {
    dispatch(clearUserManagementState());
    dispatch(setSocialUser(socialUser));
    startOnboarding();
  };

  const onboardUser = (email: string, password: string) => {
    dispatch(clearUserManagementState());
    dispatch(setEmail(email));
    dispatch(setPassword(password));
    startOnboarding();
  };

  const startOnboarding = () => {
    dispatch(setDevice(device));
    navigate(appRoutes.Onboarding);
  };

  return {
    onboardUser,
    onboardSocialUser,
  };
};
