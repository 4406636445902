import { IconProps, Icon } from './Icon';

export const SimpleBadgeIcon = ({ size, ...props }: IconProps) => (
  <Icon {...props} size={size}>
    <path
      d="M29.8381 0L25.3658 11.0348H16.0762L20.2053 0H29.8381Z"
      fill="#D35C18"
    />
    <path
      d="M25.7911 9.12109H15.7328C15.0725 9.12109 14.5371 9.66018 14.5371 10.3249V16.7742H26.9884V10.3249C26.9884 9.66018 26.4513 9.12109 25.7911 9.12109ZM26.1031 11.4842H15.1924V10.9914C15.1924 10.4556 15.6244 10.0207 16.1565 10.0207H25.139C25.6712 10.0207 26.1031 10.4556 26.1031 10.9914V11.4842Z"
      fill="#EFAE0C"
    />
    <path
      d="M11.752 0L16.0765 11.0348H25.3661L21.1384 0H11.752Z"
      fill="#EC671B"
    />
    <path
      d="M33.8834 28.1749C34.8518 20.7324 29.6445 13.9086 22.2525 12.9336C14.8604 11.9585 8.08289 17.2014 7.11444 24.6439C6.14598 32.0863 11.3533 38.9101 18.7454 39.8851C26.1374 40.8602 32.9149 35.6173 33.8834 28.1749Z"
      fill="#FFB600"
    />
    <path
      d="M31.4146 27.8485C32.2044 21.7788 27.9576 16.2137 21.929 15.4185C15.9005 14.6233 10.3731 18.8991 9.58329 24.9688C8.79346 31.0385 13.0403 36.6036 19.0688 37.3988C25.0974 38.1941 30.6248 33.9182 31.4146 27.8485Z"
      fill="#FFEC03"
    />
  </Icon>
);
