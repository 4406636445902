import { format, differenceInDays } from 'date-fns';
import { nl } from 'date-fns/locale';
import { useTranslations } from 'use-intl';

export const enum DateFormat {
  /* Example: 11 okt. 2024 09:10:42*/
  fullDateWithTime = 'd MMM yyyy HH:mm:ss',
  /* Example: 11 okt. 2024*/
  fullDate = 'dd MMM yyyy',
  /* Example: 11 oktober */
  shortDate = 'dd MMMM',
}

export const getSubjectDateString = (
  startDate: Date,
  t: ReturnType<typeof useTranslations>
) => {
  const days = differenceInDays(startDate, new Date());
  const startDateString = format(startDate, DateFormat.shortDate, {
    locale: nl,
  });
  return `${startDateString} ${t('still_x_amount_of_days_till_exam', { count: days })}`;
};

export const secondsToMMss = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const secondsToReadableText = (seconds: number) => {
  const roundedSeconds = Math.round(seconds);
  const minutes = Math.floor(roundedSeconds / 60);
  const remainingSeconds = roundedSeconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  let text = '';
  if (minutes > 0) {
    text += `${formattedMinutes}m `;
  }

  text += `${formattedSeconds}s`;

  return text;
};

export const HourDifferenceBetweenDates = (start: Date, end: Date) =>
  Math.floor((+end - +start) / 3600000);

export const DifferenceBetweenDateMinutes = (start: Date, end: Date) =>
  (end.getMinutes() - start.getMinutes() + 60) % 60;
