import styled from '@emotion/styled';

import { MediaQueries } from '../../constants/MediaQueries';

import { ProfileContent } from './ProfileContent';
import { ProfileLeftContent } from './ProfileLeftContent';

export const ProfileIndex = () => {
  return (
    <Container>
      <ProfileLeftContent></ProfileLeftContent>
      <ProfileContent></ProfileContent>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 80px;

  @media (min-width: ${MediaQueries.desktop}) {
    flex-direction: row;
  }
`;
