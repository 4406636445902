import { useIdle } from '@uidotdev/usehooks';
import { useContext, useEffect, useRef } from 'react';

import { TRACKING_PERIOD } from '../../../../constants/timeTracking';
import { SocketEvent } from '../../../../socket/socket.types';
import { SocketContext } from '../../../../socket/SocketProvider';

export const useTrackingEvent = () => {
  const { trackingSocket } = useContext(SocketContext);

  const idle = useIdle(TRACKING_PERIOD);
  const currentIntervalId = useRef<ReturnType<typeof setInterval> | undefined>(
    undefined
  );

  const getCleanPathWithoutColon = () => {
    const origin = location.origin;
    const href = location.href;
    return href.replace(origin, '').replace(/:/g, '');
  };

  useEffect(() => {
    clearInterval(currentIntervalId?.current);
    if (idle) {
      return;
    }

    currentIntervalId.current = setInterval(() => {
      if (document.visibilityState === 'visible') {
        //secure random string generator
        const random = Array.from(
          window.crypto.getRandomValues(new Uint8Array(Math.ceil(6 / 2))),
          (b) => ('0' + (b & 0xff).toString(16)).slice(-2)
        ).join('');

        trackingSocket?.current?.socket.emit(
          SocketEvent.timeTracking,
          `${getCleanPathWithoutColon()}:${random}`
        );
      }
    }, TRACKING_PERIOD);
    return () => clearInterval(currentIntervalId?.current);
  }, [idle, trackingSocket]);
};
