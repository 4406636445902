import { useNavigate, useParams } from 'react-router-dom';

import { allSubjectCategories } from '../../../constants/constants';
import { useUserManagement } from '../../../hooks/useUserManagement';
import { SubjectCategory } from '../../../models/Subject';
import { useGetSubjectsByCourseLevelQuery } from '../../../services/onboardingApi';

import { DifferentLevelSubjectCard } from './DifferentLevelSubjectCard';

export const SelectExtraSubjects = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  const categoryEnum = category as SubjectCategory;

  const userManagementState = useUserManagement();
  const courseLevel = userManagementState.courseLevel || '';

  const { data: subjects } = useGetSubjectsByCourseLevelQuery({
    courseLevelEncoded: courseLevel,
    categories: allSubjectCategories,
  });

  if (!subjects || !category) {
    return <></>;
  }

  return (
    <DifferentLevelSubjectCard
      mainCourseLevel={courseLevel}
      category={categoryEnum}
      onContinue={() => navigate(-1)}
      onBack={() => navigate(-1)}
    />
  );
};
