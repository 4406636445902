import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { AppRoutePathNames } from '../../constants/routes';
import { useAppSelector } from '../../store/hooks';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { MobileCartIcon } from '../Icons/MobileCardIcon';

export const CartNavComponent = () => {
  const navigate = useNavigate();
  const cartItems = useAppSelector((state) => state.cart.items);
  return (
    <>
      {!!cartItems.length && (
        <CarIconContainer
          onClick={() => navigate(`/${AppRoutePathNames.Cart}`)}
        >
          <MobileCartIcon />
          <Circle>{cartItems.length}</Circle>
        </CarIconContainer>
      )}
    </>
  );
};

const CarIconContainer = styled.div`
  position: absolute;
  right: 16px;
  cursor: pointer;
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: ${Colors.primary};
  width: 16px;
  height: 16px;
  color: ${Colors.primaryTextInverted};
  ${TextStyles.NotificationDotText};
  position: absolute;
  font-size: xx-small;
  top: -8px;
  left: 10px;
`;
