import { IconProps, Icon } from './Icon';

export const MenuIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 14C0.75 13.3096 1.30964 12.75 2 12.75H14.5C15.1904 12.75 15.75 13.3096 15.75 14C15.75 14.6904 15.1904 15.25 14.5 15.25H2C1.30964 15.25 0.75 14.6904 0.75 14Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 8C0.75 7.30964 1.30964 6.75 2 6.75H18C18.6904 6.75 19.25 7.30964 19.25 8C19.25 8.69036 18.6904 9.25 18 9.25H2C1.30964 9.25 0.75 8.69036 0.75 8Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 2C0.75 1.30964 1.30964 0.75 2 0.75H18C18.6904 0.75 19.25 1.30964 19.25 2C19.25 2.69036 18.6904 3.25 18 3.25H2C1.30964 3.25 0.75 2.69036 0.75 2Z"
      fill="black"
    />
  </Icon>
);
