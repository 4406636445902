import styled from '@emotion/styled';

import { PaymentStatusCard } from '../../components/payment/PaymentStatusCard';

export const Payment = () => {
  return (
    <Container>
      <PaymentStatusCard />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
`;
