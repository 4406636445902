import { useProductAccess } from '../../../../hooks/useProductAccess';
import { Section, Sections } from '../../../../models/Summary/summary';
import { SummaryMode } from '../utils/summaryUtils';

type Props = {
  summarySections: Sections | undefined;
  summaryMode: SummaryMode;
};

export const useSideMenuSectionAccess = ({
  summarySections,
  summaryMode,
}: Props) => {
  const { hasChapterSectionAccess, hasExplanationVideoAccess } =
    useProductAccess();

  return (section: Section) => {
    if (!summarySections) return true;

    switch (summaryMode) {
      case SummaryMode.Summary:
        return hasChapterSectionAccess(section, summarySections);
      case SummaryMode.Video:
        return hasExplanationVideoAccess(section, summarySections);
      case SummaryMode.Both:
        return (
          hasChapterSectionAccess(section, summarySections) ||
          hasExplanationVideoAccess(section, summarySections)
        );
      default:
        return true;
    }
  };
};
