import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../../constants/Sizes';
import { Messages } from '../../../global.d';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';

import { ReportQuestion } from './ReportQuestion';

type Props = {
  titleKey: keyof Messages;
  showFlagQuestion: boolean;
  thesisId: string;
};

export const HeaderLeft = ({ titleKey, showFlagQuestion, thesisId }: Props) => {
  const t = useTranslations();
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);

  return (
    <Container>
      <Title>{t(titleKey)}</Title>
      {showFlagQuestion && isDesktop && <ReportQuestion thesisId={thesisId} />}
    </Container>
  );
};

const Container = styled.div`
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: ${HEADER_HEIGHT_PX}px;
  background-color: ${Colors.modalBackgroundColor};

  border-bottom: 1px solid ${Colors.dividerColor};

  @media ${HookMediaQueries.isDesktop} {
    display: flex;
  }
`;
const Title = styled.h1`
  flex: 1;
  text-align: center;
  ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
