import styled from '@emotion/styled';

import { CartItemCard } from '../../components/cart/CartItemCard';
import { DiscountCard } from '../../components/cart/DiscountCard';
import { ShippingAndPayment } from '../../components/cart/ShippingAndPayment';
import { CardBase } from '../../components/common/CardBase';
import { RecommendationCart } from '../../components/RecomendationCart';
import { HookMediaQueries } from '../../constants/MediaQueries';

export const Cart = () => {
  return (
    <Container>
      <LeftContainer>
        <CartItemCard />

        <DiscountCard />

        <RecommendationCart />
      </LeftContainer>

      <ShippingFormContainer fullWidth={true}>
        <ShippingAndPayment />
      </ShippingFormContainer>
    </Container>
  );
};

const ShippingFormContainer = styled(CardBase)`
  flex: 1;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;

  @media ${HookMediaQueries.isDesktop} {
    flex-direction: row;
  }
`;

const LeftContainer = styled.div`
  flex: 2;
`;
