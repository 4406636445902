import styled from '@emotion/styled';

import { useGetUserQuery } from '../../../services/userApi';

type Props = {
  color: string;
};

export const Watermark = ({ color }: Props) => {
  const { data: user } = useGetUserQuery();

  return <Container color={color}>{user?.email}</Container>;
};
const Container = styled.div<{ color: string }>`
  ${({ color }) => color && `background-color: ${color};`};
  position: fixed;
  top: 100vh;
  left: 100vw;
  opacity: 0.75;
  width: 190px;
  z-index: 1;
  color: white;
  transform: rotate(-40deg) translate(-78px, -150px);
  overflow: hidden;
  padding: 6px 24px;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  transition: opacity 0.5s ease;
`;
