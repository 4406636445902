import { motion, AnimatePresence } from 'framer-motion';
import { ReactElement } from 'react';

export const StyledScroller = ({
  isVisible,
  children,
}: {
  isVisible: boolean;
  children: ReactElement;
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="scroller"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <div className="scroller">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
