import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';

import { MediaQueries } from '../../../constants/MediaQueries';
import { makeSubjectOldExamsRoute } from '../../../constants/routes';
import { OldExam } from '../../../models/OldExams';
import { TextStyles } from '../../../styles/textStyles';
import { CardBase } from '../../common/CardBase';

import { SelectableExamPeriodComponent } from './SelectableExamPeriodComponent';

export enum ExamPeriod {
  firstPeriod = '1',
  secondPeriod = '2',
}

export const OldExamDetailsComponent = ({ oldExam }: { oldExam: OldExam }) => {
  const { encodedSubjectName } = useParams();
  const navigate = useNavigate();
  const onPeriodPress = (examYear: number, examPeriod: number) => {
    if (encodedSubjectName) {
      navigate(
        makeSubjectOldExamsRoute(
          encodedSubjectName,
          String(examYear),
          String(examPeriod)
        )
      );
    }
  };

  return (
    <Container>
      <ExamYear>{oldExam.year}</ExamYear>
      <SelectableItemsContainer>
        {Object.values(ExamPeriod).map((period) => (
          <SelectableExamPeriodComponent
            key={period}
            onPress={() => onPeriodPress(oldExam.year, Number(period))}
            examPeriod={Number(period)}
          />
        ))}
      </SelectableItemsContainer>
    </Container>
  );
};

const Container = styled.div`
  ${TextStyles.OldExamsBase}
  display: flex;
  @media (max-width: ${MediaQueries.desktop}) {
    flex-direction: column;
    width: 100%;
  }
`;

const SelectableItemsContainer = styled.div`
  display: flex;
  @media (max-width: ${MediaQueries.desktop}) {
    margin: 0 20px;
  }
`;

const ExamYear = styled(CardBase)`
  margin: 0 20px 0 0;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  @media (max-width: ${MediaQueries.desktop}) {
    width: unset;
    margin: 16px 20px;
    height: 50px;
    border-radius: 10px;
  }
`;
