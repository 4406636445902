import styled from '@emotion/styled';

export const LoginCardHeader = () => {
  return <Logo src="/assets/logo.png" width={100} />;
};

const Logo = styled.img`
  margin: auto;
  height: 100%;
`;
