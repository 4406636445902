import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { SecondaryButton } from '../common/Button';
import { CartIcon } from '../Icons/CartIcon';

export const CardButton = ({
  cartItemsAmount,
}: {
  cartItemsAmount: number;
}) => {
  const navigate = useNavigate();

  return (
    <CartButton buttonSize="md" onClick={() => navigate('/cart')}>
      <ButtonContainer>
        <StyledCartIcon />
        <Circle>{cartItemsAmount}</Circle>
      </ButtonContainer>
    </CartButton>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const Circle = styled.div`
  border-radius: 50%;
  background-color: ${Colors.primary};
  width: 16px;
  height: 16px;
  color: ${Colors.primaryTextInverted};
  ${TextStyles.NotificationDotText};
`;
const StyledCartIcon = styled(CartIcon)`
  width: 16px;
  height: 16px;
`;
const CartButton = styled(SecondaryButton)`
  padding: 12px;
`;
