import { useCallback, RefObject } from 'react';

import { SubCategory } from '../../../../models/Definition';

export const useScrollToSubCategory = (
  subCategoryRefs: RefObject<{ [key: string]: HTMLElement | null }>
) => {
  const scrollBehavior = 'auto';
  const scrollToSubCategoryYOffset = -200;

  const scrollToSubCategory = useCallback(
    (to: SubCategory) => {
      const subCategoryElement = subCategoryRefs.current?.[to._id];

      if (subCategoryElement) {
        subCategoryElement.scrollIntoView({ behavior: scrollBehavior });

        const rect = subCategoryElement.getBoundingClientRect();
        const y = rect.top + window.scrollY + scrollToSubCategoryYOffset;

        window.scrollTo({ top: y, behavior: scrollBehavior });
      }
    },
    [subCategoryRefs, scrollToSubCategoryYOffset]
  );

  return scrollToSubCategory;
};
