import styled from '@emotion/styled';

import { BadgesCard } from '../../components/dashboard/BadgesCard';
import { CompleteProfileCard } from '../../components/dashboard/CompleteProfileCard';
import { ContinueLearningCard } from '../../components/dashboard/ContinueLearningCard';
import { DashboardSubjectScroller } from '../../components/dashboard/DashboardSubjectScroller';
import { LeaderBoardCard } from '../../components/dashboard/LeaderBoard/LeaderBoardCard';
import { ProductCodeCard } from '../../components/dashboard/ProductCodeCard';
import { TipOfTheDayCard } from '../../components/dashboard/TipOfTheDayCard';
import { VideoOfTheDayCard } from '../../components/dashboard/VideoOfTheDayCard';
import { WelcomeMessageCard } from '../../components/dashboard/WelcomeMessageCard';
import { RecommendationCart } from '../../components/RecomendationCart';
import { StyledScroller } from '../../components/subject/home/AnimatedDiv';
import { useWelcomingGift } from '../../hooks/useWelcomingGift';
import { useGetUserQuery } from '../../services/userApi';
import { useAppSelector } from '../../store/hooks';

export const Dashboard = () => {
  useWelcomingGift();
  const userState = useAppSelector((state) => state.user);
  const { data: user } = useGetUserQuery();

  const showCompleteProfileCard = () => {
    return (user?.nickname && user?.nickname.isGenerated) || !user?.school;
  };

  return (
    <DashboardContainer>
      <DashboardSubjectScroller />
      <StyledScroller isVisible={true}>
        <>
          {userState.newUser && <WelcomeMessageCard />}
          <Container>
            <StyledColumn flex={3}>
              {showCompleteProfileCard() && <CompleteProfileCard />}
              <ContinueLearningCard />
              <BadgesCard />
              <RecommendationCart />
            </StyledColumn>
            <StyledColumn flex={2}>
              <ProductCodeCard />
              <LeaderBoardCard />
              <TipOfTheDayCard />
              <VideoOfTheDayCard />
            </StyledColumn>
          </Container>
        </>
      </StyledScroller>
    </DashboardContainer>
  );
};
const DashboardContainer = styled.div`
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media only screen and (min-width: 1120px) {
    flex-direction: row;
  }
`;
const StyledColumn = styled.div<{
  flex?: number;
  margin?: string;
}>`
  flex: ${(props) => props.flex || 1};
  margin: ${(props) => props.margin};
`;
