import { IconProps, Icon } from './Icon';

export const ProfileIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color={color}>
    <path
      d="M12.1786 4.5C12.1786 4.05679 12.0899 3.61792 11.9176 3.20844C11.7453 2.79897 11.4927 2.42691 11.1744 2.11351C10.856 1.80012 10.478 1.55152 10.0621 1.38191C9.64608 1.2123 9.20025 1.125 8.75 1.125C8.29975 1.125 7.85392 1.2123 7.43794 1.38191C7.02197 1.55152 6.64401 1.80012 6.32563 2.11351C6.00726 2.42691 5.75472 2.79897 5.58241 3.20844C5.41011 3.61792 5.32143 4.05679 5.32143 4.5C5.32143 4.94321 5.41011 5.38208 5.58241 5.79156C5.75472 6.20103 6.00726 6.57309 6.32563 6.88649C6.64401 7.19988 7.02197 7.44848 7.43794 7.61809C7.85392 7.7877 8.29975 7.875 8.75 7.875C9.20025 7.875 9.64608 7.7877 10.0621 7.61809C10.478 7.44848 10.856 7.19988 11.1744 6.88649C11.4927 6.57309 11.7453 6.20103 11.9176 5.79156C12.0899 5.38208 12.1786 4.94321 12.1786 4.5ZM4.17857 4.5C4.17857 3.30653 4.6602 2.16193 5.51751 1.31802C6.37482 0.474106 7.53758 0 8.75 0C9.96242 0 11.1252 0.474106 11.9825 1.31802C12.8398 2.16193 13.3214 3.30653 13.3214 4.5C13.3214 5.69347 12.8398 6.83807 11.9825 7.68198C11.1252 8.52589 9.96242 9 8.75 9C7.53758 9 6.37482 8.52589 5.51751 7.68198C4.6602 6.83807 4.17857 5.69347 4.17857 4.5ZM1.89286 16.875H15.6071C15.5643 14.073 13.2429 11.8125 10.3821 11.8125H7.11786C4.26071 11.8125 1.93929 14.073 1.89286 16.875ZM0.75 16.9559C0.75 13.493 3.6 10.6875 7.11786 10.6875H10.3821C13.9 10.6875 16.75 13.493 16.75 16.9559C16.75 17.5324 16.275 18 15.6893 18H1.81071C1.225 18 0.75 17.5324 0.75 16.9559Z"
      fill={color}
    />
  </Icon>
);
