import { ApiTagTypes } from '../constants/api';
import { UserBadge, UserBadgesUnlockedCount } from '../models/Badge';
import { School } from '../models/ProductBundle';
import { UserSubject } from '../models/Subject';
import { Tip } from '../models/Tip';
import { User } from '../models/User';
import { RegisterSocialUserBody } from '../models/user-management/RegisterSocialUserBody';
import { Video } from '../models/Video';

import { baseApi } from './baseApi';

export const dashboardApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [
      ApiTagTypes.UserSubjects,
      ApiTagTypes.Schools,
      ApiTagTypes.User,
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getUserSubjects: build.query<UserSubject[], void>({
        query: () => ({
          url: `/user/subjects`,
        }),
        providesTags: [ApiTagTypes.UserSubjects],
      }),
      putUsername: build.mutation<User, { nickname: string }>({
        query: (body) => ({
          url: `/user/edit/profile`,
          method: 'PUT',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      updateUserSchool: build.mutation<User, { schoolId: string }>({
        query: (body) => ({
          url: `/user/edit/profile`,
          method: 'PUT',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      updateUserPassword: build.mutation<
        User,
        { curpassword: string; password: string }
      >({
        query: (body) => ({
          url: `/user/edit/profile`,
          method: 'PUT',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      updateUserPicture: build.mutation<User, { picture: string }>({
        query: (body) => ({
          url: `/user/edit/profilepicture`,
          method: 'PUT',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      patchSocialUser: build.mutation<User, RegisterSocialUserBody>({
        query: (body) => ({
          url: `/user/edit/profile`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: [ApiTagTypes.User],
      }),
      getSchools: build.query<School[], string>({
        query: (query) => ({
          url: `/schools?search=${query}`,
        }),
        providesTags: [ApiTagTypes.Schools],
      }),
      getSchoolSuggestion: build.query<School, void>({
        query: () => ({
          url: `/schools/suggestion`,
        }),
        providesTags: [ApiTagTypes.Schools],
      }),
      getFeaturedBadges: build.query<UserBadge, void>({
        query: () => ({
          url: `/user/badges/featured`,
        }),
      }),
      getUserBadgesUnlockedCount: build.query<UserBadgesUnlockedCount, void>({
        query: () => ({
          url: `/user/badges/unlocked/count`,
        }),
      }),
      getTipOfTheDay: build.query<Tip, void>({
        query: () => ({
          url: `/daily/tip`,
        }),
      }),
      getVideoOfTheDay: build.query<Video, void>({
        query: () => ({
          url: `/daily/video`,
        }),
      }),
      putProductPair: build.mutation<void, { accessCode: string }>({
        query: (body) => ({
          url: `/user/product/pair`,
          method: 'PUT',
          body: body,
        }),
      }),
    }),
  });

export const {
  useGetUserSubjectsQuery,
  usePutUsernameMutation,
  useGetSchoolsQuery,
  useGetSchoolSuggestionQuery,
  useLazyGetSchoolSuggestionQuery,
  useUpdateUserSchoolMutation,
  useGetFeaturedBadgesQuery,
  useGetTipOfTheDayQuery,
  useGetVideoOfTheDayQuery,
  usePutProductPairMutation,
  useGetUserBadgesUnlockedCountQuery,
  useUpdateUserPictureMutation,
  useUpdateUserPasswordMutation,
  usePatchSocialUserMutation,
} = dashboardApi;
