import { IconProps, Icon } from './Icon';

export const NoSearchResultsIcon = ({ size, color }: IconProps) => (
  <Icon size={size} color={color}>
    <path
      d="M16.75 3.75C20.0652 3.75 23.2446 5.06696 25.5888 7.41116C27.933 9.75537 29.25 12.9348 29.25 16.25C29.25 19.5652 27.933 22.7446 25.5888 25.0888C23.2446 27.433 20.0652 28.75 16.75 28.75C13.4348 28.75 10.2554 27.433 7.91116 25.0888C5.56696 22.7446 4.25 19.5652 4.25 16.25C4.25 12.9348 5.56696 9.75537 7.91116 7.41116C10.2554 5.06696 13.4348 3.75 16.75 3.75ZM16.75 32.5C20.5625 32.5 24.0703 31.1875 26.8359 28.9922L37.2969 39.4531C38.0312 40.1875 39.2187 40.1875 39.9453 39.4531C40.6719 38.7187 40.6797 37.5312 39.9453 36.8047L29.4922 26.3359C31.6875 23.5703 33 20.0625 33 16.25C33 7.27344 25.7266 0 16.75 0C7.77344 0 0.5 7.27344 0.5 16.25C0.5 25.2266 7.77344 32.5 16.75 32.5Z"
      fill={color}
    />
    <path
      d="M10.5628 19.7854C9.82809 20.5201 9.82809 21.7023 10.5628 22.437C11.2975 23.1718 12.4797 23.1718 13.2145 22.437L16.75 18.9015L20.2855 22.437C21.0203 23.1718 22.2025 23.1718 22.9372 22.437C23.6719 21.7023 23.6719 20.5201 22.9372 19.7854L19.4017 16.2499L22.9372 12.7143C23.6719 11.9796 23.6719 10.7974 22.9372 10.0627C22.2025 9.32794 21.0203 9.32794 20.2855 10.0627L16.75 13.5982L13.2145 10.0627C12.4797 9.32794 11.2975 9.32794 10.5628 10.0627C9.82809 10.7974 9.82809 11.9796 10.5628 12.7143L14.0984 16.2499L10.5628 19.7854Z"
      fill={color}
    />
  </Icon>
);
