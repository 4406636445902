import styled from '@emotion/styled';
import { useMediaQuery } from '@uidotdev/usehooks';
/* eslint-disable import/no-unresolved */
import { Navigation, FreeMode } from 'swiper/modules';
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { HookMediaQueries } from '../../constants/MediaQueries';
import { useGetUserSubjectsQuery } from '../../services/dashboardApi';

import { SubjectScrollItem } from './SubjectScrollItem';

export const DashboardSubjectScroller = () => {
  const isDesktop = useMediaQuery(HookMediaQueries.isDesktop);
  const { data: subjects } = useGetUserSubjectsQuery();

  return (
    <ScrollContainer>
      <Swiper
        modules={[Navigation, FreeMode]}
        spaceBetween={22}
        slidesPerView={isDesktop ? 8 : 'auto'}
        slidesPerGroup={8}
        freeMode={!isDesktop}
        navigation={isDesktop}
        className="mySwiper"
      >
        {subjects?.map((userSubject) => (
          <StyledSwiperSlide key={userSubject.subject._id}>
            <SubjectScrollItem
              active={
                userSubject.subject.hasSummary ||
                userSubject.subject.hasExercise
              }
              subjectName={userSubject.subject.name}
              imageUrl={userSubject.subject.subjectImage}
              subjectEncodedName={userSubject.subject.encodedName}
              grade={userSubject.subject.subjectRating}
            />
          </StyledSwiperSlide>
        ))}
      </Swiper>
    </ScrollContainer>
  );
};
const ScrollContainer = styled.div`
  padding-top: 20px;
`;
const StyledSwiperSlide = styled(SwiperSlide)`
  width: auto;
`;
