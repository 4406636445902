import { z } from 'zod';

export type FormData = z.infer<typeof OpenQuestionSchema>;

export enum FormFields {
  answer = 'answer',
}

export const OpenQuestionSchema = z.object({
  answer: z.string().min(1, { message: 'answer_required' }),
});
