import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { MediaQueries } from '../../constants/MediaQueries';
import { HEADER_HEIGHT_PX } from '../../constants/Sizes';
import { ZIndex } from '../../constants/Zindex';
import { Colors } from '../../styles/colors';

export const BaseNavHeader = ({ children }: PropsWithChildren) => {
  return (
    <Container>
      <Navbar>{children}</Navbar>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  z-index: ${ZIndex.z40};
  box-shadow: 0px 0px 10px 0px ${Colors.navbarBoxShadow};
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(12px + env(safe-area-inset-top)) 0 12px 0;
  background-color: ${Colors.primaryNavbarBackgroundColor};
  height: calc(${HEADER_HEIGHT_PX - 24}px);
`;
const Navbar = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1080px;
  position: relative;
  @media (min-width: ${MediaQueries.desktop}) {
    justify-content: space-between;
  }
`;
