import {
  BaseQueryApi,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { authorizationFreeEndpoints } from '../constants/api';
import { appRoutes } from '../constants/routes';
import { Token } from '../models/User';
import { setToken } from '../store/slices/userSlice';
import { RootState } from '../store/store';
import { clearSessionData } from '../utils/logoutUtils';

export type Error = {
  status: number;
  data: {
    message: string;
    statusCode: number;
  };
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    if (authorizationFreeEndpoints.includes(endpoint)) {
      return headers;
    }
    const userState = (getState() as RootState).user;
    const token = userState.token?.token;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

let refreshTokenPromise: Promise<void> | null = null;

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (authorizationFreeEndpoints.includes(api.endpoint)) {
    return result;
  }

  if (result.error && result.error.status === 401) {
    const userState = (api.getState() as RootState).user;
    const refreshToken = userState.token?.refreshToken;

    if (refreshToken) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = (async () => {
          const refreshResult = await baseQuery(
            {
              url: '/auth/refresh',
              method: 'POST',
              body: { refreshToken },
            },
            api,
            extraOptions
          );

          if (refreshResult.data) {
            const refreshData = refreshResult.data as Token;

            api.dispatch(
              setToken({
                token: refreshData.token,
                refreshToken: refreshData.refreshToken,
              })
            );
          } else {
            logout(api);
          }
          refreshTokenPromise = null;
        })();
      }

      await refreshTokenPromise;

      result = await baseQuery(args, api, extraOptions);
    } else {
      logout(api);
    }
  }

  return result;
};

export const logout = (api: BaseQueryApi) => {
  clearSessionData(api.dispatch, api.getState as () => RootState);
  window.location.pathname = appRoutes.Login;
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
