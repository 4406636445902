import { HeaderRight } from './HeaderRight';

type Props = {
  amountOfQuestions: number;
  currentQuestionIndex: number;
  thesisId: string;
};

export const ThesisHeader = ({
  amountOfQuestions,
  currentQuestionIndex,
  thesisId,
}: Props) => {
  return (
    <HeaderRight
      currentQuestionIndex={currentQuestionIndex}
      amountOfQuestions={amountOfQuestions}
      showQuestionAmount={true}
      thesisId={thesisId}
    />
  );
};
