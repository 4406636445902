import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { SecondaryButton, PrimaryButton } from '../components/common/Button';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { CartAdd } from './Icons/CartAdd';
import { CloseIcon } from './Icons/CloseIcon';

export enum CardType {
  addToCart = 'addToCart',
  removeFromCart = 'removeFromCart',
}

type ListItemSubjectProps = {
  image: string;
  title: string;
  subtitle: string;
  price?: string;
  labelText?: string;
  onPress?: () => void;
  oldPrice?: string;
  discountPercentage?: number;
  cardType?: CardType;
};

export const CartItem = ({
  image,
  title,
  subtitle,
  price,
  onPress,
  oldPrice,
  discountPercentage,
  cardType = CardType.addToCart,
  ...props
}: ListItemSubjectProps) => {
  const t = useTranslations();

  const renderButton = () => {
    switch (cardType) {
      case CardType.addToCart:
        return (
          <StyledPrimaryButton buttonSize="sm" onClick={onPress}>
            <CartAdd size="lg" color={Colors.primaryButtonContentColor} />
          </StyledPrimaryButton>
        );

      case CardType.removeFromCart:
        return (
          <StyledSecondaryButton
            buttonSize="sm"
            textSize="lg"
            onClick={onPress}
          >
            <CloseIcon />
          </StyledSecondaryButton>
        );

      default:
        break;
    }
  };
  return (
    <Container {...props}>
      <Image src={image} alt="Product Image" />
      <UpsellDetails>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <UpsellContainer>
          {oldPrice && <OldPrice>{oldPrice}</OldPrice>}
          <Price>{price}</Price>
          {discountPercentage && (
            <DiscountPercentageContainer>
              {t('discount_percentage', { count: discountPercentage })}
            </DiscountPercentageContainer>
          )}
        </UpsellContainer>
      </UpsellDetails>
      {renderButton()}
    </Container>
  );
};

const DiscountPercentageContainer = styled.span`
  ${TextStyles.DiscountPercentageText}
  background-color:${Colors.discountPercentageBackground};
  color: ${Colors.discountPercentageText};
  border-radius: 4px;
  padding: 2px;
`;

const UpsellContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: 'red';
  gap: 4px;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;

const Title = styled.div`
  ${TextStyles.ListItemTitle}
`;
const Subtitle = styled.div`
  color: ${Colors.secondaryTextColor};
  ${TextStyles.ListItemSubtitle}
`;
const Price = styled.span`
  color: ${Colors.primaryButtonFill};
  ${TextStyles.PriceBigText}
`;
const OldPrice = styled.span`
  text-decoration: line-through;
  color: ${Colors.secondaryTextColor};
  ${TextStyles.PriceSmallText}
`;
const UpsellDetails = styled.div`
  gap: 4px;
  flex: 1;
  justify-content: center;
  align-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 68px;
  max-height: 80px;
  object-fit: contain;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  border-radius: 50px;
  display: flex;
  padding: 6px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
`;
