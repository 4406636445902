import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeviceInfo } from '../../components/login/hooks/useDevice';
import { SocialUser } from '../../components/login/social/SocialUser';
import { Gender } from '../../constants/gender';
import { Subject } from '../../models/Subject';
import { RegisterRequest } from '../../models/user-management/RegisterRequest';
import { Slices } from '../Slices';

const initialState: RegisterRequest = {};

const userManagementSlice = createSlice({
  name: Slices.UserManagement,
  initialState,
  reducers: {
    setAccountType(state, action: PayloadAction<number>) {
      state.accountType = action.payload;
    },
    setCourseLevel(state, action: PayloadAction<string>) {
      state.courseLevel = action.payload;
    },
    setTeacherCourseLevels(state, action: PayloadAction<string[]>) {
      state.teacherCourseLevels = action.payload;
    },
    addTeacherCourseLevels(state, action: PayloadAction<string>) {
      if (!state.teacherCourseLevels) {
        state.teacherCourseLevels = [];
      }
      state.teacherCourseLevels.push(action.payload);
    },
    removeTeacherCourseLevels(state, action: PayloadAction<string>) {
      if (state.teacherCourseLevels) {
        state.teacherCourseLevels = state.teacherCourseLevels.filter(
          (courseLevel) => courseLevel !== action.payload
        );
      }
    },
    clearCourseLevels(state) {
      state.teacherCourseLevels = undefined;
      state.courseLevel = undefined;
    },
    setDevice(state, action: PayloadAction<DeviceInfo>) {
      state.device = action.payload;
    },
    setDifficultSections(state, action: PayloadAction<string[]>) {
      state.difficultSections = action.payload;
    },
    setDifficultSubjects(state, action: PayloadAction<string[]>) {
      state.difficultSubjects = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setGender(state, action: PayloadAction<Gender>) {
      state.gender = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setSocialUser(state, action: PayloadAction<SocialUser>) {
      state.socialUser = action.payload;
    },
    setPreparation(state, action: PayloadAction<number>) {
      if (!state.registrationGoal) {
        state.registrationGoal = {};
      }
      state.registrationGoal.preparation = action.payload;
    },
    addStudentType(state, action: PayloadAction<string>) {
      if (!state.registrationGoal) {
        state.registrationGoal = {};
      }

      if (!state.registrationGoal?.studentTypes) {
        state.registrationGoal.studentTypes = [];
      }
      state.registrationGoal.studentTypes.push(action.payload);
    },
    removeStudentType(state, action: PayloadAction<string>) {
      if (state.registrationGoal?.studentTypes) {
        state.registrationGoal.studentTypes =
          state.registrationGoal.studentTypes.filter(
            (studentType) => studentType !== action.payload
          );
      }
    },
    setSubjects(state, action: PayloadAction<Subject[]>) {
      state.subjects = action.payload;
    },
    addSubject(state, action: PayloadAction<Subject>) {
      if (!state.subjects) {
        state.subjects = [];
      }
      state.subjects.push(action.payload);
    },
    removeSubject(state, action: PayloadAction<string>) {
      if (state.subjects) {
        state.subjects = state.subjects.filter(
          (subject) => subject._id !== action.payload
        );
      }
    },
    removeAllSubjects(state) {
      state.subjects = undefined;
    },
    clearUserManagementState(state) {
      state.accountType = undefined;
      state.courseLevel = undefined;
      state.device = undefined;
      state.difficultSections = undefined;
      state.difficultSubjects = undefined;
      state.email = undefined;
      state.gender = undefined;
      state.password = undefined;
      state.subjects = undefined;
      state.registrationGoal = undefined;
      state.teacherCourseLevels = undefined;
      state.provider = undefined;
      state.socialUser = undefined;
    },
  },
});

export const {
  setAccountType,
  setCourseLevel,
  setTeacherCourseLevels,
  addTeacherCourseLevels,
  removeTeacherCourseLevels,
  clearCourseLevels,
  setDevice,
  setDifficultSections,
  setDifficultSubjects,
  setEmail,
  setGender,
  setPassword,
  setPreparation,
  addStudentType,
  removeStudentType,
  setSubjects,
  addSubject,
  removeSubject,
  removeAllSubjects,
  clearUserManagementState,
  setSocialUser,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
