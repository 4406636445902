import { skipToken } from '@reduxjs/toolkit/query';

import { useGetBuyAbleProductBundlesForUserBySubjectIdQuery } from '../../../../services/userApi';
import { useAppSelector } from '../../../../store/hooks';
import {
  BuyableType,
  getProductTypePrefixFromBuyableType,
} from '../utils/summaryUtils';

const MAX_NUMBER_OF_DISPLAYED_BUNDLES = 2;
const EXERCISES_PRODUCT_IDENTIFIER = 'Oefenboek';

export const useRecommendedBundles = (
  subjectId?: string,
  buyableTypeFilter?: BuyableType,
  isRetrieveAll = true
) => {
  const { data: buyableBundles } =
    useGetBuyAbleProductBundlesForUserBySubjectIdQuery(subjectId ?? skipToken);

  const cartItems = useAppSelector((state) => state.cart.items);
  const carItemsIds = cartItems.map((item) => item._id);

  const productIsExercise = (description: string) =>
    description !== EXERCISES_PRODUCT_IDENTIFIER;

  const getRecommended = () => {
    if (buyableBundles) {
      const bundles = [...buyableBundles].filter((product) => {
        const isFilteredType =
          !buyableTypeFilter ||
          product.products.some((product) =>
            product.startsWith(
              getProductTypePrefixFromBuyableType(buyableTypeFilter)
            )
          );

        return (
          !carItemsIds.includes(product._id) &&
          productIsExercise(product.description) &&
          isFilteredType
        );
      });
      if (isRetrieveAll) {
        return bundles;
      }
      return bundles.slice(0, MAX_NUMBER_OF_DISPLAYED_BUNDLES);
    }
    return [];
  };
  return getRecommended();
};
