import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { PrimaryButton, SecondaryButton } from '../../common/Button';

type ForgotPasswordFooterProps = {
  mailSuccessfullySent: boolean;
  hasBeenSentForTheSecondTime: boolean;
  onBackClick: () => void;
  sendDisabled: boolean;
};

export const ForgotPasswordFooter = ({
  mailSuccessfullySent,
  hasBeenSentForTheSecondTime,
  onBackClick,
  sendDisabled,
}: ForgotPasswordFooterProps) => {
  const t = useTranslations();
  if (mailSuccessfullySent) {
    return (
      <>
        {hasBeenSentForTheSecondTime ? (
          <ResendText>{t('we_have_sent_you_a_new_e-mail')}</ResendText>
        ) : (
          <SecondaryButton
            buttonSize="md"
            type="submit"
            disabled={sendDisabled}
          >
            {t('send_again')}
          </SecondaryButton>
        )}
        <PrimaryButton buttonSize="md" onClick={onBackClick}>
          {t('back_to_login')}
        </PrimaryButton>
      </>
    );
  }

  return (
    <PrimaryButton buttonSize="md" type="submit">
      {t('send')}
    </PrimaryButton>
  );
};

const ResendText = styled.p`
  color: ${Colors.primaryTextColor};
  ${TextStyles.InputHintText}
`;
