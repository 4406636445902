import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { ProductBundle } from '../models/ProductBundle';
import { useGetBuyAbleProductBundlesForUserQuery } from '../services/userApi';
import { useAppSelector } from '../store/hooks';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { CardBase } from './common/CardBase';
import { BuyableItem } from './dashboard/BuyableItem';

const MAX_NUMBER_OF_DISPLAYED_BUNDLES = 3;

export const RecommendationCart = () => {
  const { data: buyableBundles } = useGetBuyAbleProductBundlesForUserQuery();
  const t = useTranslations();

  const cartItems = useAppSelector((state) => state.cart.items);

  // Function to filter and get recommended bundles
  const getRecommendedBundles = (): ProductBundle[] => {
    if (!buyableBundles) return [];

    // Filter out products already in the cart and sort by price
    const recommendations = buyableBundles
      .filter(
        (product) =>
          !cartItems.find(
            (cartItem: ProductBundle) => cartItem._id === product._id
          )
      )
      .sort((a, b) => b.price - a.price) // Sort by price in descending order
      .slice(0, MAX_NUMBER_OF_DISPLAYED_BUNDLES); // Limit the number of displayed bundles

    return recommendations;
  };

  const recommendedBundles = getRecommendedBundles();

  return (
    <CardBase
      fullWidth={true}
      header={<Title>{t('recommended_for_you')}</Title>}
    >
      {recommendedBundles?.map((buyableProduct, i) => (
        <BuyableItem key={i} productBundle={buyableProduct} />
      ))}
    </CardBase>
  );
};
const Title = styled.h1`
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
