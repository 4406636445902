import { useGetUserProductsQuery } from '../../services/userApi';
import { CardBase } from '../common/CardBase';

import { ProductRow } from './products/ProductRow';
import { ProfileCardHeader } from './ProfileCardHeader';

export const MyProductsCard = () => {
  const { data: products } = useGetUserProductsQuery();

  if (!products?.length) {
    return <></>;
  }

  return (
    <CardBase
      fullWidth={true}
      fullWidthBody={true}
      header={<ProfileCardHeader titleTranslationKey="profile_menu_products" />}
    >
      {products.map((product) => {
        return <ProductRow key={product._id} product={product} />;
      })}
    </CardBase>
  );
};
