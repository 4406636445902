import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { mainRoutesWithLogo } from '../../constants/routes';
import { HEADER_HEIGHT_PX } from '../../constants/Sizes';
import { ZIndex } from '../../constants/Zindex';
import { Colors } from '../../styles/colors';

import { NavBarTab } from './NavBarTab';

export const MobileNavigationBar = () => {
  const t = useTranslations();
  return (
    <Container>
      {mainRoutesWithLogo.map((route) => {
        return (
          <NavBarTab
            key={route.name}
            name={t(route.name)}
            logo={route.logo}
            navigateTo={route.to}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: ${ZIndex.z40};
  box-shadow: 0px 0px 10px 0px ${Colors.navbarBoxShadow};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px 0 calc(12px + env(safe-area-inset-bottom)) 0;
  background-color: ${Colors.primaryNavbarBackgroundColor};
  height: ${HEADER_HEIGHT_PX - 24}px;
`;
