import { IconProps, Icon } from './Icon';

export const DollarCircleIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color="#00000000">
    <rect
      x="2"
      y="2"
      width="156"
      height="156"
      rx="78"
      stroke={color}
      strokeWidth="4"
    />
    <path
      d="M83.3359 46.7717C83.3359 44.8663 81.8494 43.3335 80.0018 43.3335C78.1541 43.3335 76.6676 44.8663 76.6676 46.7717V53.605C76.2369 53.6194 75.8063 53.648 75.3756 53.691C72.1526 53.9632 68.8184 54.7511 66.04 56.5705C63.1504 58.4615 61.0387 61.3839 60.2747 65.4237C59.7329 68.3319 59.9968 70.9821 61.1221 73.3315C62.2335 75.6237 64.0256 77.2854 65.9566 78.5175C69.5964 80.8382 74.556 82.1419 78.8626 83.2736L79.1821 83.3595C83.8916 84.5916 87.8787 85.666 90.5321 87.3707C91.7824 88.173 92.5187 88.9752 92.9077 89.7918C93.2828 90.551 93.4912 91.6255 93.1856 93.3016C92.7827 95.4075 91.324 97.1552 88.3927 98.3013C85.392 99.476 81.1687 99.8628 76.223 99.1465C73.0834 98.6737 67.6376 97.3271 64.6924 95.9805C63.0115 95.2069 61.0387 95.9948 60.2886 97.7282C59.5384 99.4616 60.3024 101.496 61.9834 102.269C65.6927 103.96 71.8192 105.421 75.2784 105.937H75.2923C75.7507 106.008 76.2092 106.066 76.6676 106.109V113.229C76.6676 115.134 78.1541 116.667 80.0018 116.667C81.8494 116.667 83.3359 115.134 83.3359 113.229V106.266C86.0171 106.08 88.5316 105.564 90.7683 104.691C95.1999 102.957 98.7842 99.6479 99.7288 94.5766C100.271 91.6685 100.007 89.0182 98.8814 86.6688C97.77 84.3767 95.9779 82.7149 94.0469 81.4829C90.4071 79.1621 85.4475 77.8585 81.1409 76.7267L80.8214 76.6408C76.1119 75.4088 72.1248 74.3343 69.4714 72.6296C68.2211 71.8273 67.4848 71.0251 67.0958 70.2085C66.7207 69.4493 66.5123 68.3748 66.8179 66.6987C67.2208 64.5785 68.2211 63.2606 69.6242 62.3437C71.1385 61.3552 73.2779 60.7392 75.9174 60.5244C81.2382 60.0803 87.4619 61.2836 91.7408 62.3151C93.5329 62.7592 95.325 61.6131 95.7556 59.7651C96.1863 57.9171 95.061 56.0834 93.2689 55.6536C90.6711 55.0233 87.1285 54.264 83.3359 53.8629V46.7717Z"
      fill={color}
    />
  </Icon>
);
