import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';
import { HookMediaQueries } from '../../constants/MediaQueries';

type VideoJsPlayerOptions = Parameters<typeof videojs>[1];

type Props = {
  options: VideoJsPlayerOptions;
  onReady?: (player: ReturnType<typeof videojs>) => void;
};

// This code comes directly from the Video.js docs https://videojs.com/guides/react/
export const VideoJS = ({ options, onReady }: Props) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

  useEffect(() => {
    if (!videoRef.current) return;
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
      player.playbackRates(options.playbackRates);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <VJSPlayer data-vjs-player>
      <div ref={videoRef} />
    </VJSPlayer>
  );
};

const VJSPlayer = styled.div`
  @media ${HookMediaQueries.isDesktop} {
    border-radius: 16px;

    video-js {
      border-radius: 16px;
    }

    video {
      border-radius: 16px;
    }
  }
`;
