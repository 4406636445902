import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HookMediaQueries } from '../../constants/MediaQueries';
import { STUDENT_TYPE_SELECTION } from '../../constants/onboardingSteps';
import { useUserManagement } from '../../hooks/useUserManagement';
import { StudentType } from '../../models/StudentType';
import { useGetStudentTypesQuery } from '../../services/onboardingApi';
import { useAppDispatch } from '../../store/hooks';
import {
  addStudentType,
  removeStudentType,
  setPreparation,
} from '../../store/slices/userManagementSlice';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { PrimaryButton } from '../common/Button';
import { CardBase, FooterAlignment } from '../common/CardBase';
import { Slider } from '../Inputs/Slider';
import { ProgressBar } from '../ProgressBar';

import { CardHeader } from './CardHeader';
import { useOnboardingProgress } from './hooks/useOnboardingProgress';
import { useRegister } from './hooks/useRegister';
import { SelectableItem } from './SelectableItem';
import { SelectableItemContainer } from './SelectableItemContainer';

const enum SliderRanges {
  Min = 0,
  Max = 10,
}

const ONBOARDING_STEP = STUDENT_TYPE_SELECTION;

export const StudentTypeCard = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { register } = useRegister();

  const userManagementState = useUserManagement();

  const { progress, previousProgress } = useOnboardingProgress(ONBOARDING_STEP);

  const selectedStudentTypes =
    userManagementState.registrationGoal?.studentTypes || [];
  const [rangeValue, setRangeValue] = useState<number>(SliderRanges.Max / 2);

  const { data: studentTypes } = useGetStudentTypesQuery();

  useEffect(() => {
    if (!userManagementState.registrationGoal?.preparation) {
      dispatch(setPreparation(SliderRanges.Max / 2));
    }
  }, []);

  const onStudentTypeSelect = (studentType: StudentType) => {
    if (
      userManagementState.registrationGoal?.studentTypes?.some(
        (studentTypeId) => studentTypeId === studentType._id
      )
    ) {
      dispatch(removeStudentType(studentType._id));
    } else {
      dispatch(addStudentType(studentType._id));
    }
  };

  const setUserPreparation = (range: string) => {
    const rangeNumber = parseInt(range);

    setRangeValue(rangeNumber);
    dispatch(setPreparation(rangeNumber));
  };

  const onBack = () => {
    navigate(-1);
  };

  const onContinue = async () => {
    register();
  };

  return (
    <StyledCardBase
      fullWidth={true}
      fullWidthBody={true}
      header={
        <CardHeader title={t('what_kind_of_student_are_you')} onBack={onBack} />
      }
      footerButtons={
        <PrimaryButton
          buttonSize="md"
          onClick={onContinue}
          disabled={!selectedStudentTypes.length}
        >
          {t('continue_alt')}
        </PrimaryButton>
      }
      footerAlignment={FooterAlignment.right}
      fullHeightBody={true}
    >
      <ProgressBar
        progress={progress}
        roundedCorners={false}
        initialWidth={previousProgress}
      />
      <ScrollContainer>
        <Container>
          <Header>{t(`I_want`)}</Header>
          <SelectableItemContainer>
            {studentTypes?.map((studentType) => (
              <SelectableItem
                key={studentType._id}
                text={studentType.name}
                active={selectedStudentTypes.includes(studentType._id)}
                onClick={() => onStudentTypeSelect(studentType)}
              />
            ))}
          </SelectableItemContainer>
        </Container>
        <Container>
          <Header>{t(`how_well_are_you_prepared`)}</Header>

          <Slider
            labelRightValue={t('well_prepared')}
            labelLeftValue={t('not_prepared')}
            min={SliderRanges.Min}
            max={SliderRanges.Max}
            value={rangeValue}
            onChange={(e) => setUserPreparation(e.target.value)}
          />
        </Container>
      </ScrollContainer>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(CardBase)`
  margin: 0;
  height: 100vh;
  overflow-y: auto;

  @media ${HookMediaQueries.isDesktop} {
    margin-top: 24px;
    height: 80vh;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  padding: 20px;
`;

const Container = styled.div`
  padding-bottom: 24px;
`;

const Header = styled.div`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemTitle}
  padding: 16px 0;
`;
