import { useError } from '../../../hooks/useError';
import { useUserManagement } from '../../../hooks/useUserManagement';
import { CourseLevel } from '../../../models/CourseLevel';
import { UserRole } from '../../../models/UserRole';
import { usePutEditCourseLevelMutation } from '../../../services/userApi';
import { useAppDispatch } from '../../../store/hooks';
import {
  removeTeacherCourseLevels,
  addTeacherCourseLevels,
  setCourseLevel,
} from '../../../store/slices/userManagementSlice';

export const useAccountCourseLevel = () => {
  const dispatch = useAppDispatch();
  const [editCourseLevelTrigger] = usePutEditCourseLevelMutation();
  const { showErrorModal } = useError();

  const { courseLevel, accountType, teacherCourseLevels } = useUserManagement();

  const selectedRole = accountType;
  const selectedCourseLevels =
    teacherCourseLevels || (courseLevel ? [courseLevel] : []);

  const onCourseLevelSelect = (courseLevel: CourseLevel) => {
    if (selectedRole === UserRole.teacher) {
      if (selectedCourseLevels.includes(courseLevel.name)) {
        dispatch(removeTeacherCourseLevels(courseLevel.name));
      } else {
        dispatch(addTeacherCourseLevels(courseLevel.name));
      }
    } else {
      dispatch(setCourseLevel(courseLevel.name));
    }
  };

  const updateCourseLevel = async () => {
    if (!courseLevel) return false;
    try {
      // TODO: There isn't yet a call for updating the teacher course levels, waiting for backend: https://digitalclub.atlassian.net/browse/EXO-4236
      await editCourseLevelTrigger({
        courseLevel: courseLevel,
      }).unwrap();
      return true;
    } catch (error) {
      showErrorModal(error);
      return false;
    }
  };

  return {
    onCourseLevelSelect,
    updateCourseLevel,
    selectedCourseLevels,
    selectedRole,
  };
};
