import styled from '@emotion/styled';

import { ProductCodeCard } from '../../components/dashboard/ProductCodeCard';
import { MyProductsCard } from '../../components/profile/MyProductsCard';
import { RecommendationCart } from '../../components/RecomendationCart';
import { MediaQueries } from '../../constants/MediaQueries';

export const Products = () => {
  return (
    <Container>
      <LeftContentContainer>
        <ProductCodeCard />
        <MyProductsCard />
      </LeftContentContainer>
      <RightContentContainer>
        <RecommendationCart />
      </RightContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: ${MediaQueries.desktop}) {
    flex-direction: row;
  }
`;

const LeftContentContainer = styled.div`
  height: 100%;
  width: 100%;

  @media (min-width: ${MediaQueries.desktop}) {
    width: 400px;
  }
`;

const RightContentContainer = styled.div`
  flex: 1;
`;
