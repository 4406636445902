import styled from '@emotion/styled';
import { ComponentProps, Ref } from 'react';
import { useTranslations } from 'use-intl';

import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { PrimaryButton } from '../common/Button';

type InputFieldProps = ComponentProps<'input'> & {
  label?: string;
  hasError?: boolean;
  errorText?: string;
  showAcceptButton?: boolean;
  nextInputRef?: Ref<HTMLInputElement>;
  inputRef?: Ref<HTMLInputElement>;
};

export const InputFieldComponent = ({
  label,
  hasError = false,
  errorText = '',
  showAcceptButton = false,
  inputRef,
  ...props
}: InputFieldProps) => {
  const t = useTranslations();

  return (
    <InputContainer>
      {label && <Label showAcceptButton={showAcceptButton}>{label}</Label>}
      <InputWrapper>
        <InputField hasError={hasError} ref={inputRef} {...props} />
        {showAcceptButton && (
          <AcceptButton buttonSize="sm" textSize="sm" type="submit">
            {t('link')}
          </AcceptButton>
        )}
      </InputWrapper>
      {hasError && <ErrorText>{errorText}</ErrorText>}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Label = styled.label<{ showAcceptButton?: boolean }>`
  white-space: pre-line;
  text-align: start;
  margin-bottom: 8px;
  color: ${Colors.inputHintText};
  ${({ showAcceptButton }) =>
    showAcceptButton ? TextStyles.ListItemTitle : TextStyles.InputHintText};
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const InputField = styled.input<{ hasError?: boolean }>`
  width: 100%;
  padding: 8px 48px 8px 12px;
  border: 1px solid ${Colors.inputBorderColor};
  border-radius: 4px;
  background-color: ${Colors.inputBackgroundColor};
  ${TextStyles.InputText};

  ${({ hasError }) => hasError && `border: 1px solid ${Colors.errorColor};`}

  &:focus {
    outline: none;
    border-color: ${Colors.inputBorderColor};
  }
`;

const ErrorText = styled.span`
  margin-top: 0.5rem;
  color: ${Colors.inputHintError};
  ${TextStyles.InputTextError};
`;

const AcceptButton = styled(PrimaryButton)`
  position: absolute;
  right: 8px;
`;

export default InputFieldComponent;
