import styled from '@emotion/styled';
import { useState } from 'react';

import { ZIndex } from '../../constants/Zindex';
import { Colors } from '../../styles/colors';
import { TextStyles } from '../../styles/textStyles';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';

type Props = {
  onChange: (value: string | number) => void;
  selectedItem: string;
  options: {
    label: string;
    value: number | string;
  }[];
};

export const Dropdown = ({ onChange, selectedItem, options }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <DropdownContainer>
      <InputContainer>
        <SelectWrapper onClick={() => setIsDropdownOpen((prev) => !prev)}>
          <Select>
            <SelectedItemContainer>{selectedItem}</SelectedItemContainer>
            <IconWrapper isExpanded={isDropdownOpen}>
              <ArrowDownIcon />
            </IconWrapper>
          </Select>
        </SelectWrapper>
      </InputContainer>
      {isDropdownOpen && (
        <Items>
          <List>
            {options.map((option) => (
              <Item key={option.label} onClick={() => onChange(option.value)}>
                {option.label}
              </Item>
            ))}
          </List>
        </Items>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  font-size: 1rem;
  text-align: start;
`;
const Items = styled.div`
  background-color: ${Colors.modalBackgroundColor};
  border: 1px solid ${Colors.inputBorderColor};
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  width: 100%;
  cursor: pointer;
  overflow: auto;
  max-height: 300px;
  z-index: ${ZIndex.z10};
  padding-top: 10px;
  box-sizing: border-box;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedItemContainer = styled.div`
  padding-right: 2px;
`;

const SelectWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
const Select = styled.div`
  width: 100%;
  padding: 4px 5px;
  border-radius: 4px;
  ${TextStyles.ScheduleOptionsText}
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const IconWrapper = styled.div<{ isExpanded: boolean }>`
  float: right;
  height: 20px;
  transform: ${(props) =>
    props.isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: transform 0.2s;
  cursor: pointer;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  height: inherit;
  overflow-y: auto !important;
`;
const Item = styled.li`
  color: ${Colors.primaryTextColor};
  ${TextStyles.ListItemBody}
  padding: 10px 5px;
`;
