import styled from '@emotion/styled';
import { useRef } from 'react';

import { SecondaryButton } from '../components/common/Button';
import { Colors } from '../styles/colors';
import { TextStyles } from '../styles/textStyles';

import { SummaryMode } from './subject/summary/utils/summaryUtils';

type MultiSwitchProps = {
  backgroundColor?: string;
  onSelectedChange: (option: SummaryMode) => void;
  summaryMode: SummaryMode;
};
const summaryModes = Object.values(SummaryMode);

const modeLabels = {
  [SummaryMode.Summary]: 'Samenvatting',
  [SummaryMode.Video]: 'Video',
  [SummaryMode.Both]: 'Beide',
};
export const MultiSwitch = ({
  backgroundColor,
  onSelectedChange,
  summaryMode,
}: MultiSwitchProps) => {
  const switchRef = useRef<HTMLDivElement>(null);

  return (
    <MultiSwitchContainer ref={switchRef}>
      {summaryModes.map((mode, i) => (
        <OptionButton
          key={i}
          isSelected={summaryMode === mode}
          buttonSize="md"
          onClick={() => onSelectedChange(mode)}
          backgroundColor={backgroundColor}
        >
          {modeLabels[mode]}
        </OptionButton>
      ))}
    </MultiSwitchContainer>
  );
};

const MultiSwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  background-color: ${Colors.secondaryButtonFill};
  border: 1px solid ${Colors.secondaryButtonStroke};
  margin-right: 10px;
  margin-bottom: 20px;
`;

const OptionButton = styled(SecondaryButton)<{
  isSelected: boolean;
  backgroundColor?: string;
}>`
  padding: 6px 16px;
  border-radius: 50px;
  border: none;
  transition:
    0.3s color ease,
    0.3s background-color ease;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected
      ? Colors.primaryButtonContentColor
      : Colors.primaryTextColor};
  background-color: ${(props) =>
    props.isSelected ? props.backgroundColor : 'transparent'};
  ${TextStyles.ListItemTitle}
  flex-grow: 1;
`;
