import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { getYoutubeEmbeddedUrl } from '../../../utils/videoUtils';

type VideoProps = {
  title: string;
  url: string;
} & ComponentProps<'div'>;

export const Video = ({ title, url, ...props }: VideoProps) => {
  const t = useTranslations();
  return (
    <div {...props}>
      {getYoutubeEmbeddedUrl(url) ? (
        <>
          <YTiFrame
            src={getYoutubeEmbeddedUrl(url)}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
          />
          <Title>{title}</Title>
        </>
      ) : (
        <ErrorMessage>{t('invalid_YT_url')}</ErrorMessage>
      )}
    </div>
  );
};

const YTiFrame = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 5px;
`;
const Title = styled.h1`
  ${TextStyles.ListItemTitle}
  color: ${Colors.textNormal}
`;
const ErrorMessage = styled.h1`
  ${TextStyles.ListItemTitle}
  color: ${Colors.textNormal}
`;
