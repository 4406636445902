import styled from '@emotion/styled';

import { ZIndex } from '../../constants/Zindex';
import { useModal } from '../../hooks/useModal';
import { Colors } from '../../styles/colors';

export function Backdrop() {
  const { modalActive } = useModal();

  return <BackdropContainer visible={modalActive} />;
}

const BackdropContainer = styled.div<{ visible: boolean }>`
  background: ${Colors.modalBackdrop};
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${ZIndex.z40};
  position: fixed;
  opacity: 0.4;

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 0.4;
      visibility: visible;
    }
  }
  @keyframes fadeOut {
    0% {
      display: block;
      opacity: 0.4;
    }
    1% {
      display: block;
      opacity: 0.4;
    }
    100% {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }

  animation: ${(props) =>
    props.visible ? 'fadeIn 0.4s 1' : 'fadeOut 0.4s forwards'};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;
