import styled from '@emotion/styled';
import { CalendarEvent, createViewWeek } from '@schedule-x/calendar';
import { createEventModalPlugin } from '@schedule-x/event-modal';
import { createEventsServicePlugin } from '@schedule-x/events-service';
import { ScheduleXCalendar, useCalendarApp } from '@schedule-x/react';
import { format } from 'date-fns';
import { useEffect } from 'react';

import {
  SCHEDULE_DATE_FORMAT,
  SCHEDULE_END_TIME,
  SCHEDULE_START_TIME,
} from '../../constants/date';
import { LOCALE_SCHEDULE } from '../../constants/locale';
import { Subject } from '../../models/Subject';
import { Colors } from '../../styles/colors';

import { CalendarEventComponent } from './CalendarEventComponent';
import { CalendarEventModal } from './CalendarEventModal';

export const ScheduleComponent = ({
  events,
  firstDate,
  lastDate,
}: {
  events: (CalendarEvent & Subject)[];
  firstDate: number;
  lastDate: number;
}) => {
  const plugins = [createEventsServicePlugin(), createEventModalPlugin()];

  const formatDate = (date: Date) => {
    return format(date, SCHEDULE_DATE_FORMAT);
  };

  const calendar = useCalendarApp(
    {
      locale: LOCALE_SCHEDULE,
      views: [createViewWeek()],
      events: events,
      dayBoundaries: {
        start: SCHEDULE_START_TIME,
        end: SCHEDULE_END_TIME,
      },
      minDate: formatDate(firstDate ? new Date(firstDate) : new Date()),
      maxDate: formatDate(lastDate ? new Date(lastDate) : new Date()),
      isResponsive: true,
      selectedDate: formatDate(firstDate ? new Date(firstDate) : new Date()),
      weekOptions: {
        gridHeight: 600,
        nDays: 7,
        eventWidth: 95,
        timeAxisFormatOptions: { hour: '2-digit', minute: '2-digit' },
      },
    },
    plugins
  );

  useEffect(() => {
    calendar.eventsService.getAll();
  }, [calendar.eventsService]);

  return (
    <Container>
      <ScheduleXCalendar
        calendarApp={calendar}
        customComponents={{
          timeGridEvent: CalendarEventComponent,
          eventModal: CalendarEventModal,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  & .sx__today-button,
  & .sx__date-input,
  & .sx__range-heading,
  & .sx__date-input-chevron-wrapper {
    display: none;
  }
  & .sx__calendar {
    box-shadow: 0px 2px 2px 0px ${Colors.shadowColor};
    border-radius: 8px;
    border: 1px solid ${Colors.dividerColor};
  }

  & .sx__forward-backward-navigation {
    display: block;
  }
`;
