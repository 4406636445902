import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { ProgressType } from '../../../constants/progress';
import { Colors } from '../../../styles/colors';
import { TextStyles } from '../../../styles/textStyles';
import { CircularProgress } from '../../CircularProgress';

import { TestSectionButton } from './TestSectionButton';

type Props = {
  buttonColor: string;
  chapterTitle: string;
  sectionTitle: string;
  encodedChapterName: string;
  encodedSectionName: string;
  isPreview: boolean;
  sectionRating: number;
};

export const SummaryPracticeCard = ({
  buttonColor,
  chapterTitle,
  sectionTitle,
  encodedChapterName,
  encodedSectionName,
  isPreview,
  sectionRating,
}: Props) => {
  const { encodedSubjectName } = useParams<{ encodedSubjectName: string }>();

  return (
    <PracticeContainer>
      <Left>
        <Title>{chapterTitle}</Title>
        <Subtitle> {sectionTitle}</Subtitle>
        <TestSectionButton
          buttonColor={buttonColor}
          encodedChapterName={encodedChapterName}
          encodedSectionName={encodedSectionName}
          encodedSubjectName={encodedSubjectName ?? ''}
          isPreview={isPreview}
        />
      </Left>
      {!!sectionRating && (
        <Right>
          <CircularProgress
            subjectColor={buttonColor}
            progress={sectionRating}
            type={ProgressType.Grade}
          />
        </Right>
      )}
    </PracticeContainer>
  );
};
const PracticeContainer = styled.div`
  background-color: ${Colors.backgroundColor};
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Left = styled.div``;
const Right = styled.div``;
const Title = styled.h1`
  margin: 0;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardTitleText}
`;
const Subtitle = styled.h1`
  margin: 0;
  padding-bottom: 20px;
  color: ${Colors.primaryTextColor};
  ${TextStyles.CardSubtitleText}
`;
