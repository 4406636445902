import { IconProps, Icon } from './Icon';

export const CloseCircleIcon = ({ color, ...props }: IconProps) => (
  <Icon {...props} color="#00000000">
    <rect
      x="2"
      y="2"
      width="156"
      height="156"
      rx="78"
      stroke={color}
      strokeWidth="4"
    />
    <path
      d="M103.906 61.4062C105.375 59.9375 105.375 57.5625 103.906 56.1094C102.438 54.6562 100.062 54.6406 98.6094 56.1094L80.0156 74.7031L61.4062 56.0938C59.9375 54.625 57.5625 54.625 56.1094 56.0938C54.6562 57.5625 54.6406 59.9375 56.1094 61.3906L74.7031 79.9844L56.0938 98.5938C54.625 100.062 54.625 102.437 56.0938 103.891C57.5625 105.344 59.9375 105.359 61.3906 103.891L79.9844 85.2969L98.5938 103.906C100.062 105.375 102.437 105.375 103.891 103.906C105.344 102.438 105.359 100.062 103.891 98.6094L85.2969 80.0156L103.906 61.4062Z"
      fill={color}
    />
  </Icon>
);
