import styled from '@emotion/styled';
import { useTranslations } from 'use-intl';

import { Colors } from '../../../styles/colors';
import { PlusIcon } from '../../Icons/PlusIcon';
import { SelectableItem } from '../SelectableItem';

type Props = {
  onSelect: () => void;
};

export const ExtraSubjectSelectableItem = ({ onSelect }: Props) => {
  const t = useTranslations();

  const icon = (
    <IconContainer>
      <StyledPlusIcon />
    </IconContainer>
  );

  return (
    <SelectableItem
      icon={icon}
      text={t('subject_on_other_level')}
      onClick={onSelect}
      active={false}
    />
  );
};
const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${Colors.secondaryButtonFill};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledPlusIcon = styled(PlusIcon)`
  width: 20px;
  height: 20px;
`;
