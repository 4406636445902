import { useEffect } from 'react';

type Props = {
  handleBottomOfPage: () => void;
};

function useBottomOfPage({ handleBottomOfPage }: Props) {
  const isPageScrollable = () => {
    const scrollableElement = document.documentElement;
    return scrollableElement.scrollHeight <= scrollableElement.clientHeight;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollClearanceAmount = 50;
      const scrollableElement = document.documentElement;

      const distanceFromBottom =
        scrollableElement.scrollHeight -
        scrollableElement.scrollTop -
        scrollableElement.clientHeight;

      if (distanceFromBottom <= scrollClearanceAmount) {
        handleBottomOfPage();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleBottomOfPage]);

  return { isPageScrollable };
}

export default useBottomOfPage;
